import React, { memo, Suspense, lazy } from 'react';
import { css, useTheme } from '@emotion/react';
import { ColourField, DLClickEventHandlerProps, IconEntryType, ImageEntryType, LinkEntryType } from 'tsconfig/types.d';
import { Icon } from '../../Icon/Icon';
import { CmsImage } from '../CmsImage/CmsImage';
import { logout } from 'common-state';
import { dwChat } from '../../../dw-chat/DwChat';
import { createLogger, inspectorMode } from 'common-ui';
import { Link } from '../../Link/Link';

const CartButton = lazy(() => import('../../AddToCart/Button').then(({ CartButton }) => ({ default: CartButton })));

export type CmsLinkProps = {
  contentItem: LinkEntryType;
  category?: string;
  className?: string;
  dlClickEventHandler?: DLClickEventHandlerProps;
};
const UK_COOKIE_TARGET = 'optanon-toggle-display';

export const CmsLink = memo(({ contentItem, category, className, dlClickEventHandler }: CmsLinkProps) => {
  const theme = useTheme();
  const categoryType = category ? category : 'link';
  const log = createLogger();
  const { title, entryTitle, url, targetingV2, icon, variant, productCode } = contentItem?.fields || {};

  if (!url) {
    log.warn('No url value set for', contentItem?.fields);
  }

  const wrapper = css`
    display: block;
  `;
  const getTargeting = (key?: string) => {
    switch (true) {
      case key === 'cookie_setting' || key === 'Cookie Setting':
        return UK_COOKIE_TARGET;
      default:
        return '';
    }
  };

  const onClickHandler = (event: React.MouseEvent<HTMLAnchorElement>, targetingV2?: string) => {
    event.stopPropagation();
    if (targetingV2 === 'cookie_setting_osano' || targetingV2 === 'Cookie Setting Osana') {
      window?.Osano?.cm?.showDrawer?.('osano-cm-dom-info-dialog-open');
      event.preventDefault();
    }
    if (targetingV2 === 'cookie_setting' || targetingV2 === 'Cookie Setting') {
      event.preventDefault();
    }
    if (targetingV2 === 'soft_logged' || targetingV2 === 'Soft Logged') {
      logout('hard_logged');
    }
    if (targetingV2 === 'live_chat' || targetingV2 === 'Live Chat') {
      event.preventDefault();
      dwChat.open();
    }
  };

  const renderIconImage = (iconImageField: LinkEntryType['fields']['icon']) => {
    if (!iconImageField) {
      return <></>;
    }
    let icon;
    if ((iconImageField as ImageEntryType)?.fields?.largeDeviceImage) {
      icon = iconImageField as ImageEntryType;
      return <CmsImage contentItem={icon} />;
    } else {
      icon = iconImageField as IconEntryType;
      const iconStyle = css`
        color: ${(icon?.fields?.colour as unknown as ColourField)?.value};
      `;
      return (
        <div css={iconStyle}>
          <Icon kind={icon.fields.iconType} size="m" />
        </div>
      );
    }
  };

  let variantType;
  if (variant === 'Secondary button') {
    variantType = css`
      && {
        ${theme.mixins.btnSecondary}
      }
    `;
  } else if (variant === 'Primary button') {
    variantType = css`
      && {
        ${theme.mixins.btnPrimary}
      }
    `;
  } else if (variant === 'Secondary-inverted button') {
    variantType = css`
      && {
        ${theme.mixins.btnTertiary}
      }
    `;
  } else if (variant === 'TransparentBG button') {
    variantType = css`
      && {
        ${theme.mixins.btnBGTransparent}
      }
    `;
  } else if (variant === 'Standard text link') {
    variantType = css`
      && {
        ${theme.mixins.standardTextLink}
      }
    `;
  } else {
    variantType = '';
  }

  const buttonStyle =
    variant === 'Secondary button' ? 'secondary' : variant === 'Secondary-inverted button' ? 'tertiary' : 'primary';

  return (
    <>
      {(targetingV2 === 'add_to_basket' || targetingV2 === 'Add to Basket') && productCode ? (
        <Suspense fallback={<div>Loading...</div>}>
          <CartButton
            itemCode={productCode}
            qty={'1'}
            buttonText={title ?? ''}
            buttonStyle={buttonStyle}
            {...inspectorMode(contentItem?.sys?.id, 'title' || 'entryTitle')}
          />
        </Suspense>
      ) : (
        <Link
          data-testid="widget-CmsLink"
          css={css([wrapper, variantType])}
          href={(url as string) || ''}
          data-event={`${categoryType}Click`}
          data-section={`${categoryType}:${title || entryTitle}`}
          data-action="navigation"
          data-name={`${categoryType} navigation`}
          data-text={title || entryTitle}
          data-nav-label={title || entryTitle}
          className={`${className ? className : ''} ${getTargeting(targetingV2)}`}
          onClick={(e: any) => {
            onClickHandler(e, targetingV2);
            dlClickEventHandler && dlClickEventHandler(title ?? entryTitle);
          }}
          {...inspectorMode(contentItem?.sys?.id, 'title' || 'entryTitle')}
        >
          {icon && renderIconImage(icon)}
          {title ?? entryTitle}
        </Link>
      )}
    </>
  );
});
