import React, { memo } from 'react';
import { ProductCardEntryType } from 'tsconfig/types';
import { ProductCard } from '../../ProductCard/ProductCard';
import useProductData from '../../../hooks/useProductData';
import get from 'lodash/get';
export interface CmsProductCardProps {
  contentItem: ProductCardEntryType;
}

export const CmsProductCard = memo(({ contentItem }: CmsProductCardProps) => {
  const { productCode, productCardBackgroundImage, productCardButton, productCardVariant, productCardBackgroundColor } =
    contentItem.fields || {};
  const { product: productData, loading } = useProductData(productCode);

  const vppPrice = get(productData, 'skus[0].vppPrice', null);
  const salePrice = get(productData, 'skus[0].salePrice', null);
  const buyersRRP = get(productData, 'skus[0].buyersRRP', null);
  const memberPrice = get(productData, 'skus[0].memberPrice', null);
  const numberOfBottles = get(productData, 'skus[0].numberOfBottles', null);

  return (
    <ProductCard
      productName={productData?.name || ''}
      productRating={productData?.averageOverallRating}
      productReviewCount={productData?.totalReviewCount}
      productPrice={vppPrice ? vppPrice : salePrice}
      productPriceOriginal={buyersRRP ? buyersRRP : 0}
      productPricePrefix={vppPrice ? 'from ' : ''}
      productMemberPrice={memberPrice}
      productImage={productData?.smallImage || productData?.largeImage}
      productVintage={productData?.vintage || ''}
      productCardBackgroundImage={productCardBackgroundImage}
      productCardBackgroundColor={productCardBackgroundColor}
      productCardButton={productCardButton}
      productCode={productCode}
      productCardVariant={productCardVariant}
      productType={productData?.mixed ? 'Case' : productData?.productType ?? ''}
      productNumberOfBottles={numberOfBottles}
      isLoading={loading}
    />
  );
});
