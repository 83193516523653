import { createLogger } from './logger';
/**
 * Fetches content from Contentful by content type id and feKey.
 * @param {string} contentTypeId - The id of the content type to filter entries by.
 * @param {string} feKey - The value of the 'feKey' field to match against.
 * @returns {Promise<any>} - Resolves with the fields of the matching entry if found, otherwise null.
 */
export async function fetchContentByContentTypeId(contentTypeId: string, feKey: string, client: any): Promise<any> {
  const log = createLogger();
  try {
    // Fetch entries with the specified content type
    const entries = await client?.getEntries({
      content_type: contentTypeId,
      include: 5,
    });

    // Find the entry with the matching feKey
    const contentData = entries?.items
      .map((entry: any) => ({
        id: entry?.sys?.id,
        sys: entry?.sys,
        fields: entry?.fields,
      }))
      .find((content: any) => content?.fields?.id === feKey);
    return contentData ? [contentData?.fields] : null;
  } catch (error) {
    log.error('Error fetching content from Contentful:', error);
    return null;
  }
}

/**
 * Calculates the estimated delivery date based on the current date and standard delivery days.
 * @param {number} standardDeliveryDays - The number of days for standard delivery (default is 3).
 * @returns {string} - The formatted estimated delivery date.
 */
export function calculateEstimatedDeliveryDate(standardDeliveryDays = 3): string {
  const currentDate = new Date();
  currentDate.setDate(currentDate.getDate() + standardDeliveryDays);

  const day = currentDate.getDate();
  const month = currentDate.toLocaleString('default', { month: 'short' });

  const nextDay = new Date(currentDate);
  nextDay.setDate(nextDay.getDate() + 1);
  const nextDayNumber = nextDay.getDate();
  const nextMonth = nextDay.toLocaleString('default', { month: 'short' });

  const suffix =
    day === 1 || day === 21 || day === 31
      ? 'st'
      : day === 2 || day === 22
        ? 'nd'
        : day === 3 || day === 23
          ? 'rd'
          : 'th';
  const nextDaySuffix =
    nextDayNumber === 1 || nextDayNumber === 21 || nextDayNumber === 31
      ? 'st'
      : nextDayNumber === 2 || nextDayNumber === 22
        ? 'nd'
        : nextDayNumber === 3 || nextDayNumber === 23
          ? 'rd'
          : 'th';

  let formattedDate;
  if (month === nextMonth) {
    formattedDate = `${day}${suffix} - ${nextDayNumber}${nextDaySuffix} ${nextMonth}`;
  } else {
    formattedDate = `${day}${suffix} ${month} - ${nextDayNumber}${nextDaySuffix} ${nextMonth}`;
  }

  return formattedDate;
}

/**
 * Formats the given date string according to the specified format.
 * @param {string} dateString - The date string to format.
 * @param {string} format - The desired format for the date string (e.g., 'YYYY/MM/DD', 'DD/MM/YY', 'DD MMM YYYY').
 * @returns {string} - The formatted date string.
 */
export function formatDate(dateString: string, format: string): string {
  const date = new Date(dateString);
  const day = date.getDate();
  const month = date.toLocaleString('en-US', { month: 'short' });
  const year = date.getFullYear().toString();
  const monthNumber = date.getMonth() + 1;

  if (format === 'YYYY/MM/DD') {
    return `${year}/${month.padStart(2, '0')}/${day.toString().padStart(2, '0')}`;
  }

  if (format === 'DD/MM/YY') {
    return `${day.toString().padStart(2, '0')}/${month.padStart(2, '0')}/${year.slice(-2)}`;
  }

  if (format === 'DD/M/YY') {
    return String(monthNumber).length === 1
      ? `${day.toString().padStart(2, '0')}/${String(monthNumber).padStart(2, '0')}/${year.slice(-2)}`
      : `${day.toString().padStart(2, '0')}/${monthNumber}/${year.slice(-2)}`;
  }

  if (format === 'DD MMM YYYY') {
    return `${day} ${month} ${year}`;
  }

  return dateString; // Return the original date string if format is not recognized
}

/**
 * Get the count down timer message from the given date string.
 * @param {string} dateString - The date string of datetime when the count down ends.
 * @returns {string} - The formatted count downtimer message. (e.g. "1 day : 3 hours : 5 minutes" )
 */
export function getCountDownTime(dateString: string): string {
  let text = '';
  const time = Date.parse(dateString) - Date.now();
  const d = Math.floor(time / (1000 * 60 * 60 * 24));
  const h = Math.floor((time / (1000 * 60 * 60)) % 24);
  const m = Math.floor((time / 1000 / 60) % 60);
  if (d > 0) {
    text += d + ' day' + (d != 1 ? 's' : '') + ' : ';
  }
  if (d > 0 || h > 0) {
    text += h + ' hour' + (h != 1 ? 's' : '') + ' : ';
  }
  if (d > 0 || h > 0 || m > 0) {
    text += m + ' minute' + (m != 1 ? 's' : '');
  }
  return text;
}
