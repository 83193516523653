/**
 * Theme for Laithwaites UK site
 */
import { Theme } from '@emotion/react';
import { buildTheme, globals } from 'common-ui';
import { CommonThemeVars, ThemeCreateFunction } from 'tsconfig/types';
import { icons } from 'common-font-icons';
import EmotionBreakpoint from 'common-ui/src/utils/EmotionBreakpoint';
import { notoSerifFont, oswaldFont, robotoFont } from '../common/nextFont';

export const buildThemeVarsLW = (vars: Pick<Theme, CommonThemeVars>) => {
  //primary
  const lwPink = '#CF004F'; //color1
  const lwDarkBlue = '#00193C'; //color2
  const lwCream = '#F8F5EF'; //color3
  const lwRed = '#B1070D'; //color4
  const lwlightRed = '#F5DEE6'; //color5
  const lwlightError = '#FFCFD5'; //color5
  const lwUnlimitedMemberText = '#009F8E';
  const lwUnlimitedMemberHoverText = '#00797F';
  const lwPink500 = '#E2004D';

  //secondary
  const lwPurple = '#7A0040'; //color4/11
  const lwLightGreen = '#ABC7AB'; //color6
  const lwBlue = '#203C5D'; //color7
  const lwPeach = '#FFA8A1'; //color8
  const lwDarkGreen = '#0B400A'; //color10
  const lwSand = '#B1927B'; //color5
  //Category Banners
  const whiteWine = '#E6EFF7';
  const redWine = lwPurple;
  const roseWine = '#FBE6F1';
  const champagneAndSparkling = '#674F3E';
  const dessert = '#55002D';
  const fortifiedWine = '#D5BFB1';
  const wineBox = '#C896B1';
  //CTA colors
  const lwCTAGreen = '#117B53'; //color9
  const lwCTARolloverGreen = '#094B32';
  const lwCTAPink = lwPink;
  const lwCTARolloverPink = '#8B0035';
  const lwHUELWBlue = '#D7E0EE';
  const lwDrGray = '#00000099';
  const lwGrey400 = '#BCBCBC';
  const lwCarbon = '#333333';
  const lwSquant = '#666666';
  const lwBorderBlue = '#51B1FF';
  const lwStockPink = lwPink;
  const contentBodyPrimaryBlack = '#222222';
  const congressBlue = '#004D86';

  const colors = {
    //commmon colour definitions that will used in widgets
    primary0: lwBorderBlue,
    primary1: lwPink,
    primary2: lwDarkBlue,
    primary3: lwCream,
    primary4: lwRed,
    primary5: lwlightRed,
    primary6: lwStockPink,
    primary7: lwlightError,
    primary8: contentBodyPrimaryBlack,
    primary9: lwPink500,
    secondary1: lwPurple,
    secondary2: lwSand,
    secondary3: lwLightGreen,
    secondary4: lwBlue,
    secondary5: lwPeach,
    secondary6: lwCTAGreen,
    secondary7: lwDarkGreen,
    secondary8: lwPurple,
    secondary9: lwGrey400,
    secondary10: lwCarbon,
    secondary11: lwSquant,
    secondary12: congressBlue,
    //specific colour definitions
    btnsPrimaryBg: lwCTAGreen,
    btnsPrimaryColor: vars.colors.white,
    btnsPrimaryHoverBg: lwCTARolloverGreen,
    btnsPrimaryHoverColor: vars.colors.white,
    btnsSecondaryBg: vars.colors.white,
    btnsSecondaryColor: vars.colors.black,
    btnsSecondaryHoverBg: lwCTARolloverGreen,
    btnsSecondaryHoverColor: vars.colors.white,
    btnsTertiaryBg: lwCTAPink,
    btnsTertiaryColor: vars.colors.white,
    btnsTertiaryBorder: lwCTAPink,
    btnsTertiaryHoverBg: lwCTARolloverPink,
    btnsTertiaryHoverColor: vars.colors.white,

    textUnlimitedBg: lwUnlimitedMemberText,
    textUnlimitedHoverBg: lwUnlimitedMemberHoverText,

    linkPrimaryColor: vars.colors.black,
    linkSecondaryColor: lwCTAPink,
    linkTertiaryColor: lwCTAGreen,
    lWHUELWBlue: lwHUELWBlue,
    lwDrGray: lwDrGray,

    headerCartBtn: lwCTAGreen,
    headerCartBtnSmall: lwCTAGreen,
    headerSignInBtnSmall: lwDarkBlue,
    footerPrimaryBg: lwPink,
    footerGuidelinesFillColor: lwPink,
    footerIconFillColor: vars.colors.black,
    footerdrinkaware: vars.colors.black,

    bgColors: {
      LWPink: lwPink,
      LWDarkBlue: lwDarkBlue,
      LWCream: lwCream,
      LWPurple: lwPurple,
      LWSand: lwSand,
      LWLightGreen: lwLightGreen,
      LWBlue: lwBlue,
      LWPeach: lwPeach,
      LWCTAGreen: lwCTAGreen,
      LWDarkGreen: lwDarkGreen,
      whiteWine: whiteWine,
      redWine: redWine,
      roseWine: roseWine,
      champagneAndSparkling: champagneAndSparkling,
      dessert: dessert,
      fortifiedWine: fortifiedWine,
      wineBox: wineBox,
    },
  };

  const oswald = oswaldFont.style.fontFamily;
  const roboto = robotoFont.style.fontFamily;
  const noto = notoSerifFont.style.fontFamily;

  const fontSizeDefault = '14px';
  const fonts = {
    familyPrimary: oswald,
    familySecondary: roboto,
    familyTertiary: noto,
    sizeRoot: fontSizeDefault, //used in page root
    sizeSmall: '12px',
    sizeDefault: fontSizeDefault,
    sizeLarge: '16px',
    sizeLarger: '18px',
    sizeXL: '64px',
    sizeH1: '40px',
    sizeH2: '28px',
    sizeH3: '20px',
    sizeH4: '16px',
    sizeH5: '14px',
    sizeH6: '24px',
    sizeH7: '20px',
    sizeH8: '22px',
    sizeH9: '26px',
    sizeH10: '30px',
    weightLight: '300',
    weightNormal: '400',
    weightMedium: '500',
    weightBold: '700',
    lineHeightDefault: '24px',
    headerMainNavTitle: noto,
    headerMainNavSubTitle: noto,
    styleItalic: 'italic',
    roboto,
    noto,
    oswald,
  };

  const space = {
    //common spacing based on design guide grid/typography/buttons etc
    xs: '4px',
    sm: '8px',
    md: '10px',
    lg: '16px',
    xl: '24px',
    xl2: '32px',
    xl3: '48px',
    xl4: '64px',
  };

  return {
    meta: {
      name: 'Laithwaites UK',
    },
    icons,
    assets: {
      headerLogoImgUrl: `${globals.assetsPath}/next/images/brand-logo.svg`,
      headerLogoMobileImgUrl: `${globals.assetsPath}/next/images/brand-logo-mobile.svg`,
      stickyHeaderLogoImgUrl: `${globals.assetsPath}/next/images/sticky-brand-logo.svg`,
      footerLogoImgUrl: `${globals.assetsPath}/next/images/brand-logo.svg`,
    },
    colors,
    fonts,
    space,
  };
};
export const buildThemeMixinsLW = (vars: Omit<Theme, 'mixins' | 'widgets'>) => {
  const buttonBase = `
    display: inline-block;
    font-size: ${vars.fonts.sizeDefault};
    border-radius: 5px;
    //custom line-height and vertical pad for correct icon fit
    line-height: 26px;
    padding: 12px 24px; 
    border-width: 0;
    border-style: solid;    

    :hover{
      text-decoration: none;
    }

    :focus:hover {
      color: ${vars.colors.btnsSecondaryColor} !important;
    }

    i.icon-left {
      font-size: ${vars.fonts.lineHeightDefault};
      transform: translate(-6px);
    }
  `;
  const linkBase = `
    display: inline;
    font-size: inherit;
    background:none;
    border-width: 0;
    padding: 0;
    text-decoration: none;
    text-underline-offset: ${vars.space.xs};
    font-weight: inherit;
    align-self: auto;

    :hover, :focus{
      text-decoration: underline;
      background-color: transparent;
    }
  
    &[aria-disabled="true"] {
      pointer-events: none;
      color: ${vars.colors.grey40};
    }

  `;

  const mixins = {
    h8: `
      font-family: ${vars.fonts.familySecondary};
      font-size: ${vars.fonts.fontSize16};
      font-weight: ${vars.fonts.weightBold};
      line-height: ${vars.fonts.fontLineHeight130};
      letter-spacing: ${vars.fonts.fontLetterSpacing6};
      ${vars.breakpoints.sm} {
        font-size: ${vars.fonts.fontSize18};
      }
    `,
    body1: `
      font-family: ${vars.fonts.familySecondary};
      font-size: ${vars.fonts.fontSize16};
      font-weight: ${vars.fonts.weightNormal};
      line-height: ${vars.fonts.fontLineHeight140};
      letter-spacing: ${vars.fonts.fontLetterSpacing5};
    `,
    body2: `
      font-family: ${vars.fonts.familySecondary};
      font-size: ${vars.fonts.fontSize14};
      font-weight: ${vars.fonts.weightNormal};
      line-height: ${vars.fonts.fontLineHeight130};
      letter-spacing: ${vars.fonts.fontLetterSpacing5};
    `,
    btnPrimary: `
    ${buttonBase}
    background-color: ${vars.colors.btnsPrimaryBg};
    color: ${vars.colors.btnsPrimaryColor};
    border-width: 2px;
    border-color: transparent;

    &.desktop-sticky-content:hover, :hover{
      background-color: ${vars.colors.btnsPrimaryHoverBg};
      color: ${vars.colors.white};
    }
    &.desktop-sticky-content:focus, :focus{
      background-color: ${vars.colors.white};
      color: ${vars.colors.black};
      border-color: ${vars.colors.primary0};
    }
    &.desktop-sticky-content:disabled, :disabled{
      background-color: ${vars.colors.grey00};
      color: ${vars.colors.grey70};
    }
  `,
    btnPrimaryUnlimited: `
    ${buttonBase}
    background-color: ${vars.colors.textUnlimitedBg};
    color: ${vars.colors.btnsPrimaryColor};
    border-width: 2px;
    border-color: transparent;

    &.desktop-sticky-content:hover, :hover{
      background-color: ${vars.colors.textUnlimitedHoverBg};
      color: ${vars.colors.white};
    }
    &.desktop-sticky-content:focus, :focus{
      background-color: ${vars.colors.white};
      color: ${vars.colors.black};
      border-color: ${vars.colors.primary0};
    }
    &.desktop-sticky-content:disabled, :disabled{
      background-color: ${vars.colors.grey00};
      color: ${vars.colors.grey70};
    }
  `,
    btnSecondary: `
    ${buttonBase}
    background-color: ${vars.colors.btnsSecondaryBg};
    color: ${vars.colors.btnsSecondaryColor};
    border-width: 1px;
    border-color: ${vars.colors.btnsSecondaryColor};
    &.desktop-sticky-content:hover, :hover{
      background-color: ${vars.colors.btnsSecondaryHoverBg};
      color: ${vars.colors.btnsSecondaryHoverColor};
    }
    &.desktop-sticky-content:focus, :focus{
      background-color: ${vars.colors.white};
      color: ${vars.colors.black};
      border-color: ${vars.colors.primary0};
      border-width: 2px;
      padding: 6.5px 21px; 
    }
    &.desktop-sticky-content:disabled, :disabled{
      background-color: ${vars.colors.grey00};
      border: none;
      color: ${vars.colors.grey70};
    }
  `,
    btnBGTransparent: `
      ${buttonBase}
      background-color: transparent;
      color: ${vars.colors.btnsSecondaryColor};
      border-width: 1px;
      border-color: ${vars.colors.btnsSecondaryColor};
      &.desktop-sticky-content:hover, :hover{
        background-color: ${vars.colors.btnsSecondaryHoverBg};
        color: ${vars.colors.btnsSecondaryHoverColor};
      }
      &.desktop-sticky-content:focus, :focus{
        background-color: ${vars.colors.white};
        color: ${vars.colors.black};
        border-color: ${vars.colors.primary0};
        border-width: 2px;
        padding: 6.5px 21px; 
      }
      &.desktop-sticky-content:disabled, :disabled{
        background-color: ${vars.colors.grey00};
        border: none;
        color: ${vars.colors.grey70};
      }
  `,
    standardTextLink: `
      display: flex;
      align-items: center;
      text-align: left;
      gap: 10px;
      margin-bottom: 20px;
      font-size: 16px;
  `,
    btnTertiary: `
    ${buttonBase}
    background-color: ${vars.colors.btnsTertiaryBg};
    color: ${vars.colors.btnsTertiaryColor};
    border-width: 1px;
    border-color: ${vars.colors.btnsTertiaryBorder};

    :hover{
      background-color: ${vars.colors.btnsTertiaryHoverBg};
      color: ${vars.colors.btnsTertiaryHoverColor};
    }
    :focus{
      background-color: ${vars.colors.white};
      color: ${vars.colors.black};
      border-color: ${vars.colors.primary0};
      border-width: 2px;
      padding: 6.5px 21px; 
    }
    :disabled{
      background-color: ${vars.colors.grey00};
      color: ${vars.colors.grey30};
    }
    `,
    linkPrimary: `
    ${linkBase}
    color: ${vars.colors.linkPrimaryColor};
  `,
    linkSecondary: `
    ${linkBase}
    color: ${vars.colors.linkSecondaryColor};
  `,
    linkTertiary: `
    ${linkBase}
    color: ${vars.colors.linkTertiaryColor};
  `,
    clearRefinements: ` 
    background: none;
    color: ${vars.colors.black};
    font-size: ${vars.fonts.sizeLarge};
    line-height: ${vars.fonts.lineHeightDefault};
    text-decoration-line: underline;
    padding: 0;
    margin: 0 0 16px 0;
    cursor: pointer;
    border: none;
    box-shadow: none;
    &:hover {
      background: none;
      color: ${vars.colors.black};
      text-decoration-line: underline;
    }
  `,
  };

  return mixins;
};
export const buildThemeWidgetStylesLW = (vars: Omit<Theme, 'widgets'>) => {
  const widgets = {
    CategoryPage: {
      wrapper: `
        position: relative;
        .product-listing-wrapper {
          margin: 0px auto;
        }        
        .sticky-banner {
          position: sticky;
          background-color: ${vars.colors.primary3};
          padding: 25px;
          bottom: 0;
          z-index: 2;
          border-top: 1px solid ${vars.colors.primary2};
          border-bottom: 1px solid ${vars.colors.primary2};
        }
        .product-image-container {
          .product-image {
            padding: 20px;
            position: relative;
            min-height: 260px!important;

            ${vars.breakpoints.md} {
              min-height: 420px!important;
            }
          }
        }
        .utility-bar {
          display: grid;
          justify-content: center;
          gap: 20px;
          align-items: center;
          margin: 20px 0;
          width: 100%;
          ${vars.breakpoints.sm} {
            display: flex;
          }
          ul {
            &.current-refinements {
              padding: 0;
            }
          }
          .results .options .label {
            color: #616161;
            font-weight: ${vars.fonts.weightNormal};
          }
          button {
            &.refinement-pill {
              border: 1px solid ${vars.colors.grey60};
              border-radius: 5px;
              background: none;
              color: ${vars.colors.grey60};
              margin: 0 5px;
              line-height: 24px;
              padding: 7px 24px;
            }
            &.active {
              background-color: ${vars.colors.grey10};
              color: ${vars.colors.grey70};
              font-weight: ${vars.fonts.weightBold};
            }
            &:hover {
              cursor: pointer;
            }
          }
        }
        .ais-SortBy-select {
          color: ${vars.colors.grey60};
        }
        .ais-SearchBox-input {
          border-color: #222222;
          box-shadow: none;
          border-radius: 5px;
          &::placeholder {
            color: ${vars.colors.grey60};
            font-size: 1rem;
          }
        }
        .ais-SearchBox-form {
          height: 40px;
          min-width: 200px;
          &::before {
            background-color: inherit;
          }
        }
        .ais-Hits-list {
          margin: 15px 0px;
          display: grid;
          align-items: flex-start;
          grid-template-columns: 1fr;
          ${vars.breakpoints.sm} {
            grid-template-columns: 1fr 1fr;
          }
          ${vars.breakpoints.lg} {
            grid-template-columns: 1fr 1fr 1fr;
          }
          gap: 24px;
          .ais-Hits-item {
            padding: 0px;
            box-shadow: none;
            margin-bottom: 30px;
          }      
        }
        .pagination-mainContainer {
          justify-content: center;
          margin: 50px 0;
          .ais-Pagination {
            .ais-Pagination-list {
              .ais-Pagination-item--disabled {
                background-color: #EDEDED;
                .ais-Pagination-link, .ais-Pagination-list {
                  color: #767676;
                }
              }
              .ais-Pagination-item--selected {
                border: none;
                a {
                  background-color: #F8F8F8;
                  color: #222222;
                  border: 1px solid #222222;
                }
              }
              li {
                border: 1px solid #BCBCBC;
                border-radius: 1px;
                  a {
                    color: #222222;
                    border-radius: 1px !important;
                    &:hover, &:focus {
                      border-color: #222222 !important;
                    }
                  }
              }
              .ais-Pagination-item--previousPage, .ais-Pagination-item--nextPage {
                border: 1px solid #222222;
              }
            }
          }
        }
      `,
    },
    Loading: {},
    Form: {
      wrapper: `
        .field {
          margin: 10px 0;
          &.inline {
            display: flex;
            align-items: center;
            .label {
              min-width: 120px;
            }
          }
        }
        &.rate-review-modal-container {
          .field {
            margin: 30px 0;
          }
        }
      `,
    },
    CollapseBox: {
      wrapper: `
        margin-bottom: 20px;
      `,
      header: `
        background-color: ${vars.colors.primary1};
        color: white;
        padding: 20px;
      `,
      body: `
        padding: 20px;
        background-color: ${vars.colors.white};
      `,
    },

    WinePreferences: {
      wrapper: `
        h3 {
          text-transform: uppercase;
          font-size: 28px;
        }
        em{
          display: block;
          font-weight: bold;
          margin-bottom: 20px;
        }
      `,
      loadingMsg: `
      `,
      errorMsg: `
      `,
      singleChoiceList: `
        margin-bottom: 30px;

        label{
          margin: 10px 0;
          input {
            margin-right: 10px;
          }
        }
      `,
      singleChoiceTable: `
        th,
        td {
          width: 25%;
        }

        td {
          text-align: center;
        }

        thead {
          th,
          td {
            padding: 10px 5px;
          }
        }

        tbody {
          th,
          td {
            font-weight: normal;
            padding: 15px 5px;
          }

          tr:last-child {
            th,
            td {
              border-bottom-width: 0;
            }
          }
        }
      `,
      multiSelectList: `
        label{
          margin: 10px 0;

          input {
            margin-right: 10px;
          }
        }
      `,
    },

    GlobalLayout: {
      wrapper: `
      .no-results {
        .container {
          margin: 25px 0px;
        }
      }
      `,
    },

    GlobalHeader: {
      wrapper: `
        font-size: ${vars.fonts.sizeH5};
        &.mainHeader {
          z-index: 10 !important ;
          .sticky-header{
            position: fixed;
            top: 0;
            width: 100%;
            left: 0;
            z-index: 3;
            background-color: ${vars.colors.white};
            box-shadow: 0 0.0625rem 0.1875rem rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.05) 0 0.625rem 0.9375rem -0.3125rem,
            rgba(0, 0, 0, 0.04) 0 0.4375rem 0.4375rem -0.3125rem;
            .search-navbar-container{
              padding: 0;
              transition:all 0.9s ease;
              -webkit-transition:all 0.9s ease;
              -moz-transition:all 0.9s ease;
            }
            .mantine-HoverCard-dropdown {
              padding: 12px 0;
            }
          }
        ${vars.breakpoints.xs} { 
            position: sticky;
            top: 0;
            z-index: 500;
          }
          ${vars.breakpoints.lg} { 
              position: relative;
              top: unset;
              z-index: 10;
          }
          .nav-overlay  {
            display: none;
            position: fixed;
            left: 0;
            right: 0;
            height: 100%;
            background: #000000;
            opacity: 0.6;
            z-index: 1;
          }
          .minicart-overlay {
            position: fixed;
            left: 0;
            right: 0;
            height: 100%;
            background: #000000;
            opacity: 0.6;
            z-index: 1;
            top: 0;
          }
          .active-nav-overlay  {
            display: block
          }
        }
        .state-selector-mobile {
          display: flex;
          justify-content: center;
          padding: 10px;
          background: ${vars.colors.primary3};
          ${vars.breakpoints.lg} {
            display: none;
          }
        }
      `,
      stripBanner: `
        div{
          > * {
            margin: 0;
            padding: 15px;
          }
        }
      `,
      tertiaryNav: `
      display: none;
      ${vars.breakpoints.lg} { 
        display: block;
        padding: 1em 0;
      }
      .sticky-header &{
        display: none;
      }
      ul {
        display: flex;
        padding: 0;
        margin: 0;
        justify-content: end;
        li {
          list-style: none;
          margin-left: 48.3px;
          a {
            font-family: ${vars.fonts.familySecondary};
            color: ${vars.colors.black};
            font-weight: ${vars.fonts.weightNormal};
            text-transform: capitalize;
          }
        }
      }
    `,
      mainNavBarWrapper: `
        display: none;
        .logo-wrapper{
          display: flex;
          width: 49px;
          height: 64px;
          padding: 8px 0px;
          justify-content: center;
          align-items: center;
          gap: 10px;
        }

        ${vars.breakpoints.lg} {
          display: flex;
          align-items: center;
          justify-content: space-around;
          
          .input-on-focus & {
            display: none;
          }
        }
        
        .tabPanes-wrapper{
          width: 100%;
        }

        .is-sticky{
          display: flex;
          gap: 33px;
          &.is-sticky-searchbar {
            gap: 0px;
          }
        }
        .searchbar-input-container {
          position: relative;
        }
        .isSearchBar {
            position: absolute;
            top: 11px;
            left: 15px;
        }

        .isStickyCancelContainer {
          .isStickyCancelButton {
            display: block;
            text-decoration: none;
            padding: 0px 20px 0 0px;
            background: transparent;
            color: ${vars.colors.black};
            border: 0;
            cursor: pointer;
            &:hover, &:focus {
              outline:0;
            }
          }
        }
      
        ${vars.breakpoints.lg} {
            border-left: 0;
            margin: auto;
            .isStickyCancelButton {
              display: none;
              padding: 0;
            }
          }
          
        

        .search-user-cart{
          display: flex;
          gap: 32px;
          width: 10%;
        }

        .topLevelLinks{
          display: flex;
          justify-content: center;
          width: 100%;
          & > div {
             display: flex;
             & + div {
              display: none;
            }
          }
          .navigation-link .cms-conditional-container {
            flex-direction: column;
            a {
              text-wrap: initial;
              line-height: 18px;
              margin-bottom: 14px;
              width: fit-content;
            }
          }
        }
        
        .topLevelLink{
          padding-top: 0;
          padding-bottom: 0;
          &:hover .megaNavTab{
            color: ${vars.colors.grey50};
            text-decoration: underline;
            text-decoration-color: ${vars.colors.primary1};
            text-decoration-thickness: 4px;
          }
        }
        .megaNavTab:focus{
            text-decoration: none;
        }

        .isStickySearchBar{
          width: 100%;
        }

        .isSticky {
          color: ${vars.colors.black};
          font-family: ${vars.fonts.familySecondary};
          font-size: ${vars.fonts.sizeH5};
          font-style: normal;
          font-weight: ${vars.fonts.weightNormal};
          line-height: ${vars.fonts.lineHeightDefault};
          text-transform: uppercase;
          display: inline-block;
          text-underline-offset: ${vars.space.lg};
          padding: ${vars.space.lg} 0;

          &:hover{
            text-decoation: underline;
            text-decoration-color: ${vars.colors.primary1};
            text-decoration-thickness: 3px;
          }

          &.emphasize{
          color: ${vars.colors.primary1};
          font-family: ${vars.fonts.familySecondary};
          font-size: ${vars.fonts.sizeH5};
          font-style: normal;
          font-weight: ${vars.fonts.weightBold};
          line-height: ${vars.fonts.lineHeightDefault}; /* 171.429% */
          text-transform: uppercase;
          }
        }

        .megaNavTab {
          font-size: 18px;
          text-underline-offset: ${vars.space.lg};
          padding: ${vars.space.lg} 15px;
          display: inline-block;
          font-family: ${vars.fonts.headerMainNavTitle};
          color: ${vars.colors.grey50};
          letter-spacing: ${vars.fonts.fontLetterSpacing5};
          font-weight: 600;
          ${vars.breakpoints.xl} {
            padding: ${vars.space.lg} 20px;
          }

          &:hover{
            text-decoation: underline;
            text-decoration-color: ${vars.colors.primary1};
            text-decoration-thickness: 5px;
          }

          &.emphasize{
            color: ${vars.colors.primary1};
            font-weight: ${vars.fonts.weightBold};
          }
          &:focus{
            text-decoration: none;
          }
        }

        .mantine-HoverCard-dropdown{
          padding: 0;
          border: none;
          background: transparent;
          box-shadow: 0px 0px 0px;
          .header-main-nav-layout {
            background: ${vars.colors.white};
            border-radius: 0px 0px 12px 12px;    
            box-shadow: 0px 0px 3px 0px #DEDEDE inset;
            .dynamicComponents {
              padding: 22px 40px;
              .richtext-wrapper {
                &.richtext-has-bg {
                  padding: 1rem;
                }
                p {
                  margin-top: 1em;
                  margin-bottom: 1em;
                  &:first-child {
                    margin-top: 0;
                  }
                  &:last-child {
                    margin-bottom: 0;
                  }
                }
              }
            }
            .navigation-link a {
              letter-spacing: ${vars.fonts.fontLetterSpacing5};
            }
          }
          .mantine-ScrollArea-root  {
            .widget-cms-column > div {
              margin: 0;
              width: 100%;
              max-width: 100%;
            }
          }
        } 
      `,

      mainNavBarMenuTabPane: `
        text-align: left;
        margin-top: -8px;
        left: 0 !important;
        ${vars.breakpoints.xxl} {
          right: 0;
        }
        .header-main-nav-layout {
          h4{
            font-family: ${vars.fonts.headerMainNavSubTitle};
            font-size: 18px;
            font-weight: ${vars.fonts.weightBold};
            margin: 0 0 ${vars.space.lg} 0;
            color: ${vars.colors.primary1};
            letter-spacing: 0.01em;
            text-transform: unset;
          }
          a{
            line-height: 22px !important;
            font-size: ${vars.fonts.sizeH4};
            &:hover {
              color: ${vars.colors.primary1};
            }
          }
        }
      `,
      searchNavBar: `
      .state-selector {
        display: none;
        ${vars.breakpoints.lg} {
          display: block;
        }
      }
      .search-navbar-container {
        padding: 0;
        .grid-search-navbar-container{
          flex-wrap: nowrap;
        }
        .navbar-left-container {
          order: 2;
          display: flex;
          justify-content: center;
        }
        .navbar-center-container {
          position: relative;
          .sticky-header & {
            ${vars.breakpoints.lg} {
              position: initial;
              padding-right: 24px;
            }
            &.input-on-focus {
              position: relative;
            }
            max-width: none;
          }
          min-width: 83px;
          text-align: left;
          min-height: unset;
          display:flex;
          align-items: center;
          gap:10px;
          order: 1;
          ${vars.breakpoints.lg} {
            max-width: 480px;
          }
          ${vars.breakpoints.xl} {
            max-width: 540px;
          }
          ${vars.breakpoints.xxl} {
            max-width: 689px;
          }
          .sticky-nav-bar{
            display:none;
          }
          .sticky-header &{
            align-items: center;
            ${vars.breakpoints.lg} {
              .sticky-nav-bar{
                display:flex;
                width: 100%;
              }
            }
            .search-icon {
              display: initial;
            }
            .searchbar-grid-block-container {
              display: none;
              margin: 0 auto;
              right: 0;
              transition: all 1s;
              ${vars.breakpoints.lg} {
                max-width: 410px;
                position: fixed;
              }
              ${vars.breakpoints.xl} {
                max-width: 480px;
                position: fixed;
              }
              ${vars.breakpoints.xxl} {
                max-width: 612px;
                position: fixed;
              }
            }
            .header-search-bar{
              margin-left: auto;
            }
          }
          .search-overlay  {
            display: none
          }
          .isSearchBar {
            position: absolute;
            top: 5px;
            right: 5px;
            background: ${vars.colors.primary1};
            padding: 8px;
            border-radius: 50px;
            color: ${vars.colors.white};
            width: 36px;
            height: 36px;
          }
          .sticky-header & .header-mainnav-mobile{
            ${vars.breakpoints.lg} {
              display:none;
            }
          }
          &.input-on-focus {
            + .navbar-right-container {
              .sticky-header & {
                display: block;
              }
            }
            .header-mainnav-mobile, .search-icon {
              display: none;
            }
            .searchbar-grid-block-container {
              display: block;
              .searchbar-grid  {
                background: ${vars.colors.white};
                position: relative;
              }
            }
            .suffix-icon, .searchbar-cancel-link {
              display: block;
            }
            .search-overlay  {
              display: block
            }
            .aa-Form {
              border: 0;
              ${vars.breakpoints.lg} {
                border: 1px solid ${vars.colors.black};
              }
            }
          }
        }
        .navbar-right-container {
          order: 3;
          padding-right: 21px;
          & > div {
            gap: 0em;
            .favourites-col-container {
              display: none;
              ${vars.breakpoints.md} {
                display: block;
              }
            }
          }
          .sticky-header &{
            .sign-in-link {
              span {
                display: none;
                vertical-align: middle;
                margin-left: 0.5em;
                color: ${vars.colors.black};
              }
            }
            .header-cart-price-container {
              display: none;
            }
            .header-cart-count-container {
              background: transparent;
              padding: 0em;
              .header-cart-grid-container {
                min-width: 90%;
                .header-cart-price-container {
                  display: none;
                }
                .cart-quantity{
                  display: initial;
                }
              }
            }
          }
        }
      }
      ${vars.breakpoints.lg} { 
        border-bottom: 0.5px solid ${vars.colors.grey20};
        .search-navbar-container {
          padding: 0.8em 0;
          .navbar-left-container {
            order: 1;
            justify-content: flex-start;
            .sticky-header & {
              display:none;
            }
          }
          .navbar-center-container {
            min-width: unset;
            text-align: unset;
            min-height: 69px;
            flex: auto;
            order: 2
          }
          .navbar-right-container {
            & > div {
              .favourites-col-container {
                display: flex;
              }
              .sticky-header & .favourites-col-container {
                
              }
            }
          }
        }
      }
      ${vars.breakpoints.md} { 
        .navbar-center-container {
          min-width: 106px;
        }
        .search-navbar-container {
          .navbar-right-container {
            & > div {
              gap: 1em;
            }
          }
        }
      }

      //TODO: unhide when DSP-259 implemented
      .hamburger-menu-icon {
        display: none;
      }
    `,
      headerLogo: `
      img {
        display: block;
        max-width: 10em;
        width: 100%;

        ${vars.breakpoints.lg} { 
        max-width: 13.5em;
        }
      }
    `,
      stickymainNavWrapper: `
      #header-logo img {
        width: auto;
        min-width: auto;
      }
      .topLevelLink {
        .megaNavTab, .navigation-link h4 {
          font-size: ${vars.fonts.sizeDefault};
        }
        .navigation-link a {
          font-size: ${vars.fonts.sizeDefault};
          line-height: 18px !important;
          letter-spacing: ${vars.fonts.fontLetterSpacing5};
        }
      }
    `,
      headerFavourites: `
      a {
        &.icon-hover {
          color: ${vars.colors.primary1};
        }
        &.favourites-link {
          text-decoration: none;
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 5px;
          span {
            display: none;
            
            .sticky-header & {
              display: none;
            }
            ${vars.breakpoints.lg} {
              display: block;
            }
          }
        }
      }
    `,
      headerSignIn: `
      color: ${vars.colors.black};
      .user-logged-in-label {
        cursor: pointer;
        display: flex;
        align-items: center;
        flex-direction: column;
        gap: 5px;
      }
      .sign-in-label {
        width: 2em;
        height: 2em;
        background: ${vars.colors.headerSignInBtnSmall};
        color: ${vars.colors.white};
        display: inline-flex;
        border-radius: 50%;
        align-items: center;
        justify-content: center;
        text-transform: uppercase;
        vertical-align: middle;
      }
      .sign-in-name {
        margin-left: 0.3em;
        display: none;
        text-transform: capitalize;
        max-width: 90px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        ${vars.breakpoints.lg} { 
          display: inline-block;
          .sticky-header & {
            display: none;
          }
        }
      }
      .sign-in-link {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 5px;
        &:hover {
          text-decoration: none;
        }
        span {
          display: none;
          vertical-align: middle;
          color: ${vars.colors.black};
          ${vars.breakpoints.lg} {
            display: inline-block;
          }
        }
      }
    `,
      headerCart: `
      display: inline-flex;   
      border-radius: 0.35em; 
      color: ${vars.colors.black};
      background: transparent;
      position: relative; 
      padding: 0em;
      .header-cart-link:hover {
          text-decoration: none;
      }
      .header-cart-grid-container {
        margin-left: 0;
        margin-right: 0;
        .header-cart-price-container {
          display: none;
          padding-left: 0.75em !important; 
          font-family: ${vars.fonts.familySecondary};
          font-weight: ${vars.fonts.weightNormal};
        }
        .header-cart-icon-container {
          position: relative;
          padding-right: 0.75em;
          .cart-quantity {
            position: absolute;
            border-radius: 0.8em;
            min-width: 2em;
            min-height: 1.65em;
            background: ${vars.colors.headerCartBtnSmall};
            top: 2px;
            left: 1.45em;
            font-family: ${vars.fonts.familySecondary};
            font-weight: ${vars.fonts.weightBold};
            font-size: 0.78em;
            padding: 1.5px 3px 0px;
            line-height: ${vars.fonts.sizeLarge};
            text-align: center;
            display: inline-block;
            color: ${vars.colors.white};
          }
        }
      }
      &.has-quantity {
        :not(.sticky-header) &{
          background: transparent;
          color: ${vars.colors.black};
          .header-cart-link {
            color: ${vars.colors.black};
          }
          .header-cart-grid-container {
            .header-cart-icon-container {
              padding-right: 0.75em;
              .cart-quantity {
                background: ${vars.colors.headerCartBtnSmall};
                color: ${vars.colors.white};
              }
            }
          }
        }
      }
      .cart-confirmation-popup {
        background: ${vars.colors.white};
        border: 1px solid ${vars.colors.grey20};
        border-radius: 3px;
        padding: 10px;
        position: absolute;
        right: 0px;
        top: 68px;
        min-width: 180px;
        white-space: nowrap;
        color: ${vars.colors.black};
        display: none;
        i {
          padding-right: 0.15em;
          color: ${vars.colors.secondary6};
        }
        &.fadedown {
        ${vars.mixins.animateFadeInDown};
          display: block;
          z-index: 201;
        }
        &.fadeout {
          ${vars.mixins.animateFadeOutUp};
        }
        &::before {
          background: ${vars.colors.white};
          border: solid ${vars.colors.grey20};
          border-width: 0 1px 1px 0;
          content: '';
          display: block;
          left: 150px;
          padding: 7px;
          position: absolute;
          top: -8px;
          transform: rotate(225deg);
          width: 1px;
          z-index: 1;
        }
      }
      .header-cart-price-container {
        display:none;
      }
      .header-cart-link {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 5px;
      }
      .header-cart-icon-container {
        position: relative;
      }
      .cart-quantity {
        position: absolute;
        background: ${vars.colors.primary1};
        border-radius: 50px;
        border: 1px solid ${vars.colors.white};
        display: flex;
        align-items: center;
        justify-content: center;
        top: -7px;
        right: -8px;
        padding: 9px 5px;
        color: ${vars.colors.white};
        font-size: 14px;
        font-weight: 500;
        min-width: 20px;
        height: 20px;
      }

      ${vars.breakpoints.lg} { 
        .header-cart-price-container {
          display: block;
        }

        .header-cart-grid-container {
          .header-cart-price-container {
            display: block;
          } 
          .header-cart-icon-container {
            padding-right: 0;
            .cart-quantity {
              display: none;
            }
          }
        }
        &.has-quantity {
          ${vars.breakpoints.lg} {
            .header-cart-link, div.header-cart-link {
              .header-cart-price-container span,.header-cart-icon-container i {
                
              }
              .sticky-header & {
                .header-cart-price-container span,.header-cart-icon-container i {
                  color: ${vars.colors.black};
                }
              }
            }
            .header-cart-grid-container {
              .header-cart-icon-container {
                padding-right: 0.75em;
                .cart-quantity {
                  display: inline-block;
                  background: ${vars.colors.white};
                  color: ${vars.colors.headerCartBtn};
                  .sticky-header & {
                    background: ${vars.colors.headerCartBtn};
                    color: ${vars.colors.white};
                  }
                }
              }
            }
          }
          :not(.sticky-header) &{
            background: ${vars.colors.headerCartBtn};
            color: ${vars.colors.white};
            .header-cart-link {
              color: ${vars.colors.white};
            }
            .header-cart-grid-container {
              .header-cart-icon-container {
                .cart-quantity {
                  display: inline-block;
                  background: ${vars.colors.white};
                  color: ${vars.colors.headerCartBtn};
                }
              }
            }
          }
        }
      }
    `,
      stickyHeader: `
        position: sticky;
        top: 0;
        background: ${vars.colors.white};
        z-index: 1;
        border-radius: 0;
        .stickyMainNavBar-container{
          background-color: white;
        }
    `,
      headerSearchInput: `
      .search-icon {
        display: block;
        ${vars.breakpoints.lg} {
          display: none;
        }
      }
      .searchbar-grid-block-container {
        position: fixed;
        top: 0px;
        left:0;
        width: 100%;
        display: none;
        box-shadow: 0px 4px 25px rgb(0 0 0 / 6%);
        border-radius: 0;
        background: ${vars.colors.white};
        z-index: 1;
        min-width: 30%;
        transition: min-width 1s ease-out;
        ${vars.breakpoints.lg} {
          position: absolute;
          left: 0px;
          width: 100%;
          display: block;
          box-shadow: 0 0 0;
        }
        .searchbar-input-container {
          min-width: unset;
          position: relative;
          input {
            background-color: ${vars.colors.white};
            font-size: ${vars.fonts.sizeDefault};
            font-family: ${vars.fonts.familySecondary};
            width: 100%;
            padding: 10px 16px;
            line-height: normal;
            overflow: hidden;
            text-overflow: ellipsis;
            border: 0;
            border-radius: 0;
            &:focus {
              outline: none;
              border: 0;
              &::placeholder {
                opacity: 0;
              }
            }
            &:disabled {
              cursor: default;
            }  
            &::placeholder {
              color: ${vars.colors.grey40};
            }
            &:not(:placeholder-shown) ~ .suffix-icon {
              display: block;
            }
            // This is a temporary fix will be remove in sprint 10
            &.temp {
              border: 1px solid ${vars.colors.grey20};
              border-radius: 50px !important;
              box-sizing: border-box;
              padding: 10px 16px;
              height: 46px
            }
          }
          .prefix-icon {
            position: absolute;
            top: 5px;
            right: 5px;
            background: ${vars.colors.primary1};
            padding: 8px;
            border-radius: 50px;
            color: ${vars.colors.white};
            width: 36px;
            height: 36px;
            display: none;
            ${vars.breakpoints.lg} {
              display: block;
            }
          }
          
          .suffix-icon {
            display: none;
            position: absolute;
            top: 10px;
            right: 6px;
            cursor: pointer;
            ${vars.breakpoints.lg} {
              right: 15px;
            }
          }
        }
        .searchbar-cancel-container {
          border-left: 1px solid ${vars.colors.grey20};
          ${vars.breakpoints.lg} {
            position: absolute;
            right: -15%;
          }
          ${vars.breakpoints.xxl} {
            position: absolute;
            right: -12%;
          }
          .searchbar-cancel-link {
            display: block;
            text-decoration: none;
            padding: 0px 20px 0 16px;
            background: transparent;
            color: ${vars.colors.black};
            border: 0;
            cursor: pointer;
            &:hover, &:focus {
              outline:0;
            }
          }
          
          ${vars.breakpoints.lg} {
            border-left: 0;
            .searchbar-cancel-link {
              display: none;
              padding: 0;
            }
          }
        }
      }
    `,
      navStickyButton: `
      text-align: center;
      padding: 24px 0;
      border-top: 1px solid #DEDEDE;
      a + a {
        margin-left: 10px;
      }
    `,
    },
    Footer: {
      default: `
      font-size: ${vars.fonts.sizeDefault};
      ${vars.mixins.footerBase}
      .footer-nav-links {
        background: ${vars.colors.primary3};
        box-shadow: 0 0 0 100vmax ${vars.colors.primary3};
        .wrapper {
          .navItem-navigation {
            p{
              font-weight: ${vars.fonts.weightBold}; 
            }
            .tab-panel {
              ul{
                margin: 12px 0px 0px 20px;
                ${vars.breakpoints.lg} {
                  margin-left: 0;
                }
                li {
                  line-height : 35px;
                  padding: 0;
                }
              }
            }
          }
        }
      }
      .social-connections, 
      .footer-bottom-section {
        color: ${vars.colors.black};
      }
    `,
      navItems: `
      ${vars.mixins.footerNavItems}
    `,
    },
    DrawerMenu: {
      drawerContainer: `
        .mantine-Drawer-overlay {
          z-index: 501;
        }
        .mantine-Drawer-inner {
          z-index: 501;
          left: 0;
        }
        .mantine-Drawer-content {
          flex: 0 0 328px;
          ${vars.breakpoints.sm} {
            flex: 0 0 380px;
          }
        }
        .mantine-Drawer-body {
          padding: 0;
          height: 100%;
          border-top: ${vars.colors.grey20};
          display: flex;
          flex-direction: column;
          .drawer-header-logo-section {
            width: 100%;
            padding: 16px 24px;
            top: 0;
            z-index: 10;
            background-color: ${vars.colors.white};
            display: flex;
            align-items: center;
            justify-content: space-between;
            box-shadow: 0px -1px 0px 0px rgba(204, 204, 204, 1) inset;
            #header-logo a {
              border: 0;
              padding: 0;
              &:focus-visible {
                outline: none;
              }
            }
          }
          .drawer-menu-heading {
            font-weight: ${vars.fonts.weightBold};
            font-size: ${vars.fonts.sizeH4};
            letter-spacing: 0.03em;
            margin: 0;
            background: #EEEAE2;
            padding: 8px 16px;
            text-align: center;
            min-height: 60px;
            display: flex;
            align-items: center;
            justify-content: center;
            text-transform: uppercase;
            color: ${vars.colors.grey50};
            font-family: ${vars.fonts.familySecondary};
            margin-left: -24px;
            margin-right: -24px;
          }
          .drawer-main {
            background: ${vars.colors.primary3};
            height: 100%;
            display: flex;
            flex-direction: column;
            flex: 1 1 auto;
            overflow-y: scroll;
            overflow-x: hidden;
            padding-left: 24px;
            padding-right: 24px;
            .highlighted-link {
              box-shadow: 0px 1px 0px 0px #DBDBDB inset;
              * {
                color: ${vars.colors.primary1};
                text-decoration: underline;
              }
            }
            a,span {
              font-family: ${vars.fonts.familySecondary};
              font-weight: ${vars.fonts.weightNormal};
              font-size: ${vars.fonts.sizeRoot};
              color: #303030;
              line-height: 18px;
              padding: 12px 0;
              display: block;
              &:hover,&:focus,&:focus-visible {
                text-decoration: none;
                outline: 0;
                background-color: transparent;
              }
              span {
                border: 0;
                padding: 0;
              }
            }
          }
          .drawer-footer-sticky-button-section {
            width: 100%;
            padding: 16px;
            background-color: ${vars.colors.white};
            display: flex;
            boxShadow: 0px -5px 40px 0px rgba(0, 20, 30, 0.1);
            gap: 16px;
            left: 0;
            justify-content: center;
            align-items: center;
            a {
              letter-spacing: 0.03em;
              text-transform: uppercase;
              font-size: ${vars.fonts.sizeSmall};
              padding: 15px 12px;
              line-height: 14px;
              ${vars.breakpoints.sm} {
                padding: 15px 24px;
              },
            },
          },
        },
        
      `,
      mobileNavMenuContainer: `
        position: relative;
        margin: 0 -24px;

        a{
          padding: 18px 0px; //reapply mantine pad
          border-bottom-width: 1px;
          &:hover{
            text-decoration: none;
          }
        }

        .mantine-Drawer-body & .mantine-NavLink-body,
        .mantine-Drawer-body & .mantine-NavLink-rightSection {
          border: none;
          margin: 0;
          padding: 0;
        }

        .mantine-NavLink-root {
          border-top: 1px solid ${vars.colors.grey20};
          border-radius: 0;
          padding: 15px 24px;
          position: relative;
          display: flex;
          background: transparent;
          color: black;

          &:focus, &:hover{
            background: transparent;
            border-color: transparent;
            border-top: 1px solid ${vars.colors.grey20};
            color: black;
          }
        }

        .mantine-NavLink-children {
          padding-bottom: 20px;

          .mantine-NavLink-root {
            border-top-width: 0;
            padding: 7px 0;
          }
        }

        .mantine-NavLink-root:first-child {
          border-top-width: 0;
        }

        .navMenuItemIcon{
          ${vars.icons['chevron-right']}
          :before {
            ${vars.mixins.iconBase}
            font-size: ${vars.fonts.lineHeightDefault};
          }
        }
        .mainnav-mobile-drawer-social-panel {
          display: flex;
          justify-content: center;
          padding: 16px 24px;
          background-color: ${vars.colors.white};
          gap: 55px;
          border-top: 1px solid ${vars.colors.grey20};
          > div {
            a {
              padding: 0;
            }
          }
        }
        .mainnav-mobile-drawer-badges-panel {
          display: flex;
          flex-wrap: wrap;
          padding: 16px 24px;
          align-items: center;
          justify-content: center;
          gap: 10px;
          > div {
            &:nth-child(1) {
              flex-basis: 100%;
              text-align: center;
              order: 3;
            }
            a {
              padding: 0;
              border: 0;
            }
          }
        }
        .mainnav-mobile-drawer-footer-links-panel {
          padding: 0 24px;
          > div {
            box-shadow: 0px -1px 0px 0px #DBDBDB inset;
          }
          .navItem-navigation {
            margin: 0;
            p {
              color: ${vars.colors.grey60};
              text-transform: unset;
              font-size: 16px;
              font-weight: ${vars.fonts.weightNormal};
              padding: 16px 0;
            }
            &.navItem-navigation-active div p{
              color: ${vars.colors.primary1};
            }
            .tab-indicator {
              i {
                font-size: ${vars.fonts.lineHeightDefault};
              }
            }
            .tab-indicator.tab-collapse {
              i {
                color: ${vars.colors.grey60};
              }
            }
            .tab-indicator.tab-expand {
              i {
                color: ${vars.colors.primary1};
              }
            }
            .tab-panel {
              border-bottom: 1px solid #DBDBDB;   
              ul {
                margin: 0;
                li {
                  padding: 0;
                  a {
                    padding: 12px 0;
                    display: block;
                    color: ${vars.colors.grey50};
                    font-weight: ${vars.fonts.weightNormal};
                    line-height: 18px;
                    text-align: left;
                  }
                }
              }           
            }
            &:last-child {
              .tab-panel  {
                border-bottom: 0;
              }
            }
          }
        }

        .level1MenuPane{
          transition: left 0.3s ease;
          position: absolute;
          width: 100%;
          height: auto;
          top: 0;
          & > div.menu-container > div + div {
            display: none;
          }
          .cms-conditional-container:not(:first-child) {
            .level1Link:first-child {
              border-top: 1px solid ${vars.colors.grey20};
            }
          }
    
          .level1Link{
            span {
              font-family: ${vars.fonts.headerMainNavTitle};
              font-size: ${vars.fonts.sizeH4};
              font-weight: ${vars.fonts.weightBold};
              letter-spacing: 0.01em;
              text-transform: unset;
              padding: 0;
            }
            &[data-expanded=true] span, &:hover span{
              color: ${vars.colors.primary1};
            }
          }
          a.level1Link {
              padding: 15px 24px;
              display: flex;
              font-family: ${vars.fonts.headerMainNavTitle};
              font-size: ${vars.fonts.sizeH4};
              font-weight:  ${vars.fonts.weightBold};
              letter-spacing: 0.01em;
              text-transform: unset;
              text-decoration: none;

            &:not(:first-child) {
              border-top: 1px solid ${vars.colors.grey20};
            }
          }
          a.level1Link:hover {
              color: ${vars.colors.primary1}; 
          }
          a.level1Link > div {
              margin-right: auto;
          }
        }
        .level2And3MenuPane {
          transition: left 0.3s ease;
          padding: 0 24px;
          position: absolute;
          width: 100%;
          height: 100%;
          .mantine-NavLink-root {
            padding-right: 0;
            padding-left: 0;
            span {
              font-family: ${vars.fonts.familySecondary};
              font-weight: ${vars.fonts.weightMedium};
              font-size: ${vars.fonts.sizeH4};
              color: #303030;
              padding: 0;
            }
            &[data-expanded=true] span{
              color: ${vars.colors.primary1};
              font-weight: ${vars.fonts.weightBold};
            }
            .mantine-NavLink-rightSection[data-rotate] {
              transform: rotate(-90deg);
            }
            &.level2Link .mantine-NavLink-rightSection[data-rotate] {
              transform: rotate(180deg);
            }
            &:last-of-type {
              border-bottom: 1px solid ${vars.colors.grey20};
              &[data-expanded=true] {
                border-bottom: 0;
              }
              + div {
                border-bottom: 1px solid ${vars.colors.grey20};
              }
            }
          }
          .mantine-NavLink-children {
            padding: 0;
            a {
              display: block;
              padding: 12px 0;
              font-weight: ${vars.fonts.weightNormal};
              color: ${vars.colors.grey50};
              text-align: left;
              letter-spacing: 0em;
              &.navMenuSectionLink {
                text-decoration: underline;
                color: ${vars.colors.primary1};
              }
            }
          }
          a.navMenuBackLink{
            border-bottom: 0;
            text-transform: none;
            cursor: pointer;
            background: #EEEAE2;
            padding: 8px 16px;
            text-align: center;
            min-height: 60px;
            display: flex;
            align-items: center;
            margin-left: -24px;
            margin-right: -24px;
            span {
              flex: 1 1 0px;
              font-family: ${vars.fonts.familySecondary};
              font-weight: 600;
              font-size: ${vars.fonts.sizeH4};
              letter-spacing: 0.03em;
              text-transform: uppercase;
              color: ${vars.colors.grey50};
            }
          }
          .navMenuContent{
            margin-top: 20px;
            //TODO: this tweaks the styling of the link in the MenuBanner but we should 
            //find a more elegant/targetted way to fix this
            a{
              border: 0;
              padding: 0;
            }
            .richtext-wrapper {
              p {
                margin-top: ${vars.space.sm};
                margin-bottom: ${vars.space.sm};
                line-height: initial;
                &:first-child {
                  margin-top: 0;
                }
                &:last-child {
                  margin-bottom: 0;
                }
                a {
                  line-height: initial;
                }
              }
              &.richtext-has-bg {
                padding: 1rem;
              }
            }
          }
          .navMenuContent:has(.richtext-wrapper){
            margin-bottom: 10px;
            margin-top: 0px;
          }
        }
      `,
    },
    MenuDropdown: {
      dropdown: {
        boxShadow: `0px 2px 20px 0px #00141E33`,
        borderRadius: '12px',
        margin: 0,
        width: '190px !important',
        '&.mantine-Menu-dropdown': {
          padding: '8px 0px 15px',
        },
        '&.account-navbar .dynamicComponents': {
          textAlign: 'left',
          a: {
            padding: '8px 21px',
            display: 'block',
          },
        },
      },
      item: {
        textAlign: 'left',
        padding: '8px 21px !important',
        '&:hover': {
          backgroundColor: 'transparent',
          textDecoration: 'underline',
        },
      },
      itemLabel: {
        fontFamily: vars.fonts.familySecondary,
        fontWeight: vars.fonts.weightNormal,
        fontSize: vars.fonts.sizeRoot,
        color: vars.colors.black,
        lineHeight: 'normal',
        '.signout-button &': {
          color: `${vars.colors.primary1}`,
        },
      },
      arrow: {
        border: `1px solid transparent`,
        borderBottomColor: 'transparent',
        borderRightColor: 'transparent',
      },
      divider: {
        borderColor: vars.colors.grey20,
        marginTop: '12px',
        marginBottom: '11px',
      },
    },
    Product: {
      ItemCard: `      
        position: relative;
        .zoom {
          position: absolute;
          top: 10px;
          left: 10px;
          z-index: 2;
          background: ${vars.colors.white};
          padding: 6px;
        }
        .info-container {
          gap: 10px;
          display: grid;
          margin-bottom: 20px;
          .product-name {
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            min-height: 33px;
          }
        }
        .buyers-price {
          text-decoration: line-through;
        }
        .sale-price {
          font-weight: ${vars.fonts.weightBold};
        }

        .cart-button {
          button {
            border-radius: 0px;
            line-height: 16px;
            padding: 8px 24px;
          }
        }
       
        font-size: 1rem;
        width: 100%;
        
        .product-image-container {
          position: relative;
          padding: 25px;
          margin-bottom: 15px;
          justify-content: center;
          text-align: center;

          &.no-background {
            background: none;
          }
          
          background-color: ${vars.colors.primary3};

          .product-image {
            padding: 20px;
            position: relative;
            height: 200px;
          }
        }
      
      .mantine-Modal-body {
        .zoom-container {
          position: relative;
          height: 70vh;
        }
      }
        
      `,
      flavourProfile: `
      .item {
        font-size: 18px;
        line-height: ${vars.fonts.lineHeightDefault};
        font-weight: ${vars.fonts.weightNormal};
        margin-bottom: 20px;
        &:last-child {
          margin-bottom: 0;
        }
        .item-title {
          font-weight: ${vars.fonts.weightBold};
        }
      }
      `,
      bazaarvoice: `
      .reviews-container {
        padding: 15px;
        background: ${vars.colors.primary3};
        margin-bottom: 15px;
        h2 {
          &.title {
            text-align: center;
          }
        }
      }
      `,
      averageCaseRating: `
        display: flex;
        grid-gap: 10px;
        align-items: center;
        button {
          background: none;
          padding: 0;
          i {
            color: ${vars.colors.black};
          }
          &:hover {
            background: none;
          }
        }
      `,
      enPrimeur: `
      display: flex;
      justify-content: space-between;
      a {
        text-decoration: underline;
        &:hover {
          text-decoration: none;
        }
      }
      .reserve-text {
        font-weight: ${vars.fonts.weightBold}
      }
      border: 1px solid ${vars.colors.grey20};
      padding: 15px;
      background: #F9F5EF;
      margin-bottom: 15px;
      `,
      compactOrderForm: `
        .add-to-cart-section {
          display: flex;
          gap: 10px;
          .quantityFields {
            border: 1px solid ${vars.colors.grey30};
            display: flex;
            align-items: center;
            padding: 0 10px;
            .quantity {
              width: 40px;
              text-align: center;
              border: 0;
            }
            .input-group-prepend, .input-group-append {
              button {
                border: 0;
                background: transparent;
              }
            }
          }
          
        }
      `,
      presell: `
      display: flex;
      justify-content: space-between;
      .reserve-text {
        font-weight: ${vars.fonts.weightBold}
      }
      border: 1px solid ${vars.colors.grey20};
      padding: 15px;
      background: #F9F5EF;
      margin-bottom: 15px;
      `,
      orderform: `
      flex:1;
      .border-bottom-ccc {
        border-bottom: 1px solid ${vars.colors.grey20};
        padding-bottom:6px;
        &.with-padding{
          padding-bottom:18px;
          margin-bottom: 8px;
        }
        &.with-margin-bottom{
          padding-bottom:24px;
          margin-bottom:8px;
        }
      }
      &.bulk-deal-button-container {
        margin: 15px 0px;
        .deal {
          margin: 15px 0px;
          border-bottom: 1px solid ${vars.colors.grey20};
          padding-bottom: 10px;
        }
        
        .price-title {
          font-weight: ${vars.fonts.weightBold};
          margin-bottom: 10px;
          a {
            text-decoration: underline;
          }
        }
        .top-price-section {
          padding-top: 5px;
          .add-to-cart-section {
            padding-top: 0;          
          }
        }
      }
      .happy-to-wait-container {
        background-color: #D7E0EE;
        padding: 15px;
        text-align: center;
        margin-bottom: 15px;
        width: 100%;
        @media (min-width: 1025px) {
          padding: 5px;
          margin-bottom: 5px;
        }
        ${vars.breakpoints.lg} {
          padding: 4px 8px;
          margin-bottom: 10px;
        }
        ${vars.breakpoints.xl} {
          padding: 8px;
          margin-top: 20px;
          margin-bottom: 15px;
        }
        @media (min-width: 1400px) {
          margin-top: 0px;
          padding: 15px;
        }
        .date-expected {
          font-weight: ${vars.fonts.weightBold};
          margin-bottom: 5px;
        }
      }
      .add-on-container {
        padding-top: 10px;
        color: ${vars.colors.primary1};
        margin: 10px 0px;
        font-weight: ${vars.fonts.weightBold};
      } 
      &.out-of-stock {       
        .sold-out-text{
          padding: 15px;
          border: 1px solid ${vars.colors.error};
          border-radius: 5px;
          margin-bottom: 20px;
          display: flex;
          align-items: flex-start;
        }
        .sold-out-text i{
          color: ${vars.colors.error};
          background: ${vars.colors.primary7};
          padding: 10px;
          border-radius: 50%;
          margin-right: 15px;
        }
        .sold-out-text span{
          font-weight: ${vars.fonts.weightBold};
        }
        .sold-out-text p{
          margin-bottom: 0;
        }
        .clickToViewSimilarWines {
          cursor: pointer;
          padding: 15px;
          border: 1px solid ${vars.colors.grey50};
          border-radius: 5px;
        }
      }   
      &.cart-state-compliance {       
        .sold-out-text{
          padding: 15px;
          margin-bottom: 20px;
          display: flex;
          align-items: flex-start;
          background: ${vars.colors.primary7};
        }
        .sold-out-text span {
          font-weight: ${vars.fonts.weightBold};
        }
        .sold-out-text p {
          margin-bottom: 0;
        }
        .clickToViewSimilarWines {
          cursor: pointer;
          padding: 15px;
          border: 1px solid ${vars.colors.grey50};
          text-transform: uppercase;
          text-align: center;
        }
      }  
      .vpp-btn {
        margin-bottom: 15px;
      }
      .cart-button {
          button {
            display: flex;
            gap: 10px;
            align-items: center;
            justify-content: center;
            width: 100%;
            &:hover {
              cursor: pointer;
            }
            &:disabled {
              cursor: default;
            }
          }
        }
      .grid-addto-cart-btn {
        height: 45px;
      }
      .vpp-price {
        color: ${vars.colors.primary1};
        padding-bottom: 16px;
        // border-bottom: 1px solid ${vars.colors.grey20};
        &.first-price-head{ 
          padding-bottom:0;
          .price{
          font-size: 28px;
          }
        }
        .price {
          font-size: ${vars.fonts.sizeH3};
          font-family: ${vars.fonts.familyTertiary};
          font-weight: ${vars.fonts.weightBold};
        }
      }
            .tiered-price-container {  
        color: ${vars.colors.primary1};
        font-size: ${vars.fonts.sizeLarge};
        .price {
          font-size: ${vars.fonts.sizeH3};
          font-family: ${vars.fonts.familyTertiary};
          font-weight: ${vars.fonts.weightBold};
        }
        div {
          margin-bottom: 15px;
          &:first-child {
            .price {
              font-size: 28px;
            }
          }
          &:last-child {
            // padding-bottom: 10px;
            // border-bottom: 1px solid ${vars.colors.grey20};
          }
        }
      }
      .show-qty-error{
        margin-top:16px;
        padding:8px;
        background-color:${vars.colors.errorLight};
        color:${vars.colors.error};
      }
      .add-to-cart-section {
        display: flex;
        justify-content: space-between;
        gap: 16px;
        padding-top:24px;
        &:not(:last-child) {
          padding-bottom:24px; 
      }
      &.last-addto-cart-section{
        padding-bottom: 0; 
      }
        .customProdQantityField {
          display: flex;
          align-items: center;
          justify-content: space-between;
          .quantity {
            width: 40px;
            min-height: 34px;
            text-align: center;
            padding: 6px;
            font-size: ${vars.fonts.sizeDefault};
            color: ${vars.colors.black};            
            border-radius: 5px;
            border: 1px solid ${vars.colors.grey20};
            margin: 0 auto;
            font-size: 16px;
            &:focus {
              outline: none;
            }
            &.form-control{
              border: none;
            }
          }
          input::-webkit-outer-spin-button,
          input::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
          }
          input[type=number] {
            -moz-appearance: textfield;
          }
          .btn {
            width: 34px;
            height: 34px;
            background: ${vars.colors.white};
            border-radius: 5px;
            border: 1px solid ${vars.colors.black};
            color: ${vars.colors.black};
            padding: 0;
            cursor: pointer;
            &:disabled{
              color: ${vars.colors.grey20};
              border: 1px solid ${vars.colors.grey20};
              cursor: auto;
            }
          }
          & div:first-child{
            max-width:43px;
            margin-right: 18px;
            font-size: ${vars.fonts.sizeSmall};
          }
        }
        .add-to-cart-btn-container {
          .add-to-cart-btn {
            font-size: ${vars.fonts.sizeLarge};
            font-weight: 400;
            line-height: ${vars.fonts.lineHeightDefault};
          }
        }
      }
      .top-price-section {
        display: flex;
        justify-content: space-between;
        font-size: ${vars.fonts.sizeDefault};
        line-height: ${vars.fonts.lineHeightDefault};
        // margin-bottom: ${vars.fonts.lineHeightDefault};  
        padding-top: 20px;
        &.mixed-case {
          padding-top: 24px;
        }   
        
        .price-section {      
          &.saving {
            color: ${vars.colors.primary1};
            .price-with-strike{
              font-size:28px;
            }
            & .case-details {
              font-size:16px;
            }
          }   
          &.presell {
            .total-price {
              margin-top: 16px;
              font-weight: ${vars.fonts.weightBold};
            }
          } 
        .price {
          font-size: 28px;
          line-height: ${vars.fonts.lineHeightDefault};
        } 
        .strike {
          text-decoration: line-through;
          padding-left: 5px;
          line-height: 22.4px;
        }
        }
        .sale-price {
          .price {
            font-size: 18px;
            font-family: ${vars.fonts.familyTertiary};
            line-height: 25.2px;
            font-weight: ${vars.fonts.weightBold};
          }
        }
                
        .case-count {
          color: ${vars.colors.primary1};
        }
        .bottle-price {
          font-weight: ${vars.fonts.weightBold};
          font-size: 16px;
        }
        
        .saving-section {
          text-align: right;
          .saving-section-content {
            text-align:center;
            border-radius: 30px;
            background: ${vars.colors.primary5};
            font-size: 12px;
            font-weight: ${vars.fonts.weightBold};
            line-height: 18px;
            padding: 5px 8px;
            .strikePrice {
              text-decoration: line-through;
            }
            .saving {
              color: ${vars.colors.primary1};
              .sale-price, .case-details{
                color: ${vars.colors.primary1}!important;
              }
              
            }
          }
        }
        .strong {
          font-weight: bold;
        }
      }

      .view-wine-link {
        z-index: 9;
        text-decoration: underline;
        margin: 6px 0 10px;
        font-size: 16px;
        line-height: 24px;
        color: ${vars.colors.grey50}
      }

      .bulk-add-to-cart {
        display: grid;
        gap: 10px;
        .bulk-deal-link {
          line-height: ${vars.fonts.lineHeightDefault};
          color: ${vars.colors.primary1};
          padding: 5px 0px 8px 0px;
          //border-bottom: 1px solid ${vars.colors.grey20};
        }
      }

      .separation-line {
        width: 100%;
        text-align: center;
        border-bottom: 1px solid ${vars.colors.grey20};
        line-height: 1px;
        margin-bottom: 22px;
        font-size: ${vars.fonts.sizeLarge};
        text-transform: uppercase;
        color: ${vars.colors.grey30};
        span {
          background:${vars.colors.white}; 
          padding: 0 33px;
        }
        &.tired-promo-button{
          margin-top: 24px;
          margin-bottom: 22px;
        }
      } 

      .sale-price{
        color: ${vars.colors.grey50}
      }
      .saving .sale-price{
        color: ${vars.colors.primary1}
      }
      .vpp-price,.sale-price{
        font-size: ${vars.fonts.sizeLarge};
        margin-top: 20px;
      }
      `,
      pagination: `
      display: flex;
      align-items: center;
      justify-content: end;
      border-width: 1px 0 1px 0;
      padding: 0px;
      margin: 0px;
      .pagination-bottom{
        margin: 20px 0;
      }
      @media (max-width: 767px) {
        display: block;
      }
      li {
        list-style: none;
        &.page-count {
          margin-right: 25px;
          @media (min-width: 768px) and (max-width: 991px) {
            font-size: 12px;
            margin-right: 15px;
          }
          @media (max-width: 767px) {
            display:none;
            margin-right: 0;
            margin-bottom: 20px;
            text-align: center;
          }
        }
        .ais-Pagination-list {
          @media (max-width: 767px) {
          display: flex;
          justify-content: space-between; 
          align-items: center; 
          }
          li {
            width: 40px;
            height: 40px;
            display: inline-flex;
            align-items: center;
            margin: 0 5px;
            border-radius: 5px;
            @media (max-width: 767px) {
              margin: 0 3px;
            }
            a {
              margin-left: 0 !important;
              display: inline-flex;
              align-items: center;
              justify-content: center;
              width: 40px;
              height: 40px;
              box-shadow: none;
              border-radius: 5px !important;
              border: none;
              margin: 0;
              &:active,
              &:hover,
              &:focus {
                background-image: none !important;
                border: none;
                border-color: transparent !important;
                box-shadow: none !important;
                text-decoration: none;
              }
            }
            &.ais-Pagination-item--previousPage {
              border: 1px solid ${vars.colors.black};
              @media(max-width:767px){
                justify-content:left;
              }
              a {
                border-radius: 3px 0 0 3px;
                background: transparent;
                border: none;
                outline: none;
                color: #333;
                box-shadow: none;
              }
              a,
              span {
                font-size: 26px;
                line-height: 0;
              }
            }
            &.ais-Pagination-item--disabled {
              border: 1px solid #969696 !important;
              a {
                pointer-events: none;
                color: #969696;
                border-radius: 0 !important;
                padding: 0;
                width: 40px;
                height: 40px;
                box-shadow: none;
                background: transparent;
                border: none;
              }
            }
            &.ais-Pagination-item--selected {
              a {
                pointer-events: none;
                background: ${vars.colors.primary1};
                color: ${vars.colors.white};
                p {
                  margin: 0px;
                }
              }
              @media(max-width:767px){
                display:none;
              }
            }
            &.ais-Pagination-item-notSelected {
              a {
                p {
                  margin: 0px;
                }
              }
              @media(max-width:767px){
                display:none;
              }
            }
            &.ais-Pagination-item--nextPage {
              border: 1px solid ${vars.colors.black};
              a,
              span {
                font-size: 26px;
                line-height: 0;
                margin-left: 0 !important;
              }
              a {
                margin-left: 0;
                background: transparent;
                border: none;
                outline: none;
                box-shadow: none;
              }
            }
            @media (min-width: 768px) and (max-width: 991px) {
              width: 35px;
              height: 35px; 
              a {
                width: 35px;
                height: 35px;
              }
            }
          }
          .mobileView-Pagination{
            @media(min-width : 768px){
              display:none;
            }
          }
        }
      }
      `,
      countryNameGrapeVariety: `
      display: flex;
      font-size: 14px;
      line-height: 18.4px;
      .country-grapeVariety {
        display: flex;
        align-items: center;
        &.grapeVariety-container {
          margin-left: 10px;
          i {
            margin-right: 3px;
            font-size: 21px;
          }
        }
      }
      img {
        margin-right: 8px;
      }
      `,
      caseComponentsMobile: `
      position: relative;
      .layout {
        display: flex;
        align-items: center;
        justify-content; center;
        flex-direction: column;
        margin: 40px 0px 0px;
        background-color: ${vars.colors.primary3};
        padding: 15px;
        .case-compo-heart-icon {
          position: absolute;
          right: 10px;
          padding: 12px;
          background: white;
          border-radius: 25px;
          width: 44px;
          height: 44px;
        }
        .product {
          &-image {
            position: relative;
            height: 115px;
            width: 60px;
          }
        }
        .title {
          font-weight: ${vars.fonts.weightBold};
          font-size: 22px;
          line-height: ${vars.fonts.lineHeightDefault};
          color: #CF004F;
          margin-bottom: 15px;
          margin-top: 12px;
        }
        .quantity {
          margin-top: 12px;
          font-weight: ${vars.fonts.weightBold};
          font-size: ${vars.fonts.sizeLarge};
          text-align: center;
        }
        .description{
          text-overflow: ellipsis;
          white-space: break-spaces;
          max-width: 38rem;
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }
      }
      .redirect-link-container{
        margin: 10px 0;
        .redirect-link {
          margin-top: 15px; 
          text-decoration: underline;
          font-weight: 500
        }
      }
      
      `,
      wineDetails: `
        ul {
          list-style: none;
          padding: 0;
          margin: 0;
          display: grid;
          gap: 10px;

          ${vars.breakpoints.lg} {           
            grid-template-columns: repeat(2, 1fr); 
            align-items: center;         
          }

          @media print {      
            grid-template-columns: repeat(2, 1fr);
          }

          li {
            a {
              display: flex;
              align-items: center;
              cursor: pointer;
              &:hover {
                text-decoration: none;
              }
              span {
                margin-left: 4px;
              }
            }
            i {
              margin-right: 10px;
              width: 32px;
              @media print {      
                width: 22px;
              }
            }
            img {
              margin-right: 18px;
              width: 24px;
              @media print {      
                margin-right: 10px;
              }
            }
            svg {
              margin-right: 10px;
              width: 32px;
              height: 32px;
              @media print {      
                width: 22px;
              }
            }
          }
      }
      `,
      subscribeSave: `
      .saving-section-content {
        text-align: center;
        border-radius: 30px;
        background: rgba(255, 168, 161, 0.4);
        font-size: 12px;
        font-weight: 700;
        line-height: 18px;
        padding: 5px 8px;
        .saving {
          color: ${vars.colors.primary1};
        }
      }
      .subscribe-save-container {
        display: flex;
        justify-content: space-between;
        gap: 10px;
      }
  
      .subscribe-save-price-container {
        // display: flex;
        // justify-content: space-between;
        // margin-bottom: 15px;
        // gap: 10px;
      }
  
      .mantine-Accordion-chevron {
        &[data-rotate] {
          transform: rotate(45deg);
          i {
            ${vars.icons['radio-selected']};
            &::before {
              ${vars.mixins.iconBase};
            }
          }
        }
      }
  
      .pricing-container {
        display: grid;
        grid-template-columns: 1fr 1fr;
        align-items: center;
      }
  
      .price {
        &.vpp-price {
          color: ${vars.colors.primary1};
        }
        .case-price {
          font-family: ${vars.fonts.familyTertiary};
          font-weight: ${vars.fonts.weightBold};
        }
        .strike {
          text-decoration: line-through;
        }
      }

      .cart-button {
        button {
          display: flex;
          gap: 10px;
          align-items: center;
          justify-content: center;
          width: 100%;
          &:hover {
            cursor: pointer;
          }
          &:disabled {
            cursor: default;
          }
        }
      }

      .mantine-Accordion-label {
        .label-title {
          text-transform: uppercase;
        }
      }
      .mantine-Accordion-control {
        align-items: baseline;
      }
      .mantine-Accordion-content {
        word-break: normal;
        padding: 0 15px 15px 40px;
        .top-price-section {
          padding-top: 0px;
        }
        .pricing-container {
          display: flex;
          justify-content: space-between;
          gap: 10px;
          margin-bottom: 8px;
        }
      }
      .mantine-Accordion-item[data-active] {
        border-color: ${vars.colors.success};
        border-width: 2px;
        background-color: ${vars.colors.primary3};
        .separation-line {
          span {
            background:${vars.colors.primary3};
          }
        }
      }
      .mantine-Accordion-item {
        background-color: transparent;
      }
      `,
      wrapper: `
      max-width: ${vars.breakpointWidths?.xl}px;
      margin-left: auto;
      margin-right: auto;
      padding: 0px 15px;
      &.giftPage {
        max-width: 1680px;
      }

      .see-more-description{
        background: none;
        color: ${vars.colors.grey50};
        line-height: 24px;
        font-size: ${vars.fonts.sizeH4};
        padding: 0;
        text-decoration-line: underline;
        cursor: pointer;
        margin: 0 0 16px 0;
        box-shadow: none;
        border: none;
      }

      .image-container {
        &.about-producer {
          position: relative;
          min-height: 300px;
        }
      }

      .accordion-mobile-wine-details {
        button {
          background-color: ${vars.colors.primary3};
          padding: 0px 14px;
        }
        .border-bottom{
          background: red;
        }
        .mantine-Accordion-label {
          font-weight: ${vars.fonts.weightBold};
          font-size: ${vars.fonts.sizeLarge};
          font-family: ${vars.fonts.familyTertiary};
        }
        .mantine-Accordion-panel {
          background-color: ${vars.colors.primary3};
          @media print {
            display: block!important;
            height: auto!important;
            & div:first-child {
              opacity: 1!important;
            }
          }   
        }
        .mantine-Accordion-content {
          padding-top: 1rem;
        }
        .mantine-Accordion-item{
          .mantine-Accordion-chevron{
            @media (min-width: 400px) and (max-width: 900px) {
              width: 2.5rem;
            }
          }
        }
      }

      .print-share-container {
        background-color: ${vars.colors.primary3};
        padding: 20px;
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        .label {
          display: none;
        }
      }

      .gift-main-container {
        .gift-details-container {
          background-color: #F8F5EF;
          width: 100vw;
          margin-left: calc(-50vw + 50%);
          .product-layout {
            padding: 60px 20px;
            gap: 15px;
              .layout-name {
                order: 1;
                .description-container {
                  margin-bottom: 0;
                }
              }
              .layout-image {
                order: 0;
                  .image-container {
                    padding: 20px 0;
                    .image-size {
                      height: 300px;
                    }
                  }
              }
              .layout-details {
                order: 2;
                .top-price-section {
                  padding: 0;
                  .price-section {
                    .sale-price {
                      display: flex;
                      align-items: center;
                        .price {
                          font-size: 22px;
                          font-weight: 600;
                          line-height: 26.4px;
                          color: #222222;
                        }
                        .strike {
                          color: #616161;
                        }
                    }
                    .case-details {
                      display: none;
                    }
                  }
                  .saving-section {
                    display: none;
                  }
                }
                .add-to-cart-section {
                  justify-content: unset;
                  .customProdQantityField {
                    div:first-child {
                      display: none;
                    }
                    .quantity {
                      background-color: #F8F5EF;
                    }
                  }
                }
              }
          }
        }
        .gift-content-container {
          width: auto;
          margin: 0 auto;
          padding: 50px 0;
          .richtext-wrapper {
            margin-bottom: 35px;
          }
          a:last-child {
            display: inline-block;
            margin-top: 10px;
            text-decoration: underline;
            font-size: 16px;
          }
        }
        .gift-banner-container {
          background-color: #F8F5EF;
          width: 100vw;
          margin-left: calc(-50vw + 50%);
          padding: 0;
          & > div {
            overflow: unset;
            margin-bottom: 0;
          }
          .banner {
            flex-direction: column-reverse;
            margin: 0;
            .banner-content-wrapper {
              .banner-content {
                padding: 0;
                margin-bottom: 40px;
              }
              .banner-button {
                padding: 0;
                a {
                  width: 100%;
                }
              }
            }
          }
        }
      }

      .product-layout {
        display: flex;
        gap: 25px;
        flex-direction: column; /* Default layout for mobile */
        margin-top: 20px;

        .richtext-wrapper {
          a {
            text-decoration: underline;
          }
        }
        
        @media print {
          display: grid ;
          gap: 0px;
          grid-template-columns: 1fr !important;
          .layout-name {
            .main-title {
              font-size: 22px;
            }
            .sub-title {
              font-size: 18px;
              padding: 0;
              margin-top: 5px;
              margin-bottom: 0;
            }
            .rating-country-container {
              margin-top: 5px;
            }
            .bv_main_container {
              .bv_main_container_row_flex {
                margin: 3px 0 0px !important
              }
              .bv_main_container_row_normal, .bv_main_container_line_break, .bv_main_container_CTA_row {
                display: none !important;
              }
            }
          }
        }

        .description-container {
          font-size: ${vars.fonts.sizeLarge};
          line-height: ${vars.fonts.lineHeightDefault};
          margin: 20px 0px;
          ${vars.breakpoints.md} {  
            margin: 0px 0px 20px 0px; 
          }
          @media print {
            font-size: 12px;
            margin: 0px 0px 10px;
          }
        }      

        .rating-country-container {
          margin-top: 15px;
          .rating {
            display: flex;
            a {
              display: inline-flex;
              .star-icons{
                padding-top: 2px;
              }
              .rating-cout {
                margin: 0 15px 0;
                font-size: ${vars.fonts.sizeDefault};
                font-weight: ${vars.fonts.weightBold};
              }
              .review-count {
                margin-left: 10px;
                text-decoration: underline;
              }
            }
          }
          .country {
            display: flex;
            align-items: center;
            font-size: ${vars.fonts.sizeLarge};
            line-height: ${vars.fonts.lineHeightDefault};
            img, svg {
              margin-right: 10px;
            }
          }
        }

        .unlimited-banner {
          margin-top: 24px;
          background: #e6eff7;
          display: flex;
          padding: 16px;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          gap: 12px;
          align-self: stretch;
          .join-unlimited {
            font-weight: 400;
            background: #112b4b;
          }
        }

        .money-back-guarantee {
          background-color: ${vars.colors.lWHUELWBlue};
          padding: 15px;
          margin: 30px 0;
          display: flex;
          align-items: center;
          justify-content: space-between;
          .content {
            max-width: 429px;
            .descp {
              font-weight: ${vars.fonts.weightBold};
              padding-bottom: 10px;
            } 
          }
          .icon i:before {
            font-size: 44px;
          }
        }

        .product-info-container {
          // position: absolute;
          // top: 15px;
          // left: 15px;
          // display: flex;
          // flex-direction: column;
          .stock {
            .case-count {
              font-size: ${vars.fonts.sizeLarge};
              font-weight: ${vars.fonts.weightBold};
            }
          }
          
          .sold-last-hour {
            .image-count {
              position: relative;
              display: inline-block;
              .sold-count {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                left: 0;
                right: 0;
                margin: 0 auto;
                text-align: center;
              }
            }
            .sold-last-hour-text {
              max-width: 56px;
              font-size: 12px;
              line-height: 18px;
              font-weight: 500;
            }
          }          
        }

        &.mixed {
          .product-image-container {
            height: 100%;
            .image-container {
            height: inherit;
            .image-size {
              position: relative;
                height: 260px;
                ${vars.breakpoints.md} {  
                  height: inherit; 
                }
            }
            }
          }                
        }

        .available-vintage {
          border-top: 1px solid var(--neutral-colours-cccccc, ${vars.colors.grey20});
          margin-bottom: 60px;
          .title {
            font-size: 20px;
            line-height: 28px;            
            padding: 20px 0;
          }
          .d-flex {
            display: flex;
            .available-vintage-data {
              a {
                color: ${vars.colors.white};
                background-color: ${vars.colors.secondary4};
                width: 64px;
                padding: 3px;
                border-radius: 30px;
                margin-right: 10px;
                text-align: center;
                display: inline-block;
              }
            }
          }
        }
      
        .product-image-container{
          height: 100%;
          .image-container {
            background-color: ${vars.colors.primary3};
            padding: 25px;
            text-align: center;
            position: relative;

          .image-size {
            position: relative;
            height: 300px;
            ${vars.breakpoints.sm} {  
              height: 650px; 
            }
          }

          .awards {
            position: absolute;
            top: 5px;
            left: 10px;
          }
          
          .pill {
            position: absolute;
            left: 15px;
            ${vars.breakpoints.lg} {
              right: 15px;
              left: auto;
            }
            bottom: 15px;
            font-size: 16px;
            .bold{
              font-weight: 700;
            }
          }
          
          
          .image-bg-circle {
            width: 500px;
            height: 500px;
            background-color: ${vars.colors.primary3};
            border-radius: 100%;
            position: absolute;
            top: 45%;
            transform: translateY(-50%);
            z-index: -1;
            margin: 0 auto;
            left: 0;
            right: 0;
          }
          .d-flex {
            display: flex;
            align-items: center;
            justify-content: center;
            
            &.sustainable-awards {
              margin-top: 40px;
            }
          }
          .zoom-fav-container {
            .zoom {
              position: absolute;            
              right: 10px;
              bottom: 10px;
            }
            .favourite {
              position: absolute;            
              right: 10px;
              top: 10px;
            }
            .zoom, .favourite {
              padding: 12px;
              background: white;
              border-radius: 25px;
              width: 44px;
              height: 44px;
              div {
                cursor: pointer;
              }
            }

            ${vars.breakpoints.lg} {  
              gap: 15px;   
              position: absolute;            
              right: 15px;
              top: 15px;
              .zoom {          
                right: 50px;
                top: 0px;
                position: relative;
              }
              .favourite {          
                right: 0px;
                top: 0px;
              }   
            }
          }
        }
      }

      }

      .award-main-container {
        margin-bottom: 20px;
        .medal {
          margin-right: 7px;
          position: relative;
          display: inline-block;
          &:last-child {
            margin-right: 0px; 
          }
          .point-main-container {
            position: relative;
            .point-text-container {
              position: absolute;
              display: flex;
              flex-direction: column;
              align-items: center;
              top: 5px;
              left: 0;
              right: 0;
              .point {
                font-size: 28px;
                font-weight: 600;
                color: ${vars.colors.white};
                letter-spacing: -1px;
                line-height: 110%;
              }
              .point-text {
                font-size: 12px;
                font-weight: 700;
                line-height: 120%;
                letter-spacing: 0;
                color: ${vars.colors.white};
              }
            }
          }
          .tooltiptext {
            visibility: hidden;
            width: 383px;
            background-color: #2C2C2C;
            color: #fff;
            text-align: left;
            border-radius: 6px;
            padding: 15px;
            position: absolute;
            z-index: 1;
            top: 120%;
            left: 120%;
            margin-left: -65px;
            opacity: 0;
            transition: opacity 0.3s;
            &:after {
              content: "";
              position: absolute;
              bottom: 100%;
              left: 5%;
              margin-left: -5px;
              border-width: 10px;
              border-style: solid;
              border-color: transparent transparent #2C2C2C transparent ;
            }
            @media (max-width: 767px) {
              display: none;
            }
          }
          &:hover {
            .tooltiptext {
              visibility: visible;
              opacity: 1;
            }
          }
          &.award-text {
            .tooltiptext {
              padding: 0 15px;
              .award-item {
                display: block;
                margin: 20px 0;
              }
            }
          }
        }
      }
      
      ${vars.breakpoints.xs} {
        .gift-main-container {
          .gift-content-container {
            width: auto;
          }
        }
      }

      ${vars.breakpoints.sm} {
        .gift-main-container {
          .gift-details-container {
            .product-layout {
              padding: 100px 20px;
              margin: 0 auto;
              gap: 25px;
              .layout-name {
                max-width: 720px;
                .description-container {
                  margin: 20px 0px;
                }
              }
              .layout-image {
                .product-image-container {
                  .image-container {
                    padding: 0;
                    .image-size {
                      height: inherit;
                    }
                  }
                }
              }
            }
          }
          .gift-content-container {
            width: 60%;
          }
          .gift-banner-container {
            .banner {
              flex-direction: row;
              .banner-content-wrapper {
                .banner-content {
                  margin-bottom: 60px;
                  text-align: left !important;
                  p, h1, h2, h3, h4, h5, h6, span {
                    text-align: inherit !important;
                  }
                }
                .banner-button {
                  justify-content: flex-start;
                  a {
                    width: auto;
                  }
                }
              }
            }
          }
        }
        .product-layout {
          display: grid;
          grid-template-columns: 1fr 1fr; /* Left column auto-sized, right column takes remaining space */
          grid-template-rows: auto auto; /* Two rows with auto sizing */
          margin: 60px auto 20px auto;                   

          &.mixed {     
            .description-container {
              margin: 20px 0px;
            }   
            .product-image-container {
              .image-container {
                .image-bg-circle {
                  top: 47%;
                }
                .d-flex.sustainable-awards {
                  margin-top: 80px;
                }
              }
            }        
          }
        }      
        
        .layout-image {
          grid-column: 1; /* Image starts from first column */
          grid-row: 1 / span 2; /* Image spans two rows */
        }
        
        .layout-name {
          grid-column: 2; /* Name starts from second column */
          grid-row: 1; /* Name is in the first row */
        }
        
        .layout-details {
          grid-column: 2; /* Details starts from second column */
          grid-row: 2; /* Details is in the second row */
        }        

        .print-share-container {
          background: none;
          max-width: ${vars.breakpointWidths?.xl}px;
          margin: 0px auto 20px auto;
          display: flex;
          align-items: center;
          justify-content: right;
          .label {
            display: inline;
          }
          a {
            padding: 0 15px;
            margin: 0;
            &:last-child {
              padding-right: 0;
            }
          }
        }
        // End of desktop CSS
      }

      ${vars.breakpoints.md} {
        .gift-main-container {
          .gift-banner-container {
            .banner {
              .banner-img {
                width: 560px;
              }
            }
          }
        }
      }

      ${vars.breakpoints.lg} {
        .gift-main-container {
          .gift-content-container {
            width: 35%;
          }
          .gift-banner-container {
            .banner {
              padding: 33px 0;
              .banner-img {
                width: auto;
              }
            }
          }
        }
      }

      .main-title {
        font-family: ${vars.fonts.familyPrimary};
        font-size: 34px;
        ${vars.breakpoints.lg} {
          font-size: 48px;
        }
        font-style: normal;
        font-weight: 500;
        line-height: 105%; /* 50.4px */
        letter-spacing: -1.44px;
        text-transform: uppercase;
        margin: 0px;
        i {
          color: #E2004D;
          top: -3px;
          position: relative;
          ${vars.breakpoints.lg} {
            font-size: 32px;
            top: -8px;
          }
        }
      }

      .sub-title {
        font-family: ${vars.fonts.familyTertiary};
        font-size: 22px;
        ${vars.breakpoints.lg} {
          font-size: 22px;
        }
        font-style: normal;
        font-weight: 600;
        margin: 18px 0;
        padding-top: 10px;
        line-height: 120%; /* 26.4px */
        letter-spacing: -0.22px;
        &.center {
          text-align: center;
        }
      }

      .sub-title-with-border {
        font-family: ${vars.fonts.familyTertiary};
        font-size: 22px;
        font-style: normal;
        text-transform: capitalize;
        font-weight: ${vars.fonts.weightBold};
        line-height: 120%; /* 36px */
        letter-spacing: -0.3px;
        padding-bottom: 10px;
        border-bottom: 1px solid #ccc;
        margin-bottom: 15px;
      }

      .tooltip-container {
        margin: 10px 0 10px;
        .tooltip {
          font-Size: ${vars.fonts.sizeLarge};
          line-height: ${vars.fonts.lineHeightDefault};
          margin-left: 7px;
          text-decoration: underline;
          position: relative;
          display: inline-block;
          .tooltiptext {
            visibility: hidden;
            width: 200px;
            background-color: #2C2C2C;
            color: ${vars.colors.white};
            text-align: left;
            border-radius: 6px;
            padding: 15px;
            position: absolute;
            z-index: 1;
            top: -20px;
            left: 60px;
            opacity: 0;
            transition: opacity 0.3s;
            &:after {
              content: "";
              position: absolute;
              bottom: 100%;
              left: -24px;
              top: 25px;
              margin-left: -5px;
              border-width: 15px;
              border-style: solid;
              border-color: transparent #2C2C2C transparent transparent ;
            }
          }
          &:hover {
            .tooltiptext {
              visibility: visible;
              opacity: 1;
            }
          }
          &.award-text {
            .tooltiptext {
              padding: 0 15px;
              .award-item {
                display: block;
                margin: 20px 0;
              }
            }
          }
        }
      }     

      .product-container {
        max-width: ${vars.breakpointWidths?.xl}px;        
        margin: 60px auto 20px auto;
        .product-main-container {
            display: grid;
            gap: 25px;
            grid-template-columns: 1fr;
            ${vars.breakpoints.lg} {
              grid-template-columns: 1fr 1fr;
            }
          
          .w-100 {
            width: 100%;
          }
          .product-image-container {
            max-width: 827px;
            position: relative;
            .image-container {
              background-color: ${vars.colors.primary3};
              padding: 15px;
              text-align: center;
              position: relative;
              .image-bg-circle {
                width: 500px;
                height: 500px;
                background-color: ${vars.colors.primary3};
                border-radius: 100%;
                position: absolute;
                top: 45%;
                transform: translateY(-50%);
                z-index: -1;
                margin: 0 auto;
                left: 0;
                right: 0;
              }
              .d-flex {
                display: flex;
                align-items: center;
                justify-content: center;
                &.awards {
                  margin-left: 15px;
                }
                &.sustainable-awards {
                  margin-top: 40px;
                }
              }
            }
          }
        }
      }    
      
      .menu-banner-image, .cms-image-container, .richtext-wrapper {
        @media print {
          display: none
        }
      }

      .about-the-wine-container {
        .wine-details-container {
          max-width: ${vars.breakpointWidths?.xl}px;
          margin: 0px auto;

          .first-row {
            .column-align {
              display: grid;
              gap: 25px;
              grid-template-columns: 1fr;
              ${vars.breakpoints.sm} {
                grid-template-columns: 1fr 1fr;
              }
              margin-bottom: 15px;

              @media print {
                gap: 25px;
                margin-bottom: 0px;
                  .sub-title-with-border {
                    margin-bottom: 10px;
                    text-transform: capitalize;
                    padding-bottom: 5px;
                    font-size: 16px;
                  }
                  li {
                    font-size: 12px;
                  }
                  .item {
                    margin-bottom: 0px;
                    .item-title {
                      font-size: 14px;
                    }
                    .item-sub-title {
                      font-size: 12px;
                    }
                  }
              }
            }
            
            @media print {
              display: grid;
              gap: 10px;
              grid-template-columns: 1fr 2fr;
            }
          }

          .print-bottle-image-container {
            position: relative;
            height: 750px;
            text-align: center;
            @media print {
              height: 350px !important;
            }
          }

          .about-wine {
            background-color: ${vars.colors.primary3};
            text-align: center;
            padding: 15px;
            margin: 25px 0px;
            .show-in-print-view-only {
              display : none;
            }
            @media print {
              padding: 0;
              margin: 20px 0px;
              font-size: 12px !important;
              text-align: left;
              margin-top: 10px;
              .title {
                text-align: center;
                font-size: 20px;
                margin-bottom: 10px;
              }
              .show-in-print-view-only {
                display : block;
                font-size: 12px !important;
              }
              .longDescription {
                display : none;
              }
            }
          }

          .second-row {
            display: grid;
            gap: 25px;
            grid-template-columns: 1fr;
            ${vars.breakpoints.sm} {
              grid-template-columns: 1fr 1fr 1fr;
            }
            @media print {
              gap: 10px;
              grid-template-columns: 1fr 1fr 1fr;
              .about-producer {
                min-height: 200px;
              }
              .map-image {
                min-height: 200px !important;
                img {
                  height: 90% !important;
                  width: 90% !important;
                  object-fit: contain !important;
                  margin: 0 auto;
                }
              }
              .quote {
                padding: 0 !important;
                font-size: 22px !important;
              }
            }
            
            .quote {
              display: grid;
              place-items: center;
              font-family: ${vars.fonts.familyTertiary};
              font-size: 28px;
              font-style: italic;
              font-weight: 400;
              line-height: 120%; /* 33.6px */
              letter-spacing: -1.12px;
              text-align: center;
              color: ${vars.colors.white};
              background-color: ${vars.colors.secondary4};
              padding: 40px;
              .person-name {
                font-style: normal;
              }
            }
            
            .about-the-producer {
              background-color: ${vars.colors.primary3};
              .content-container {
                padding: 15px;
              }
            }
            .map-image {
              position: relative;
              min-height: 300px;
              
              img {
                border-radius: 10px;
              }
            }
          }
          .flavour, .wine-detail, .quote, .about-wine, .about-the-producer {
            border-radius: 10px;
            font-size: ${vars.fonts.sizeLarge};
            line-height: ${vars.fonts.lineHeightDefault};
          }
          .about-wine{
            padding-left:80px;
            padding-right:80px;
          }
          .title {
            font-family: ${vars.fonts.familyTertiary};
            font-size: 24px;
            font-style: normal;
            font-weight: 600;
            line-height: 120%; /* 36px */
            letter-spacing: -0.3px;
            margin-bottom: 15px;
          }           
        }
        @media (max-width: 767px) {
          .print-view-only {
            display : none;
          }
          @media print {
            .first-row {
              display: grid;
              grid-template-columns: 1fr 2fr;
            }
            .print-bottle-image-container {
              position: relative;
              height: 350px !important;
            }
            .fixed-bottom {
              display: none;
            }
            .accordion-mobile-wine-details {
              .mantine-Accordion-item {
                border-bottom: none;
                .mantine-Accordion-control {
                  padding: 0;
                  .mantine-Accordion-chevron {
                    display: none;
                  }
                  .mantine-Accordion-label {
                    border-bottom: 1px solid #ccc;
                    padding-bottom: 10px;
                    font-size: 16px;
                  }
                }
                .mantine-Accordion-panel {
                  .mantine-Accordion-content {
                    padding: 10px 0;
                    li {
                      font-size: 12px;
                    }
                    .item {
                      margin-bottom: 15px;
                      &:last-child{
                        margin-bottom: 0px;
                      }
                      .item-title {
                        font-size: 14px;
                      }
                      .item-sub-title {
                        font-size: 12px;
                      }
                    }
                  }
                }
                &:first-child {
                  .mantine-Accordion-control {
                    .mantine-Accordion-label {
                      padding-top: 0;
                    }
                  }
                }
              }
            }
            .print-view-only {
              display : block;
              .title {
                text-align: center;
                font-size: 20px;
                font-weight: 600;
                margin-bottom: 10px;
                margin-top: 20px;
                font-family: ${vars.fonts.familyTertiary};
              }
              .show-in-print-view-only {
                font-size: 12px;
                line-height: 24px;
              }
              .producer {
                position: relative;
                min-height: 200px;
                margin-top: 20px;
              }
            }
            .longDescription {
              display : none;
            }
          }
        }
      }      

      .details-container {
        margin: 50px 0 80px;
        background-color: ${vars.colors.primary3};
        padding: 25px 25px 50px;
        h1 {
          margin: 0;
        }
        p {
          font-size: 16px;
          line-height: 24px;
        }
      }

      .case-content-main-container {
        .slick-slider {
          background: ${vars.colors.primary3};
          border-bottom: 1px solid ${vars.colors.grey20};
          ${vars.breakpoints.sm} {
            background: none;
          }
        }
        .sub-title {
          background: ${vars.colors.primary3};
          margin: 0;
          text-transform: capitalize;
          ${vars.breakpoints.sm} {
            background: none;
            margin: 18px 0px;
          }
        }
        @media (min-width: 400px) and (max-width: 900px) {
          background: #F8F5EF;
        }
        .header-container {
          display: flex;
          gap: 15px;
          justify-content: space-between;
          align-items: center;
          .main-title {
            a {
              color: ${vars.colors.primary1};
            }
            margin-bottom: 15px;
            font-size: 30px;
          }
          .price {
            text-transform: uppercase;
            font-weight: ${vars.fonts.weightBold};
          }
        }

        .rating-container {
          display: flex;
          gap: 15px;
          align-items: center;
          margin-bottom: 15px;
        }
        
        .case-content {
          &.title {
            text-align: center;
            margin-top: 40px;
            margin-bottom: 80px;
          }
        }
        .case-content-container {
          .mantine-Tabs-tabsList {
            border: none;
            margin-bottom: 20px;
            .mantine-Tabs-tab {
              text-align: center;
              background: none;
              border: none;
              display: block;
              width: 132px;
              &[data-active]:after {
                content: "";
                position: absolute;
                bottom: -20px;
                left: -5px;
                right: 0;
                margin: 0 auto;
                border-width: 0 22px ${vars.fonts.lineHeightDefault};
                border-style: solid;
                border-color: ${vars.colors.primary3} transparent;
                display: block;
                width: 0;
              }
            &Icon {
              margin: 0; 
              svg {
                width: 53px;
                height: 190px;
              }
            }
            &Label {
              font-size: 18px;
              line-height: ${vars.fonts.lineHeightDefault};
              font-weight: ${vars.fonts.weightBold};
              color: ${vars.colors.black};
              display: inline-block;
              margin-top: 7.5px;
            } 
            }
          }
          .mantine-Tabs-panel {
            background: ${vars.colors.primary3};
            padding: 25px;
            margin-bottom: 40px;
            .web-description{
              text-align:left;
            }
            .case-content-details-section {              
              margin-top: 20px;             
              text-align: left;
              @media (min-width: 768px) {
                display: grid;
                grid-template-columns: 2fr 1fr 2fr;
              }
              .case-image {
                text-align: center;
              }
              .case-details {
                width: 100%;
                .title-print-container {
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  border-bottom: 1px solid ${vars.colors.grey20};
                  .title {
                    margin-top: 0;
                  }
                }                
                .price-section {
                  padding-bottom: 20px;
                  border-bottom: 1px solid ${vars.colors.grey20};
                  .price {
                    font-size: ${vars.fonts.sizeLarge};
                    line-height: ${vars.fonts.lineHeightDefault};
                    font-weight: ${vars.fonts.weightBold};
                  }
                  .shortDesc {
                    margin-top: 20px;
                    a {
                      text-decoration: underline;
                    }
                  }
                }
              }
            }
          }
        }
      }

      .pill {
        display: inline-block;
        border-radius: 10px;
        padding: 6px 10px;
        &.stock {
          background-color: ${vars.colors.primary1};
          color: ${vars.colors.white};
        }
        &.savings {
          background-color: #FFDCD9;
          .discount-price {
            text-decoration: line-through;
            margin-right: 10px;
          }
          .saving-price {
            color: ${vars.colors.primary1};
            font-weight: ${vars.fonts.weightBold}
          }
        }
      }  
    }
    `,
      zoom: `
      .mantine-Modal-body {
        text-align: center;
        @media (max-width: 1199px) {
          .bottle {
            width: 180px;
            height: 660px;
            @media (min-height: 768px) and (max-height: 1024px) {
              top: 50%;
              transform: translateY(-50%);
              position: absolute;
              left: 0;
              right: 0;
              margin: 0 auto;
            }
          }
          .case {
            color: transparent;
            width: 315px;
            height: 280px;
            top: 50%;
            transform: translateY(-50%);
            position: absolute;
            left: 0;
            right: 0;
            margin: 0 auto;
          }
        }
              }
      .mantine-Modal-inner {
        z-index: 999;
        .mantine-Modal-content {
          .mantine-Modal-header {
            justify-content: flex-end;
            .mantine-CloseButton-root {
              background: transparent;
              color: ${vars.colors.black};
              padding: 0;
              border: none;
              width: auto;
              height: auto;
              line-height: 0;
              svg {
                width: 32px;
                height: 32px;
              }
            }
          }
        }
      }
      `,
      reviews: {
        wrapper: `
          background-color: ${vars.colors.primary3};
          margin: 25px auto;
          padding: 15px 25px;
          max-width: ${vars.breakpointWidths?.xl}px;

          a.login-to-review {
            ${vars.mixins.btnPrimary}
          }

          .title {
            text-align: center;
          }

          .review-heading-container {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-gap: 25px; /* Adjust as needed */
            text-align: center;

            .item {
              &.review-button {
                grid-column: span 2;
              }
            }
            ${vars.breakpoints.md} {
              display: flex;
              align-items: center;
              justify-content: center;

              .item {
                padding: 0px 15px;
                ${vars.breakpoints.md} {
                  padding: 0px 30px;
                }
                &:not(:last-child) {
                  border-right: 1px solid #ccc; /* Remove border from all items except the last one */
                }
              }
              
            }
            
            .review-heading {
              text-align: center;
          
              .review-title {
                font-weight: ${vars.fonts.weightBold};
              }
              .review-total {
                font-family: ${vars.fonts.familyPrimary};
                font-size: 24px;
                ${vars.breakpoints.md} {
                  font-size: 36px;
                }
                &.flex-center {
                  display: flex;
                  gap: 10px;
                  justify-content: center;
                }
              }
            }
          }
        
        .rating-sort-container {
          select {
            background-color: rgb(255, 255, 255);
            border: 1px solid ${vars.colors.grey40};
            padding: 8px 12px;
            text-overflow: ellipsis;
            white-space: nowrap;
            border-radius: 5px;
            min-width: 250px;
          }
        }

        .rating-filter-container {
          display: flex;          
          align-items: center; 
          flex-direction: row-reverse;

          border: 1px solid ${vars.colors.grey20}; 

          ${vars.breakpoints.md} {
            gap: 5px;
            border: none;
          }

          .stars {
            text-align: center;
            background: white;
            padding: 10px;

            &.disabled {
              background: none;
              color: ${vars.colors.grey30};
              a {
                svg {
                  opacity: 0.3;
                }
              }
              
            }

            a {
              display: flex;
              align-items: center;
              gap: 5px;
            }

            &:not(:first-child) {
              border-right: 1px solid ${vars.colors.grey20};
            }
            
            ${vars.breakpoints.md} {
              padding: 8px;
              border: 1px solid ${vars.colors.grey20}; 
            }         
          }
        }
        
        .rating-sort-container {
          display: flex;          
          align-items: center; 
        }
  
      .review-toolbar {
        display: grid;
        gap: 25px;
        grid-template-columns: 1fr;
        margin: 25px 0px;
        justify-items: center;
        
        .rating-pagination-container {
          display: flex;
          align-items: baseline;
          gap: 25px;
        }

        ${vars.breakpoints.md} {
          display: flex;
          gap: 25px;
          justify-content: space-evenly;
          padding: 15px;          
          align-items: center;
        }
      }
  
      .review {
        display: grid;
        gap: 25px;
        grid-template-columns: 1fr;

        border-bottom: 1px solid ${vars.colors.grey20};
        padding: 25px 0px;

        ${vars.breakpoints.lg} {
          grid-template-columns: 120px auto;          
        }

        .user-info {
          order: 2;
          display: flex;

          ${vars.breakpoints.md} {
            order: inherit;
          }

          .user-profile-container {
            display: flex;
            align-items: baseline;

            .nickname-badge {
              color: ${vars.colors.white};
              background-color: ${vars.colors.secondary4};
              padding: 8px;
              border-radius: 15px;
              margin-right: 10px;
              text-align: center;
              display: inline-block;
              text-transform: uppercase;
              height: 32px;
              text-transform: uppercase;
            }
          }          
        }

        .review-info {
          padding-right: 25px;
          .submission-time {
            padding-left: 10px;
            color: ${vars.colors.grey40};
          }
          .title {
            font-weight: ${vars.fonts.weightBold};
            margin: 20px 0px 5px 0px;
            text-align: left;
          }
          .review-text {
            margin-bottom: 20px;
          }
          .review-helpfulness {
            display: flex;
            gap: 30px;
            align-items: center;
          }
          .client-response-container {
            .agent {
              font-weight: ${vars.fonts.weightBold};
              margin-bottom: 15px;
            }
            background: white;
            padding: 15px;
            border-radius: 10px;
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.06);
            margin-top: 20px;
          }
        }
      }
    `,
      },
      related: `
      margin: 40px 0;
      text-align: center;
        .auc-Recommend-title {
          font-family: ${vars.fonts.familyTertiary};
          font-weight: ${vars.fonts.weightBold};
          font-size: 24px;
          line-height: 40px;
          margin-bottom: 20px;
          text-transform: none;
        }
        .container {
          margin-bottom:10px;
          .card-container {
            margin-right: 25px;
          }
          .product-image-container .product-image {
            height: 150px;
          }
          .slick-slider {
            .slick-arrow {
              i {
                font-size: 44px;
              }
              &.slick-disabled {
                display: block !important;
                color: ${vars.colors.grey20};            
              }
            }
          }
        }
      `,
      recentlyViewed: `
      .container {
        margin: 20px 0;
        text-align: center;
        .sub-title{
        text-transform: none;
        font-size:24px;
        }
        .recently-viewed-carousel {
          .mantine-Carousel-container {
            .mantine-Carousel-slide {
              .product-card {
                .product-image-and-info {
                  .product-card-info {
                    .product-name-box {
                      justify-content: center;
                    }
                    .product-rating {
                      justify-content: center;
                    }
                    .view-product-button{
                      border:none
                    }
                    .view-product-button:hover {
                      text-decoration: underline;
                      background: none;
                      color:inherit
                    }
                  }
                }
              }
            }
          }
          .mantine-Carousel-indicators {
            bottom: -10px;
          }
        }
      }
      `,
      productItem: `
      margin: 0 10px;
      
      .product {
        &-image {
          position: relative;
          height: 180px;
        }
        &-title {
          font-size: 24px;
          font-weight: ${vars.fonts.weightBold};
          display: block;
          word-wrap: break-word;
          max-width: 200px;
          margin: 20px auto;
        }
        &-rating {
          margin-bottom: 15px;
          height: 16.5px
        }
        &-price {
          font-size: 16px;
          line-height: 32px;
          font-weight: 600;
          color: ${vars.colors.primary1};
          .price {
            font-size: 18px;
            line-height: 26px;
            font-weight: ${vars.fonts.weightBold};
          }
        }
        &-detail {
          margin-top: 20px;
          display: block;
          text-decoration: none;
          padding-bottom: 5px
        }
        &-detail:hover {
          text-decoration: underline;
        }
      }
      `,
      productCardFullBleed: `
        .product-card {
          height: 100%;
          width: 100%;
          .product-image-and-info{
            position: relative;
            .product-image-wrapper {
              .product-background-image {
                z-index: 1;
                height: 540px;
                img {
                  width: 100%;
                  object-fit: cover;
                  width: 100%;
                  height: 540px;
                }
              }
              .product-image {
                position: absolute;
                display: flex;
                justify-content:center;
                top: 0;
                left: 0;
                z-index: 2;
                width: 100%;
                height: 540px;
                object-fit:cover;
                align-items: flex-end;
                .product-bottle-image{
                  max-width: 105px;
                  margin-bottom: 50px;
                  width: 100%;
                  height: max-content;
                }
                .product-case-default-image{
                  position:absolute;
                  width: 100%;
                  top: 110px;
                  height: 60% !important;
                  max-width: max-content;
                }
              }
            }
          }
        }

        .product-card-info {
          z-index: 3;
          position: absolute;
          top: 2%;
          left: 8%;
          display: flex;
          height: 94%;
          justify-content: space-between;
          flex-direction: column;
          .product-sale-status {
            display: flex;
            align-items: center;
            gap: ${vars.space.sm};
            background-color: ${vars.colors.primary1};
            padding: ${vars.space.sm};
            color: ${vars.colors.white};
            width: fit-content;
            .product-on-sale-text{
              margin: 0px;
            }
          }
          .product-info-and-button{
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            height: 90%;
          }
          .product-name-box{
            a:hover{
              text-decoration: none;
            }
            overflow: hidden;
          }
          .product-name  {
            font-size: ${vars.fonts.sizeH8};
            color: ${vars.colors.white};
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            line-clamp: 2;
            webkit-box-orient: vertical;
            line-height: 32.4px;
            width: fit-content;
            font-weight: 600;
            font-family: ${vars.fonts.familyTertiary};
            margin: 12px 0px;
          }

          .product-rating {
            display: flex;
            align-items: center;
            height:17.33px;
            gap: ${vars.space.sm};
              span {
                color: ${vars.colors.white};
              }
          }

          .product-price {
            margin: 12px 0px;
            color:${vars.colors.white};
            font-sixe: ${vars.fonts.sizeH4};
            font-sixe: ${vars.fonts.sizeLarge};
          }

          .product-card-button{
            .cart-button button{
              padding: ${vars.space.md};
              background: ${vars.colors.white};
              border: none;
              font-family: ${vars.fonts.familySecondary};
              font-weight: 600;
              background: ${vars.colors.btnsPrimaryColor};
              color: ${vars.colors.black};
              display: flex;
              gap: 10px;
            }
            .cart-button button:hover {
                background-color: ${vars.colors.btnsPrimaryHoverBg}; 
                color: ${vars.colors.btnsPrimaryHoverColor}; 
                text-decoration: none;
            }
                
            .view-product-button{
              padding: ${vars.space.md};
              border: 1px solid ${vars.colors.black};
              padding: 12px 24px 12px 24px;
              font-family: ${vars.fonts.familyTertiary};
              font-weight: 600;
              color: ${vars.colors.black};
              display: inline-block;
              font-size: ${vars.fonts.sizeH5};
              background: ${vars.colors.btnsPrimaryColor};
              border-radius: 5px;
              line-height: 26px;
            }
            .view-product-button:hover {
              background-color: ${vars.colors.btnsPrimaryHoverBg}; 
              color: ${vars.colors.btnsPrimaryHoverColor};
              text-decoration: none; 
            }
          }
        }
      }
      `,

      productCardStandard: `
        .product-card {
          height: 100%;
          width: 100%;
          .product-image-and-info {
            position: relative;
            .product-image-wrapper {
              display: flex;
              position: relative;
              justify-content: center;
              height: 320px;
              align-items: center;
              .product-background-image {
                position: absolute;
                display: none;
                top: 0;
                left: 0;
              
                img {
                  object-fit: cover;
                  height: 540px;
                }
            
              }
            
              .product-image {
                position: absolute;
                display: flex;
                justify-content:center;
                top: 0;
                left: 0;
                z-index: 2;
                width: 100%;
                height:100%;
                object-fit:cover;
                align-items: center;
                .product-bottle-image{
                  width: 100%;
                  height: 90%;
                  object-fit: scale-down;
                }
                .product-case-default-image{
                  min-height: -webkit-fill-available;
                  width: 100%;
                  height: auto;
                  max-width: max-content;
                }
              }
            }
          }
      
        }
        .product-card-info {
          z-index: 3;
          display: flex;
          justify-content: space-between;
          flex-direction: column;
          .product-sale-status {
            background-color: ${vars.colors.primary1};
            display: flex;
            align-items: center;
            gap: ${vars.space.sm};
            padding: ${vars.space.sm};
            color: ${vars.colors.white};
            position: absolute;
            top:0;
            left:0;
            margin: 0px;
            .product-on-sale-text{
              margin: 0px;
            }
          }
        
          .product-info-and-button{
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
          }
          .product-name-box{
            display: flex;
            align-items: center;
            height: 62px;
            overflow: hidden;
            a:hover{
              text-decoration: none;
            }
          }
          .product-name{
            font-size: ${vars.fonts.sizeH8};
            color: ${vars.colors.black};
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            line-height: 28.4px;
            font-weight: 600;
            font-family: ${vars.fonts.familyTertiary};
            margin-bottom: 12px;
            word-break: break-word;
          }

          .product-rating {
            display: flex;
            align-items: center;
            height:17.33px;
            gap: ${vars.space.sm};
            span {
              color: ${vars.colors.black};
            }
          }

          .product-price {
            margin: 12px 0px;
            color: #616161;
            font-sixe: ${vars.fonts.sizeH4};
            font-family: ${vars.fonts.familySecondary};
            font-weight: ${vars.fonts.weightNormal};
          }

          .product-card-button{
            .cart-button button{
              padding: ${vars.space.md};
              border: 1px solid ${vars.colors.black};
              padding: 12px 24px 12px 24px;
              font-family: ${vars.fonts.familySecondary};
              font-weight: 600;
              background: ${vars.colors.btnsPrimaryColor};
              color: ${vars.colors.black};
              display: flex;
              gap: 10px;
            }
            .cart-button button:hover {
              background-color: ${vars.colors.btnsPrimaryHoverBg}; 
              color: ${vars.colors.btnsPrimaryHoverColor};
              text-decoration: none;
            }
            .view-product-button{
              padding: ${vars.space.md};
              border: 1px solid ${vars.colors.black};
              padding: 12px 24px 12px 24px;
              font-family: ${vars.fonts.familySecondary};
              background: ${vars.colors.btnsPrimaryColor};
              color: ${vars.colors.black};
              display: inline-block;
              font-size: 14px;
              border-radius: 5px;
              line-height: 26px;
            }
            .view-product-button:hover {
              background-color: ${vars.colors.btnsPrimaryHoverBg}; 
              color: ${vars.colors.btnsPrimaryHoverColor};
              text-decoration: none
            }
          }
        }
      }
      `,

      productCardDetailed: `
        height: 100%;

        .product-card {
          border-radius: 8px;
          border: 1px solid ${vars.colors.secondary9};
          background: #ffffff;
          height: 100%;
          overflow: hidden;
        }

        .product-image-and-info {
          display: flex;
          flex-flow: column;
          height: 100%;
        }

        // Image Wrap
        .product-image-wrapper {
          position: relative;
          padding-top: 67%;
          height: 0;
          width: 100%;
          display: block;

          img {
            position: absolute;
            left: 0;
            top: 0;
            width: 100% !important;
            height: 100% !important;
            object-fit: contain;
            padding: 20px 10px;
          }
        }

        // Product Info
        .product-card__info {
          display: flex;
          flex-flow: column;
          height: 100%;
        }

        // Product Info Top
        .product-card__info-top {
          flex: 1;
          padding: 16px;
          border-top: 1px solid ${vars.colors.secondary9};

          .product-card__badge + .product-name-box {
            margin: 8px 0 4px;
            text-transform: none;
          }

          .product-name {
            margin: 0;
          }
        }
        
        // Product Info Bottom
        .product-card__info-bottom {
          display: grid;
          gap: 4px;
          padding: 10px 16px 24px;
          border-top: 1px solid ${vars.colors.secondary9};
        }

        // Badge
        .product-card__badge {
          display: inline-block;
          padding: 5px 11px;
          border-radius: 32px;
          border: 1px solid #000;
          font-size: ${vars.fonts.sizeSmall};
        }

        p, .body1 {
          margin: 0 !important;
        }

        .product-card__member-price {
          margin-top: 4px;
          color: #0055A0;
        }

        .product-card-button {
          margin-top: 8px;

          button {
            cursor: pointer;
            padding-left: 64px;
            padding-right: 64px;
            font-weight: 600;
            letter-spacing: 0.42px;
            text-transform: uppercase;
          }
        }

        .view-product-button {
          padding: ${vars.space.md};
          border: 1px solid ${vars.colors.black};
          font-weight: 600;
          letter-spacing: 0.42px;
          text-transform: uppercase;
          color: ${vars.colors.black};
          display: inline-block;
          font-size: ${vars.fonts.sizeH5};
          background: ${vars.colors.btnsPrimaryColor};
          border-radius: 5px;
          line-height: 26px;
        }
        .view-product-button:hover {
          background-color: ${vars.colors.btnsPrimaryHoverBg}; 
          color: ${vars.colors.btnsPrimaryHoverColor};
          text-decoration: none; 
        }

        .product-price {
          display: flex;
          gap: 4px;
        }

        .product-price__original {
          color: #616161;
        }

        .product-rating {
          svg {
            width: 14px;
            height: 14px;
          }
        }
      }
      `,
      productCardSkeleton: `
        .product-rating {
          padding-top: 20px;
        }
        .product-price {
          padding-top: 10px;
        }
      `,
    },

    Search: {
      noResults: `
      .no-results {
        .container {
          text-align: center;
          margin: 30px 0px;
          .title {
            font-size: ${vars.fonts.sizeH2};
            font-weight: ${vars.fonts.weightBold};
            margin-bottom: 5px;          
          }
        }
        
        .query {
          font-size: ${vars.fonts.sizeH2};
        }
      }
      `,
      wrapper: `
      max-width: ${vars.breakpointWidths?.xxl}px;
      margin-left: auto;
      margin-right: auto;
      // padding: 0px 15px;
      ${vars.breakpoints.xxl} {
        padding: 0px;
      }
      h1 {
        margin-top: 0px;
      }
      .facets-navigation {
        padding:0 16px 16px 0;
        @media (max-width: 767px) {
          display: none;
        }
      }
      .search-results-listing.loading {
        display: none;
      }
      .search-toolbar-container + .loaderContainer {
        ${vars.breakpoints.sm} {
          margin-top: 250px;
        }
        @media (max-width: 767px) {
          margin: 100px 0;
        }
      }
      .filter-header{
        display:flex;
        align-items: baseline;
        justify-content: space-between;

      .clear-refinements {
        background: none;
        color: ${vars.colors.black};
        font-size: ${vars.fonts.sizeLarge};
        line-height: ${vars.fonts.lineHeightDefault};
        text-decoration-line: underline;
        padding: 0;
        margin: 0 2px 0 0;
        cursor: pointer;
        border: none;
        box-shadow: none;
        &:hover {
          background: none;
          color: ${vars.colors.black};
          text-decoration-line: underline;
        }
      }
    }

      
      margin-top: 15px;

      .search-toolbar-container {
        ${vars.breakpoints.sm} {
          display: flex;
          gap: 15px;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 16px;
        }
        .toolbar-start{
          flex:1 0 0;
          ${vars.breakpoints.md} {
            padding-bottom: 16px;
          }
          ${vars.breakpoints.lg} {
            adding-bottom: initial;
          }
      }
        .toolbar-end {
          ${vars.breakpoints.sm} {
            display: flex;
            flex:0.9 0 0;
            justify-content:end;
            align-items: end;
            gap: 8px;
            ${vars.breakpoints.lg} {
              gap: 12px;
            }
          }
        }

      .quick-toggle-container {
        display: none;
        @media (min-width: 769px) {
          display: flex; // hiding the Grid view. The list view is default, So hiding the toggle icons;
          align-items: center;
        
          a {
            border: 1px solid #707070;
            width: 40px;
            height: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            &.list-icon {
              border-radius: 5px 0px 0px 5px;
              svg {
                path {
                  fill: #707070;
                } 
              }
              &.active {
                svg {
                  path {
                    fill: ${vars.colors.black};
                  }
                }
                border: 1px solid ${vars.colors.black};
                background-color: #F6F6F6;
              }
            }
            &.grid-icon {
              border-radius: 0px 5px 5px 0px;
              svg {
                path {
                  fill: #707070;
                } 
              }
              &.active {
                border: 1px solid ${vars.colors.black};
                background-color: #F6F6F6;
                svg {
                  path {
                    fill: ${vars.colors.black};
                  }
                }
              }
            }
          }
        }
      }
    }

    .category-intro-copy {
      text-align: center;
      border: 1px solid ${vars.colors.grey20};
      border-radius: 10px;
      margin-bottom: 15px;
      padding: 24px 16px 32px;
      margin-top: 25px;
      h1 {
        &.Red, &.Rose, &.dessert {
          color: ${vars.colors.primary1};
        }
        &.White, &.Beer {
          color: #D89B00;
        }
        &.Fortified, &.Sparkling, &.Cider {
          color: ${vars.colors.primary2};
        }
        &.Mixed {
          color: ${vars.colors.secondary1};
        }
        margin-bottom: 20px;
      }
      @media (max-width: 767px) {
        p, span {
          font-size: 16px !important;
        }
      }
    }

    .category-faq {
      margin: 30px 0px;
      .heading {
        font-family: ${vars.fonts.familyTertiary};
        font-weight: ${vars.fonts.weightNormal};
        font-size: ${vars.fonts.sizeH2};
        margin-bottom: 15px;
        text-transform: capitalize;
      }
    }

    .category-banner {
      margin-bottom: 15px;
      margin-top: 25px;
    }
    display: grid;
    grid-gap: 24px;
      ${vars.breakpoints.xs} {
        grid-template-columns: auto;
      }
      ${vars.breakpoints.sm} {
        grid-template-columns: 270px 1fr;
      }
  
      ${vars.breakpoints.md} {
        grid-template-columns: 228px 1fr;
      }
      ${vars.breakpoints.lg} {
        grid-template-columns: 272px 1fr;
      }
      ${vars.breakpoints.xl} {
        grid-template-columns: 292px 1fr;
      }
      ${vars.breakpoints.xxl} {
        grid-template-columns: 402px 1fr;
      }
    .facets-navigation.hide-refinementlist{
      display : none;
    }
    
    .sort-filter-block{
      display:flex;
      gap: 10px;
      @media (min-width: 768px) {
        .mobile-filter-box {
          display: none;
        }
      }
    }
    
    ${EmotionBreakpoint('mobile')} {
        .search-result-mobile-header{
          .web-description{
            padding:16px 0;
          }
          .search-header{
            color:${vars.colors.primary1};
            font-family: ${vars.fonts.familyPrimary};
            font-size: 25px;
            font-style: normal;
            font-weight: 500;
            line-height: 28px; /* 112% */
            text-transform: uppercase;
          }
        }
        .sort-filter-block{
          gap: 0px;
          padding-bottom:16px;
          .filter-box {
            display: flex;
            height: 40px;
            justify-content: center;
            align-items: center;
            gap: 10px;
            flex: 1 0 0;
            border-radius: 5px 0px 0px 5px;
            border: 1px solid var(--greys-blacks-global-c-4-c-7-c-5, #CCC);
          }
          .sort-box {
            border-radius: 0px 5px 5px 0px;
            border-width: 1px 1px 1px 0;
            border-color: #CCC;
            border-style: solid;
            display: flex;
            justify-content: center;
            align-items: center;
            flex: 1 0 0;
            & .label {
              display:none;
            }
            & .options {
              border:none;
              max-height:38px;
            }
          }
        }
      }
      .refinement-drawer{
        position: fixed;
        background-color: ${vars.colors.lwDrGray};
        z-index:500;
        .drawer-container{
          position: fixed;
          width: 100%;
          top: 0px;
          left: 0px;
          bottom: 0px;
          max-height: 100%;
          background-color: #707070;
          display: flex;
          justify-content: flex-start;
          align-items: flex-start;
          .drawer-section{
            outline: 0px;
            -webkit-tap-highlight-color: transparent;
            display: block;
            text-decoration: none;
            background-color: ${vars.colors.white};
            box-sizing: border-box;
            flex: 0 0 27.5rem;
            max-width: 100%;
            max-height: 100%;
            height: 100%;
            border-radius: 0px;
            overflow-y: auto;

            .drawer-body{
              position:relative;
              min-height: 100vh;
              padding: 1rem;
              & >:nth-child(2) .heading{
                border-top:none;
              }
              .filter-header{
                position:sticky;
                top:0;
                background:${vars.colors.white};
                z-index:1;
                display: flex;
                flex-direction: column;
      
              & :first-child{
                align-self: end;
                }
            .header-row{
              display:flex;
              justify-content:space-between;
              align-items:center;
              width:100%;
            }
          }
          .bottom-button{ 
            width:100%;
            position: sticky;
            margin: auto;
            background:${vars.colors.white};
            padding:10px;
            display:flex;
            justify-content:space-between;
            gap:10px;
            margin-bottom: 0;
            bottom:0;
      
        
            & button{
                flex: 1;
                width:100%;
              display: flex;
              padding: 10px 16px;
              justify-content: center;
              align-items: center;
              gap: 4px;
              flex: 1 0 0;
              border-radius: 8px;
              border: 1px solid ${vars.colors.secondary6};
            }
          
            & :first-child{
                background:${vars.colors.white};
                color:black;
                padding: 10px 16px;
                border-radius: 8px;
                border: 1px solid ${vars.colors.secondary6};
            } 
          }
            
            }
          }
        }
      }
      body:has(.drawer-body:not(.refinement-drawer-show))
    {
      overflow: visible;
    }
    
      body:has(.drawer-body.refinement-drawer-show){
        overflow: hidden;
      }
    .refinement-drawer.refinement-drawer-hide{
      display:none;
      }
      .facets-navigation >:nth-child(2) .heading {
      border-top:none;
      }   
      `,
      refinements: `
      .show {
        display: block;
      }
      .hide {
        display: none;
      }
      .text-capitalize {
        text-transform: capitalize;
      }
      .line-seperator {
        border-top: 1px solid #dadada;
      }
      .heading {
        display: flex;
        align-items: center;
        background-color: ${vars.colors.white};
        justify-content: space-between;
        border-top: 1px solid #dadada;
        .text-wrap {
          text-wrap: wrap;
        }
        .title {
          font-weight: 600;
        }
        button {
          text-transform: uppercase;
          background: transparent;
          padding: 16px 40px 16px 0px;
          line-height: ${vars.fonts.lineHeightDefault};
          color: black;
          width: 100%;
          text-align: left;
          border-radius: 0px;
          font-size: ${vars.fonts.sizeLarge};
          font-weight: ${vars.fonts.weightBold};
          border-width: 0px;
          position: relative;
          &:hover {
            cursor: pointer;
          }
          i {
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            position: absolute;
          }
        }
      }
    
      .ais-RefinementList-searchBox {
        margin-bottom: 12px;
        .ais-SearchBox-form {
          height: 44px;
          .ais-SearchBox-input {
            padding: 0px ${vars.fonts.sizeLarge};
            border-radius: 5px;
            border: 1px solid var(--neutral-colours-cccccc, ${vars.colors.grey20});
            box-shadow: none;
            &::placeholder {
              color: #707070;
            }
          }
          button {
            border: none;
            background: white;
            padding: 0;
            position: absolute;
            right: ${vars.fonts.sizeLarge};
            width: 20px;
            height: 20px;
            top: 50%;
            transform: translateY(-50%);
            svg {
              width: 20px;
              height: 20px;
              fill: ${vars.colors.black};
            }
          }
          &:before {
            content: none;
          }
        }
      }
      .ais-RefinementList-noResults{
        color:red;
        padding-bottom: 16px;
      }
      ul.ais-RefinementList-list {
        margin: 0;
        li {
          &.ais-RefinementList-item {
            padding-bottom: 18px;
            .ais-RefinementList-label {
              .ais-RefinementList-checkbox {
                width: 18px;
                height: 18px;
                box-shadow: none;
                border-radius: 0;
                color: ${vars.colors.black};
              }
              .ais-RefinementList-labelText {
                font-size: ${vars.fonts.sizeDefault};
                font-weight: 400;
                line-height: ${vars.fonts.lineHeightDefault};
                padding-left: 8px;
                .ais-Highlight-highlighted {
                  background-color: transparent;
                  color: ${vars.colors.black};
                }
              }
              .ais-RefinementList-count {
                font-size: ${vars.fonts.sizeDefault};
                line-height: ${vars.fonts.lineHeightDefault};
                background: none;
                border: none;
                padding: 0;
                margin-left: 5px;
                &:before {
                  content: '(';
                }
                &:after {
                  content: ')';
                }
              }
              .rating-star {
                margin-left: 5px;
              }
            }
          }
          &.ais-RefinementList-item--selected .ais-RefinementList-checkbox:checked,
          .ais-GeoSearch-input:checked {
            background-image: url("data:image/svg+xml,%3Csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg id='Checkbox'%3E%3Crect width='18' height='18' fill='%23117B53'/%3E%3Cpath id='Vector' d='M14 5L7.125 12L4 8.81818' stroke='%23F8F5EF' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/g%3E%3C/svg%3E%0A");
            background-size: 18px;
            color: transparent;
            // accent-color: ${vars.colors.secondary6};
            // appearance: auto;
          }
        }
      }
      .ais-RefinementList-showMore:not([disabled]),
      .clear-refinements {
        background: none;
        color: ${vars.colors.grey50};
        font-size: ${vars.fonts.sizeLarge};
        line-height: ${vars.fonts.lineHeightDefault};
        text-decoration-line: underline;
        padding: 0;
        margin: 0 0 16px 0;
        cursor: pointer;
        border: none;
        box-shadow: none;
        &:hover {
          background: none;
          color: ${vars.colors.black};
          text-decoration-line: underline;
        }
      }
      .ais-RefinementList-showMore:not([disabled]) {
        position: absolute;
      }
      .clear-refinements-container {
        display: flex;
        justify-content: end;
        padding-right: 6px;
      }
      // .ais-RefinementList:has(.ais-RefinementList-showMore:not([disabled]))
      //   ~ .clear-refinements-container:empty {
      //   height: 40px;
      // }

      .clear-refinements-container.have-showmore:empty{
        height:40px  
      }
      .ais-RefinementList:has(.ais-RefinementList-showMore[disabled])
        ~ .clear-refinements-container:empty {
        height: 0;
      }

      `,
      currentRefinement: `
      .row {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: 10px;
        margin-bottom: 10px;
      }
      
    .refinements {
      display: flex;
      gap: 4px;
      flex-wrap: wrap;
    }
    button {
      &.refinement-item {
        // width: 100%;
        color: ${vars.colors.white};
        display: flex;
        border-width: 1px;
        align-items: center;
        justify-content: space-between;
        border-radius: 0px;
        border-color: transparent;
        background-color: #01193c;
        border-radius: 15px;
        padding: 4px 15px;
        gap: 10px;
        margin-bottom: 4px;
        line-height: 14px;
        svg {
          path {
            stroke: ${vars.colors.white};
          }
        }
        :hover {
          cursor: pointer;
          border-color: #eee;
          filter: brightness(55%);
        }
        &.collapse-refinement{
          background-color:  ${vars.colors.lWHUELWBlue};
          color:black;
          filter: unset !important;
        }
      }
      &.clear-refinements {
        width: 100%;
        color: #333;
        background: transparent;
        text-decoration-line: underline;
        line-height: 24px;
        font-size: ${vars.fonts.sizeLarge};
        padding: 0px;
        margin-left:8px;
        :hover {
          text-transform: underline;
          cursor: pointer;
        }
      }
    }
    ${EmotionBreakpoint('mobile')} {
      margin-bottom:14px;
      gap: 0;
      .refinements{
        gap:4px;
        flex-wrap:wrap;
        margin-bottom:5px;
        .refinement-item{
        margin-bottom: 0px;
        }
      }
      .refinement-item{

        &.collapse-refinement{
          background-color:  ${vars.colors.lWHUELWBlue};
          color:black;
          filter: unset !important;
        }
      } 
      .clear-refinements-container{
      
          display: none;
        
      }

    } 
    .text-capitalize {
      text-transform: capitalize;
    }
      `,
      sorting: `
      .results {
        .options {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          border: 1px solid #222222;
          height: 40px;
          padding: 5px 5px 5px 10px;
          border-radius: 5px;
          ${vars.breakpoints.sm} {
          min-width: 200px;
          }
          .label {
            font-weight: 700;
            line-height: 24px;
            margin-right: 7px;
          }
        }
      }
      .ais-SortBy-select {
        border: none;
        box-shadow: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        background: url('data:image/svg+xml;charset=UTF-8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2212%22%20height%3D%2212%22%20viewBox%3D%220%200%2012%2012%22%3E%3Ctitle%3Edown-arrow%3C%2Ftitle%3E%3Cg%20fill%3D%22%23000000%22%3E%3Cpath%20d%3D%22M10.293%2C3.293%2C6%2C7.586%2C1.707%2C3.293A1%2C1%2C0%2C0%2C0%2C.293%2C4.707l5%2C5a1%2C1%2C0%2C0%2C0%2C1.414%2C0l5-5a1%2C1%2C0%2C1%2C0-1.414-1.414Z%22%20fill%3D%22%23000000%22%3E%3C%2Fpath%3E%3C%2Fg%3E%3C%2Fsvg%3E')
          96% / 10% no-repeat;
        width: 120px;
        padding: 0;
      }`,
      pagination: `
      .results {
        .options {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          border: 1px solid #222222;
          height: 40px;
          padding: 5px 5px 5px 10px;
          border-radius: 5px;
          min-width: 190px;
          .label {
            font-weight: 700;
            line-height: 24px;
            margin-right: 7px;
          }
        }
        @media (max-width: 767px) {
          display: none;
        }
      }
      .ais-HitsPerPage-select {
        border: none;
        box-shadow: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        background: url('data:image/svg+xml;charset=UTF-8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2212%22%20height%3D%2212%22%20viewBox%3D%220%200%2012%2012%22%3E%3Ctitle%3Edown-arrow%3C%2Ftitle%3E%3Cg%20fill%3D%22%23000000%22%3E%3Cpath%20d%3D%22M10.293%2C3.293%2C6%2C7.586%2C1.707%2C3.293A1%2C1%2C0%2C0%2C0%2C.293%2C4.707l5%2C5a1%2C1%2C0%2C0%2C0%2C1.414%2C0l5-5a1%2C1%2C0%2C1%2C0-1.414-1.414Z%22%20fill%3D%22%23000000%22%3E%3C%2Fpath%3E%3C%2Fg%3E%3C%2Fsvg%3E')
          85% / 25% no-repeat;
        width: 50px;
        padding: 0;
      }
      `,
      accordionWrapper: `
      .accordion-item {
        border-bottom: 1px solid ${vars.colors.grey20};
        padding: 10px 0px;
        // display: flex;
        align-items: center; /* Center the text and icon vertically */
        cursor: pointer;
      }
  
      .accordion-title {
        display: flex;
        justify-content: space-between;
        padding-right: 10px;
        align-items: center;
        .title {
          font-family: ${vars.fonts.familySecondary};
          font-size: 1.2em;
          font-weight: ${vars.fonts.weightBold};
          text-transform: capitalize;
          margin:0;
          padding:0;
        }
      }
  
      .accordion-icon {
        margin-left: 8px; /* Add some space between the text and icon */
        svg {
          max-height: 10px;
        }
      }
      .accordion-content {
        display: none;
        &.show-accordion {
          display: block;
        }
      }
      `,
      quickViewWrapper: `
      .description-title .mantine-gm39d{
        font-weight: 600;
        font-size: 16px;
      }
        &.quick-view-modal-container {
          .mantine-Modal-content .mantine-Modal-header {
            justify-content: flex-end;
          }
          &.rate-review {
            .mantine-Modal-body, .mantine-Modal-header {
              background-color: ${vars.colors.primary3};
            }
          }
        }s
        .mantine-Modal-overlay {
          z-index: 500;
        }
        .mantine-Modal-inner {
          z-index: 500;
          @media (max-width: 767px) {
            padding: 0;
            align-items: unset;
          }
        }
        .mantine-Modal-content {
          padding: 20px;
          border-radius: 10px;
          background: #F8F5EF;
          .mantine-Modal-header {
            background: #F8F5EF;
            .mantine-Modal-title {
              color: #000;
              font-size: 30px;
              font-weight: 500;
            }        
            .mantine-CloseButton-root {
              padding: 0;
              line-height: 0;
              background: transparent;
              color: ${vars.colors.black};
              width: auto;
              height: auto;
              border: none;
              svg {
                width: 32px !important;
                height: 32px !important;
              }
            }
          }
          .mantine-Modal-body {
            padding: 0px;
            padding-top: 0;
            .PDP-title {
              margin-top: 0;
              font-size: 38px;
              line-height: 52px;
              @media (max-width: 767px) {
                font-size: 25px;
                line-height: 28px;
              }
            }
            .submission-success {
              text-align: center;
            }
            .table-container {
              header, .row {
                display: flex;
              }   
              header {
                .column {
                  font-weight: ${vars.fonts.weightBold};
                }
              }   
              .row {
                border-bottom: 1px solid ${vars.colors.grey20};
                
              }  
              .column {
                flex: 1;
                padding: 10px 0px;
              }
            }
            .product-main-container {
              display: flex;
              .w-100 {
                width: 100%;
              }
              .product-image-container {
                max-width: 217px;
                position: relative;
                .image-container {
                  text-align: center;
                  position: relative;
                  img {
                    min-height: 400px;
                    min-width: 112px;
                    &.case {
                      min-height: 275px;
                      min-width: 306px;
                    }
                  }
                }
                .wine-details {
                  ul {
                    list-style: none;
                    margin-top: 30px;
                    padding-left: 30px;
                    li {
                      padding-bottom: 10px;
                      display: flex;
                      align-items: center;
                      a {
                        display: flex;
                        align-items: center;
                      }
                      i, img {
                        width: 32px;
                        margin-right: 10px;
                      }
                      &:last-child {
                        padding-bottom: 0px;
                      }
                    }
                  }
                }
                &.case {
                  max-width: 320px;
                }
              }
              .product-content-container {
                margin-left: 24px;
                @media (max-width: 767px) {
                  margin-left: 0px;
                  margin-top: 20px;
                }
                // .rating-country-container {
                //   display: flex;
                //   align-items: center;
                //   justify-content: space-between;
                //   @media (max-width: 767px) {
                //     justify-content: unset;
                //   }
                //   .rating {
                //     ${vars.breakpoints.xl} {
                //       display: flex;
                //       gap: 5px;
                //     }
                //     .star-icons {
                        margin-right:4px;
                //       ${vars.breakpoints.xl} {
                //         gap: 5px;
                //         display: inline-flex;
                //       }
                //     }
                //     .rating-cout {
                //       margin: 0 15px 0;
                //       font-size: ${vars.fonts.sizeDefault};
                //       font-weight: ${vars.fonts.weightBold};
                //     }
                //     .review-count {
                //       text-decoration: underline;
                //     }
                //   }
                //   .country {
                //     display: flex;
                //     align-items: center;
                //     font-size: ${vars.fonts.sizeLarge};
                //     line-height: ${vars.fonts.lineHeightDefault};
                //     img, svg {
                //       margin-right: 10px;
                //       @media (max-width: 767px) {
                //         width: 24px;
                //       }
                //     }
                //     @media (max-width: 767px) {
                //       font-size: 12px;
                //       margin-left: 25px;
                //     }
                //   }
                // }
                .image-price-container {
                  border-bottom: 1px solid ${vars.colors.grey20};
                }
                .image-container {
                  text-align: center;
                  margin: 30px 0;
                }
                .wine-details {
                  margin-top: 30px;
                  ul {
                    list-style-type: none;
                    padding: 0;
                    height: 100px;
                    li {
                      display: flex;
                      align-items: center;
                      margin-bottom: 25px;
                      float: left;
                      width: 160px;
                      a {
                        display: flex;
                        align-items: center;
                        cursor: pointer;
                        &:hover {
                          text-decoration: none;
                        }
                      }
                      i {
                        margin-right: 10px;
                        width: 24px;
                        font-size: 24px;
                      }
                      svg {
                        margin-right: 10px;
                        width: 24px;
                        height: 24px;
                      }
                    }
                  }
                }
                .money-back-guarantee {
                  background-color: ${vars.colors.lWHUELWBlue};
                  padding: 15px;
                  margin: 30px 0;
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  .content {
                    max-width: 429px;
                    .descp {
                      font-weight: ${vars.fonts.weightBold};
                      padding-bottom: 10px;
                    } 
                  }
                  .icon i:before {
                    font-size: 44px;
                  }
                }
                &.case {
                  .title-rating-container, .description-container {
                    display: none;
                  } 
                }
              }
              @media (min-width: 768px) {
                .desktopOnly {
                  display: block;
                }
                .mobileOnly {
                  display: none;
                }
              }
              @media (max-width: 767px) {
                .desktopOnly {
                  display: none;
                }
                .mobileOnly {
                  display: block;
                }
              }
            }
          }
        }
      `,
      sliderWrapper: `
        .price-heading {
          line-height: ${vars.fonts.lineHeightDefault};
          text-transform: uppercase;
          // margin: 25px 0 15px;
          padding: 16px 0px;
          font-size: ${vars.fonts.sizeLarge};
          font-weight: ${vars.fonts.weightBold};
          border-top: 1px solid #dadada;

        }
        .slider-root {
          position: relative;
          display: flex;
          align-items: center;
          margin: 25px 10px 40px 10px;
          .slider-track {
            position: relative;
            flex-grow: 1;
            background-color: #969696;
            height: 4px;
            border-radius: 9999px;
            .slider-range {
              position: absolute;
              background-color: ${vars.colors.primary1};
              height: 100%;
            }
          }
          .slider-thumb {
            position: relative;
            display: block;
            width: 12px;
            height: 12px;
            background-color: ${vars.colors.primary1};
            border-radius: 9999px;
            cursor: pointer;
            &[aria-label='Minimum']:after {
              left: 0;
            }
            &[aria-label='Maximum']:after {
              right: 0;
            }
            &:after {
              position: absolute;
              bottom: 15px;
              content: '£'attr(aria-valuenow);
              font-size: 12px;
              line-height: 18px;
            }
            &:focus-visible {
              outline: none;
            }
          }
        }
        .range-input {
          display: flex;
          justify-content: center;
          margin: 20px 10px;
          .ais-RangeInput {
            width: 100%;
            label {
              width: 25%;
              &:first-child:before {
                content: 'From';
              }
              &:before {
                content: 'To';
              }
            }
            input {
              border: 1px solid ${vars.colors.grey20};
              border-radius: 5px;
              box-shadow: none;
              text-align: center;
              margin-top: 5px;
              width: 100%;
              padding: 0;
            }
            button {
              background: ${vars.colors.btnsPrimaryBg};
              text-align: center;
              margin: 21px 0 0 10px;
              line-height: 10px;
              border-radius: 3px;
              &:hover {
                background: ${vars.colors.btnsPrimaryHoverBg};
                border: none;
              }
              &:focus {
                color: ${vars.colors.white};
              }
            }
          }
        }
      `,
      hitWrapper: ` 
      button {
        width: 100%;
      }            
      &.grid-view {    
        .class-grid {
          display: block !important;
        }    
        .ais-Hits-list {
          @media (min-width: 769px) {
            grid-gap: 18px;
          }
          ${vars.breakpoints.xxl} {
            grid-gap: 26px;
          }
          .border-bottom-ccc{
            border-bottom:none;
          }
          .web-description {
            @media (max-width: 1365px) {
              display: none !important;
            }
            @media (max-width: 1600px){
              &.case {
                position: absolute;
                top: 66% !important;
              }
            } 
            @media (min-width: 1900){
              &.case {
                position: absolute;
                top: 59% !important;
              }
            } 
            @media (min-width: 1300px) and (max-width: 1400px) {
              &.case {
                position: absolute;
                top: 57%;
              }
            } 
            margin: 20px 0;
            &.case {
              position: absolute;
              top: 70%;
              transform: translateY(-50%);
              margin: 0 auto !important;
              width: 90%;
            }
          }

          @media (min-width: 768px) and (max-width: 1229px) {
            margin-top: 16px;
          }

          @media (min-width: 769px) {
            grid-template-columns: 1fr 1fr 1fr;
          }
          
          .ais-Hits-item {
            display: flex;
            flex-direction: column;
            padding: 0px;
            border: 1px solid #BCBCBC;
            border-radius: 12px;
            transition: box-shadow 0.3s ease-in-out;
            ${vars.breakpoints.md} {
              border-radius: 4px;
              min-height: 550px;
            }
            ${vars.breakpoints.xl} {
              min-height: 614px;
            }
            &:hover {
              box-shadow: 0px 30px 60px 0px rgba(52, 31, 0, 0.10), 0px 10px 30px 0px rgba(30, 20, 0, 0.10);
            }
            .image-bg {
              width: 100%;
              border-radius: 4px;
              .image-container {
                height: 209px;
                .offer {
                  .stock {
                    position: absolute;
                    left: 10px;
                    bottom: 4px;
                    margin-bottom: 0;
                    z-index: 0;
                  }
                  svg {
                    position: absolute;
                    left: 10px;
                    top: 10px;
                    // z-index: 2;
                  }
                }
                .image {
                  .bottle {
                    max-width: 52px;
                    height: 177px;
                  }
                }
                .quick-links-container {
                  flex-direction: column-reverse;
                  gap: 15px;
                  bottom: 10px;
                }
                .quick-view-container {
                  display: none;
                }
              }
            } 
            .hit-content {
              text-align: center;
              padding: 16px;
              width: 100%;
              margin: 0;
              @media(min-width: 330px) and (max-width: 460px){
                min-height: 328px;
              }
              ${vars.breakpoints.xl} {
                min-height: 160px;
              }
              ${vars.breakpoints.xxl} {
                min-height: 164px;
              }
              min-height: 185px;
              display:flex;
              flex-direction:column;
              justify-content: space-between;
              position: relative;
              .title {
                ${vars.breakpoints.sm} {
                  font-size: 18px;
                  font-weight: 700;
                  line-height: 140%;
                }
                ${vars.breakpoints.xl} {
                  font-size: 22px;
                  font-weight: 600;
                  line-height: 120%;
                }
                ${vars.breakpoints.xxl} {
                  font-size: ${vars.fonts.sizeH8}
                }
              }
              .inline-container {
                .country-rating{
                  display: flex;
                  justify-content: center;
                  flex-direction: column;
                }
                .happy-to-wait-container{
                  min-height: 46px;
                  margin-top: 10px;
                  margin-bottom: 20px;
                }

                justify-content: center;
                flex-direction: column;
                gap: 0;

                .rating {
                  a{
                    span{
                      svg{
                        margin-right:4px;
                      }
                    }
                  }
                  margin-top: 5px;
                  order: 1;
                  justify-content: center;
                }
                .country {
                  color: ${vars.colors.primary8};
                  align-items: center;
                  justify-content: center;
                  margin-bottom: 5px;
                  order: 0;
                  ${vars.breakpoints.sm} {
                    flex-direction: row;
                  }
                  ${vars.breakpoints.md} {
                    flex-direction: row;
                  }
                  ${vars.breakpoints.xl} {
                    flex-direction: row;
                  }
                  .country-grapeVariety.grapeVariety-container {
                    margin-left: 0;
                    ${vars.breakpoints.xl} {
                      margin-left: 8px;
                    }
                    ${vars.breakpoints.xxl} {
                      margin-left: 10px;
                    }  
                  }
                }
              }
              .title {
                margin-bottom: 0px;
                @media (min-width: 1025px) {
                  min-height: 56px;
                  text-overflow: ellipsis;
                  overflow: hidden;
                  height: 0;
                }
                @media (min-width: 1366px) {
                  min-height: 56px;
                }
                @media (min-width: 1920px) {
                  min-height: 67px;
                }
                .titleDescSale {
                  a{
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    text-overflow: ellipsis;
                  }
                  
                }
              }
              .reserveTodayContainer {
                margin: 0 auto!important;
                width: 90%;
                > div {
                    @media (min-width: 1025px) {
                      padding: 10px;
                      margin-bottom: 10px;
                      margin-right:0;
                      margin-left:0;
                    }
                    ${vars.breakpoints.xl} {
                      padding: 15px;
                      margin: 0 20px 25px 20px;
                    }
                }
                div {
                  border: none;
                }
              }
            }
            .addToCart-container {
              position: absolute;
              bottom: 16px;
              padding: 0 16px;
              width: 100%;
              left: 0;
              right: 0;
              margin: 0 auto;
              .lowestPricepossible, .lowestPricePossibleOnOffer{
                font-family: ${vars.fonts.familySecondary};
                letter-spacing: 0.18px;
                font-weight: 700;
                color: ${vars.colors.primary1};
                text-align: center;
                margin-bottom: 3px;
                &.no-pill-lowestPricepossible{
                  color: ${vars.colors.grey50};
                }
                &.no-pill-lowestPricePossibleOnOffer{
                  color: ${vars.colors.grey50};
                }
                @media(min-width: 330px) and (max-width: 460px){
                  margin-bottom:10px;
                }
                @media (min-width: 1025px) {
                  font-size: 14px;
                  line-height: 130%;
                }
                ${vars.breakpoints.lg} {
                  font-size: 16px;
                  line-height: 140%;
                }
                ${vars.breakpoints.xl} {
                  font-size: 16px;
                  line-height: 140%;
                }
                ${vars.breakpoints.xxl} {
                  font-size: 18px;
                  line-height: 140%;
                }
                .strike {
                  font-family: ${vars.fonts.familyTertiary};
                  font-weight: 400;
                  text-decoration: line-through;
                }
                &.presell-primeur {
                  color: #303030;
                  font-weight: 400;
                  font-family: ${vars.fonts.familySecondary};
                  font-size: 16px;
                  .currency {
                    color: ${vars.colors.black};
                    font-weight: 700;
                  }
                }
                &.lowestPricePossibleOnOffer{
                  font-weight:400 !important;
                }
              }
              .maxSavingsContainer {
                text-align: center;
                .saving-section-content {
                  border-radius: 30px;
                  background: ${vars.colors.primary5};
                  font-size: 12px;
                  font-weight: 700;
                  line-height: 18px;
                  padding: 5px 8px;
                  display: inline-block;
                  .saving {
                    color: ${vars.colors.primary1};
                  }
                }
                @media(min-width: 360px) and (max-width: 460px){
                  margin-bottom:10px;
                }
                @media(min-width: 300px) and (max-width: 360px){
                  margin-bottom:30px;
                }
              }              
              .top-price-section {
                padding-top: 0px;
                // justify-content: space-around;
                // text-align: center;
                padding-bottom: 6px;
                display: block;
                // .saving-section {
                //   display: none;
                // }
                .top-price-section {
                  display: flex !important;
                }
              }
              .single-sku-container {
                text-align: center;
                .top-price-section {
                  justify-content: center;
                  gap: 15px;
                  align-items: center;
                }
              }
              .country {
                justify-content: center;
                gap: 10px;
                height: 50px;
              }
              .vpp-price, .tiered-price-container {
                // text-align: center;
              }

              .grid-addto-cart-container {
                margin-top: 12px;
                .mantine-Popover-dropdown {
                  @media (min-width: 1025px) {
                    width: 350px !important;
                  }
                  @media (min-width: 1480px) and (max-width: 1680px) {
                    width: 380px !important;
                  }
                  @media (min-width: 1551px) {
                    width: 420px !important;
                  }
                  @media (min-width: 1680px) {
                    width: 480px !important;
                  }
                }
                .grid-addto-cart-btn {
                  background-color: ${vars.colors.btnsPrimaryBg};
                  &:hover {
                    background-color: ${vars.colors.btnsPrimaryHoverBg};
                    color: ${vars.colors.white};
                  }
                }
              }
            }
          }
        }
      }
      &.list-view {
        .class-grid {
          display: none !important;
        }
        .ais-Hits-list {
          grid-template-columns: 1fr;
          
          .ais-Hits-item {
            border-top: 1px solid #eee;
            display: block;
            box-shadow: none;
            padding: 32px 0px;
            align-items: flex-start;

            &:first-child {
              border-top: none;
            }
            &:last-child {
              padding-bottom: 48px;
            }
            
              display: grid;
              grid-template-columns: 1fr;
              gap: 24px;

              ${vars.breakpoints.lg} {
                grid-template-columns: 240px auto 320px;
              }

              ${vars.breakpoints.xl} {
                grid-template-columns: 340px auto 400px;
              }    

              .image-bg {
                border-radius: 8px;
              }

              .hit-content{
                .web-description{
                  height: fit-content;
                  min-height: 0px;
                }
              }
              
              .offer {
                .stock {
                  position: absolute;
                  left: 10px;
                  bottom: 4px;
                  margin-bottom: 0;
                  // z-index: 2;
                }
                svg {
                  position: absolute;
                  left: 10px;
                  top: 10px;
                  // z-index: 2;
                }
              }

              .addToCart-container {
                .country {
                  display: none;
                }
              }
              

              .quick-links-container {
                flex-direction: column-reverse;
                gap: 15px;
                bottom: 10px;
              }
              
              .quick-view-container {
                display: none;
              }
            
          }
        
          
        }
      }
      .ais-Hits div + ol {
        @media (min-width: 768px) {
          margin-top: 16px;
        }
      }
      .ais-Hits-list {
        display: grid;
        grid-template-columns: 1fr;
        .ais-Hits-item {
          box-shadow: none;
          position : relative;
          font-size: 1rem;
          .ais-Highlight {
            mark {
              color: ${vars.colors.primary1};
            }
          }
  
          .title {
            font-size: ${vars.fonts.lineHeightDefault};
            font-family: ${vars.fonts.familyTertiary};
            font-weight: ${vars.fonts.weightBold};
            line-height: 32px;
            margin-bottom: 10px;
          }
  
          .prod-link {
            a {
              text-decoration: underline;
              &:hover {
                text-decoration: none;
              }
            }
          }
  
          .pill {
            display: inline-flex;
            padding: 2px 5px;
            justify-content: center;
            align-items: center;
            gap: 10px;
            border-radius: 30px;
            margin: 10px 0px;
            background: rgba(255, 168, 161, 0.4);
            .saving {
              color: ${vars.colors.primary1};
              font-size: 10px;
              font-family: ${vars.fonts.familySecondary};
              font-weight: ${vars.fonts.weightBold};
            }
          }

          .stock {
            background: ${vars.colors.primary6};
            color: ${vars.colors.white};
            font-size: 12px;
            line-height: 18.75px;
            gap: 5px;
            padding: 3px 8px;
            margin-top: 0;
            .case-count {
              font-size: 16px;
              font-weight: 700;
            }
          }
  
          .hit-content {
            margin-bottom: 5px;
            .inline-container {
              display: flex;
              flex-wrap: wrap;
              align-items: center;
              gap: 15px;
              .country-rating{
                margin:10px 0px;
              }
              .rating {
                display: flex;
                align-items: center;
                margin-top: 20px;
                justify-content: center;
                ${vars.breakpoints.sm} {
                  justify-content: start;
                }
                .review-count {
                  color: ${vars.colors.primary8};
                  font-size: 14px;
                  line-height: 24px;
                  margin-left: 5px;
                  text-decoration: underline;
                }
              }
            }
            .web-description {
              margin-top: 10px;
              margin-bottom: 20px;
              color: ${vars.colors.primary8};
              font-size: ${vars.fonts.sizeLarge};
              line-height: 20px;
              ${vars.breakpoints.xl} {
                font-size: 16px;
                font-weight: 400;
                line-height: 1.4rem;

                text-overflow: ellipsis;
                overflow: hidden;
                height: 0;
                min-height: 46px;
              }
              ${vars.breakpoints.xxl} {
                font-size: ${vars.fonts.sizeLarge};
                line-height: 140%;
                min-height: 44px;
              }
            }
          }

          .image-bg {
            background-color: ${vars.colors.primary3};
            padding: 10px 0px;
            &.gift-image {
              background: none;
              padding: 0px;
              .image-container{
                height: 229px;
                padding: 0px;
              }
              .web-description {
                min-height: 95px!important;
              }
              .image {
                margin: 0px;
                height: 100%;
              }
              .image img {
                border-radius: 10px;
                object-fit: cover;
              }
            }
          }
  
          .image-container {   
            position: relative;
            padding: 20px;
            height: 245px;
            max-height: 245px;
            .image {
              margin: 10px 0px;
              position: relative;
              height: 100%;
              img {
                object-fit: contain;
                object-position: 50% 50%;
                border-radius: 10px;
                position: absolute;
                height: 100%;
                width: 100%;
                left: 0;
                top: 0;
                right: 0;
                bottom: 0;
                color: transparent;
              }
            }
            .image-center {
              position: relative;
              height: 100%;
              width: 100%;
              display: flex;
              align-items: center;
              justify-content: center;
              margin: 0;
            }
            .offer {
              text-align: center;
            }
            
            .quick-links-container {
              display: flex;
              gap: 10px;
              position: absolute;
              top: 15px;
              right: 15px;
              .zoom, .favourite {
                background: white;
                padding: 5px 5px 0px 5px;
                border-radius: 20px;
                &:hover {
                  cursor: pointer;
                }
              }
            }
            .quick-view-btn {
              position: absolute;
              bottom: 15px;
              right: 15px;
              max-width: 95px;
              padding: 0;
              max-height: 24px;
              border-radius: 4px;
              background-color: ${vars.colors.secondary4};
              .mantine-Button-label {
                font-size: 12px;
                line-height: 14.06px;
                font-weight: 400;
              }
              &:focus {
                color: ${vars.colors.white};
                border-color: transparent;
              }
            }
            // display: grid;
            // grid-template-columns: 1fr 3fr 1fr;
          }
        }

        
        
      }
      li.ais-Hits-item:empty {
        display: none !important;
    }
      ${EmotionBreakpoint('mobile')} {
        .ais-Hits-list {
          grid-gap: 30px; 
        }
        .ais-Hits-item{
          border-radius: 12px;
          border-top: none;
          border: 1px solid #CCC;
          background: ${vars.colors.white};
          padding: 0px!important;
          .hit-content{
            padding:16px;
            padding-bottom: 0;
            text-align: center;
            .inline-container {
              justify-content: center;
              .rating {
                order: 1;
              }
            }
          }
          .addToCart-container {
            margin:0px 16px 16px 16px;
            .form-control.quantity {
              border:none;
            }
            .customProdQantityField {
              & div:first-child {
                min-width:38px;
                margin-right: 8px;
              }
            }
            .add-to-cart-btn-container {
              .cart-button {
                button {
                  padding: 8px 24px;
                  @media (max-width: 374px) {
                    padding: 8px 16px;
                  }
                }
              }
            }
          }
        }
      }
    `,
      searchBoxWrapper: `
      .aa-Autocomplete {
        display: flex;
        flex-direction: column;
      
        .aa-Form {
          box-shadow: none;
          border: 0;
          border-radius: 50px;
          display: flex;
          width: 100%;
          align-items: center;
          ${vars.breakpoints.lg} {
            border: 1px solid ${vars.colors.grey20};
          }
        }
        .aa-InputWrapperPrefix {
          display: none;
        }
        .aa-InputWrapper {
          padding-left: 0;
          width: 100%;
          .aa-Input {
            border-radius: 50px !important;
            box-sizing: border-box;
            box-shadow: none;
          }
        }
        .aa-InputWrapperSuffix {
          padding-right: 10px;
          z-index: 1;
          ${vars.breakpoints.lg} {
            padding-right: 4px;
          }
          button {
            &.aa-ClearButton {
              vertical-align: middle;
              background: ${vars.colors.primary1};
              padding: 5px;
              border-radius: 50px !important;
              color: ${vars.colors.white};
              width: 26px;
              height: 26px;

              ${vars.breakpoints.lg} {
                padding: 8px;
                width: 36px;
                height: 36px;
              }

              svg {
                width: 26px;
                height: 26px;

                &:focus {
                  outline: none;
                }
              }
            }
            &:hover {
            }
          }
        }
      }
      `,
      autocompletePanel: `
        display: flex;
        align-content: stretch;
        column-gap: 30px;

        .ui-container {
          display: flex;
          gap: 25px;
          flex-direction: column;
          ${vars.breakpoints.md} {
            flex-direction: unset;
          }

          .aa-Item {
            &[aria-selected="true"] {
              background: ${vars.colors.lWHUELWBlue};
            }
            
          }

          #query-suggestions {
            .aa-ItemIcon {
              display: none;
            }
            .aa-ItemActions {
              display: none;
              
            }
          }

          #recent-searches {
            .aa-ItemActions button:nth-child(1) {
              background-color: transparent;
              padding: 0px;
              display: inline-grid;
              
              &:hover {
                background-color: transparent;
              }
                svg {
                    background-repeat: no-repeat;
                    background-position: center;
                    background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12 4L4 12' stroke='black' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M4 4L12 12' stroke='black' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
                    path {
                        display: none;
                    }
                }
            }
            .aa-ItemActions button:nth-child(2),
            .aa-ItemIcon {
                display: none;
            }
            .aa-ItemContentTitle {
                mark {
                    font-weight: ${vars.fonts.weightNormal};
                }
            }
        } 

          .us-results-image{
            box-shadow: none;
            height: 60px;
            min-width: 70px;
            width: 60px;

            img {
              height: 40px;
              max-height: none;
              max-width: 65px;
          }
          }

          .results-image {
            box-shadow: none;
            height: 60px;
            min-width: 70px;
            width: 60px;

            img {
              height: 60px;
              max-height: none;
              max-width: none;
          }
        }

        

          .aa-SourceHeader {
            border-bottom: 1px solid #eee;
            font-size: ${vars.fonts.sizeDefault};
            padding-bottom: 10px;
            text-transform: uppercase;
            white-space: nowrap;
            .aa-SourceHeaderTitle {
              font-size: 1em;
              font-weight: ${vars.fonts.weightNormal};
              color: ${vars.colors.black};
            }
          }

          .ui-plugins {
            .aa-Source {
              margin-bottom: 25px;
            }
          }
        }
      `,
      searchOverlay: `
        position: fixed;
        left: 0;
        right: 0;
        height: 100%;
        background: ${vars.colors.black};
        opacity: 0.6;
      `,
    },
    CmsBanner: {
      default: `
      overflow: hidden;
      .banner{
        ${vars.breakpoints.md}{
          padding: 5% 5% 5% 5%;
          position:relative;
        }
        ${vars.breakpoints.lg}{
          padding: 5% 15% 5% 10%;
        }
      }
      .banner-firstgrid{
        position: relative;
        padding:0px;
        margin:0px;
        order:1
      }
      .banner-secondgrid{
        position: relative;
        padding:0px;
        margin:0px;
        order: 2
      }
      .banner-img{
      display: block;
      object-fit:contain;
      }
      .banner-imgcomp{
        ${vars.breakpoints.md}{
        position: absolute;
        top:50%;
        left: 50%;
        transform: translate(-50%, -50%);
        }
      }
      .banner-sources{
        padding:0px;
      }
      .banner-title {
          color: ${vars.colors.white};
          font-weight:${vars.fonts.weightMedium};
          padding-bottom: 12px;
          text-align:center;
          margin:0%;
          font-size:22px;
          font-family:${vars.fonts.familyTertiary};
          padding-top:36px;
        
        ${vars.breakpoints.md}{
          text-align:left;
          font-size:60px;
          font-family:${vars.fonts.familyPrimary};
        }
        }
        .banner-content {
          a {
            color: ${vars.colors.white};
          }
            padding-left:${vars.fonts.sizeLarge};
            padding-right:${vars.fonts.sizeLarge};
        
          ${vars.breakpoints.md} {
            padding:0px 0px 6px 0px;
          }
        }

      .banner-button{
        display: flex;
        gap: ${vars.space.lg};
        flex-wrap: wrap;
        align-items: center;
      ${vars.breakpoints.md}{
          padding-left:0px
        }
        padding-left:${vars.space.lg};
      }
        .banner-info{
          padding: 16px;
          ${vars.breakpoints.xs}{
            padding: 16px
          }
          ${vars.breakpoints.sm}{
            padding: 30px
          }
          ${vars.breakpoints.md}{
            padding: 32px
          }
          ${vars.breakpoints.lg}{
            padding: 40px 85px;
          }
          ${vars.breakpoints.xxl}{
            padding: 40px 120px;
          }
        }
      `,
      wrapper: `
    margin-bottom: 24px;
    border-radius: 8px;
    padding: 24px;
    .banner-info {
    
      max-width: 764px;
      margin: 0 auto;
      padding: 0;
      .banner-content {
        padding: 0;
        a:hover {
          text-decoration: none;
        }
      }
      .banner-button {
        margin-top: 16px;
        display: flex;
        align-items: center;
        gap: 16px;
      
        flex-wrap: wrap;
        padding: 0;
        ${vars.breakpoints.xs}{
          gap: 12px;
        }
        a {
          button {
            font-size: 12px;
            font-weight: 600;
            text-transform: uppercase;
            letter-spacing: 0.24px;
            min-width: 140px;
            padding: 2px 16px;
            background: transparent;

            cursor: pointer;
            &.darkColor {
              color: ${vars.colors.primary8};
              border: 1px solid ${vars.colors.primary8};
              &:hover {
                background-color: ${vars.colors.btnsPrimaryHoverBg};
                color: ${vars.colors.white};
                border-color: ${vars.colors.btnsPrimaryHoverBg};
              }
            }
            &.lightColor {
              color: ${vars.colors.white};
              border: 1px solid #f8f8f8;
              &:hover {
                background-color: ${vars.colors.white};
                color: ${vars.colors.primary8};
              }
            }
          }
        }
      }
      h2,
      p {
        margin: 0;
      }
    }
  `,
      bannerSkeleton: `
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 20px;
        min-height: 300px;
          .text-section{
            flex: 1;
            max-width: 60%;
            margin-left: 40px;
          }
          .image-section{
            margin-left: 40px;
            min-height: 500px;
          }
          .cta-section{
            display: flex;
            gap:10px;
          }
        `,
      BannerWithButton: `
      position:relative;
        .banner-content {
          display: flex;
          flex-direction: column;
          padding:15px !important;
          ${vars.breakpoints.xl} {
            padding:18px !important;
          }
          color: ${vars.colors.btnsPrimaryColor};
          justify-content: space-between;
          width: 100%;
          height: 100%;
          position:absolute;
          background: linear-gradient(90deg, #000 0.05%, rgba(0, 0, 0, 0.00) 107.69%);
          z-index:2;
        }
        & img{
          min-height:230px;
        }
      
      `,
    },
    bannerSkeleton: {
      wrapper: `display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 20px;
      min-height: 300px;
        .text-section{
          flex: 1;
          max-width: 60%;
          margin-left: 40px;
        }
        .image-section{
          margin-left: 40px;
          min-height: 500px;
        }`,
    },

    CmsMenuBanner: {
      wrapper: `
        display: flex;
        flex-direction: column;
        align-items: end;

        .nav-banner-img {
          display: block;
          width: 100%;
          height: auto;
        }
        .main-content-container & {
            display: unset;
            a {
              display: inline-block;
              img {
                width: auto;
              }
            }
        }
      `,
    },
    CmsNavigation: {
      wrapper: `
      `,
      body: `
        &.navigation-link {
          display: flex;
          flex-direction: column;
          flex-wrap: wrap;

          a{
            text-wrap:nowrap;
          }
          > a {
            text-wrap:initial;
            line-height: 18px;
            margin-bottom: ${vars.fonts.sizeDefault};
            width: fit-content;
          }
        }
      `,
      sectionLinkFooter: `
        margin-top:20px;
        text-wrap:nowrap;
        a{
          display: inline-block;
          text-decoration: underline;
        }
        i{
          ${vars.mixins.iconBase}
          ${vars.icons['chevron-right']} 
          display: inline-block;
          font-size: ${vars.fonts.lineHeightDefault};
          margin-left: 0px;
          ${vars.breakpoints.xl}{
            margin-left: 10px;
          }
        }
      `,
    },
    CmsArticle: {
      wrapper: `
        display: flex;
        flex-direction: column;
        margin-bottom: 20px;
        > * {
          flex-basis: 50%;
        }
        ${vars.breakpoints.lg} {
          margin-bottom: 0;
        }
        img {
          max-width: 100%;
          height: 100%;
        }
        .article-container {
          margin-top: 24px;
          > p {
            font-weight: ${vars.fonts.weightNormal};
            font-size: ${vars.fonts.fontSize16};
            line-height: 22.4px;
            color: ${vars.colors.grey60};
          }
          h2 {
            margin-top: 8px;
            margin-bottom: 16px;
            color: ${vars.colors.black};
          }
          .secondary {
            color: ${vars.colors.black};
          }
          .date {
            color: ${vars.colors.grey60};
          }
          .article-content {
            color: ${vars.colors.grey60};
            p {
              ${vars.mixins.body1}
            }
          }
        }
      `,
    },
    CmsCarousel: {
      viewport: `
        padding-bottom: 24px;
      `,
      indicators: `
        bottom: 17px;
      `,
      indicator: `
        background: ${vars.colors.secondary9};
        width: 8px;
        height: 8px;
        border-radius: 100%;
        opacity: 1;

        &[data-active] {
          background: ${vars.colors.grey50};
        }
      `,
      controls: `
        position: static;
        max-width: 250px;
        margin: 0 auto;
      `,
      control: `
        width: 44px;
        height: 44px;
        border-color: ${vars.colors.secondary9};
        svg {
          width: 1.5rem !important;
          height: 1.5rem !important;
        }

        &[data-inactive] {
          background: transparent !important;
        }
      `,
    },
    CmsVideo: {
      videoCompCss: `
        display: flex;
        margin: 0 auto;
        position: relative;
        justify-content: center;
        align-items: center;
        max-width: 1100px;
        margin: 0 auto;

        .main-content-container & .video-container {
          position: relative;
          height: 0;
          width: 100%;
          & > div,
          .cms-image-container img {
            position: absolute;
            left: 0;
            top: 0;
            width: 100% !important;
            height:100% !important;
            object-fit: cover;
          }
          .react-player__shadow {
            display: none !important;
          }
        }
        &.full-width {
          display: grid;
          grid-template-columns: 1fr;
          min-height: 600px;
          height: 100%;
          max-width: none;
          .full-width-overlay {
            grid-row-start: 1;
            grid-column-start: 1;
            width: 100%;
            height: 100%;
            z-index: 1;

            .video-grid {
              height: 100%;
              margin: 0;
              .overlay-content {
                display: flex;
                flex-direction: column;
                justify-content: center;
                height: 100%;
                color: ${vars.colors.white};
                padding: 16px;
                ${vars.breakpoints.sm}{
                  padding: 40px 32px;
                }
                ${vars.breakpoints.xl}{
                  padding: 40px 72px;
                }
                ${vars.breakpoints.xxl}{
                  padding: 40px 120px;
                }
                .play-button {
                  border: 1px solid ${vars.colors.white};
                  padding: 12px 24px;
                  border-radius: 1px;
                  cursor: pointer;

                  display: flex;
                  gap: 8px;
                  align-items: center;
                  justify-content: space-between;
                }
              }
            }
          }
          .video-container {
            grid-row-start: 1;
            grid-column-start: 1;
            width: 100%;
            height: 100%;
            padding: 0 !important;
            .react-player__shadow {
              display: none !important;
            }
            .cms-image-container {
              width: 100%;
              height:100%;
              img {
                width: 100%;
                height: 100%;
                ${vars.breakpoints.xs} {
                  min-height: 600px;
                  object-fit: cover;
                }
              }
            }
            div {
              width: 100% !important;
              height:100% !important;
            }
            ${vars.breakpoints.sm} {
              height:  600px;
            }
            ${vars.breakpoints.md} {
              width:1100px;
              height:  625px;
            }
          }
        }
        &.playing {
          background-color: ${vars.colors.black};
          .video-container {
            width:100%;
            margin: 0 auto;
            ${vars.breakpoints.xs} {
              width:  100%;
              height: 211px;
            }
            ${vars.breakpoints.sm} {
              width:  100%;
              height: 432px;
            }
            ${vars.breakpoints.md} {
              width:  100%;
              height: 576px;
            }
            ${vars.breakpoints.lg} {
              width:  1068px;
              height: 600px;
            }
          }
        }
      `,
      overlayCss: `
        position: absolute;
        z-index: 2;
        margin: 0 auto;
        display: inline-block;
        cursor: pointer;

        svg {
          width: 47px;
          height: 47px;
          ${vars.breakpoints.sm} {
            width: 90px;
            height: 90px;
          }
        }
      `,
    },
    SlickSlider: {
      wrapper: `
      .slick-slider {
        position: relative;
        display: block;
        box-sizing: border-box;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        -webkit-touch-callout: none;
        -khtml-user-select: none;
        -ms-touch-action: pan-y;
        touch-action: pan-y;
        -webkit-tap-highlight-color: transparent;
        overflow: hidden;
        cursor: pointer;
        .slick-list {
          position: relative;
          display: block;
          overflow: hidden;
          padding: 0;
          // width: 1400px;
          margin: 0px 60px;
          .slick-track {
            position: relative;
            top: 0;
            left: 0;
            display: block;
            margin-left: auto;
            margin-right: auto;
            .slick-slide {
              display: block;
              float: left;
              height: 100%;
              min-height: 1px;
              .product-item-container {
                .product-title {
                  display: -webkit-box;
                  -webkit-line-clamp: 2;
                  -webkit-box-orient: vertical;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  font-family: ${vars.fonts.familyTertiary};
                  ${vars.breakpoints.xs}{
                    font-size: ${vars.fonts.sizeLarge};
                    min-height: auto;
                  }
                  ${vars.breakpoints.sm}{
                    min-height: 43px;
                  }
                  ${vars.breakpoints.lg}{
                    font-size: ${vars.fonts.sizeLarger};
                    min-height: 49px;
                  }
                }
              }
            }
          }
        }
        .slick-list , .slick-track {
          -webkit-transform: translate3d(0, 0, 0);
          -moz-transform: translate3d(0, 0, 0);
          -ms-transform: translate3d(0, 0, 0);
          -o-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
        } 
        .slick-arrow {
          cursor: pointer;
          top: 25%;
          transform: translateY(-50%);
          position: absolute;
          background: transparent;
          color: ${vars.colors.black};
          padding: 0;
          i {
            font-size: 60px;
          }
          &.slick-prev {
            left: 0
          }
          &.slick-next {
            right: 0;
          }
          &:focus {
            border-color: transparent;
          }
        }
        .slick-disabled {
          display: none !important;
        }
        .slick-dots {
          display: flex !important;
          justify-content: center;
          margin: 0;
          padding: 1rem 0;
          list-style-type: none;
          li {
            margin: 0 0.25rem;
            button {
              display: block;
              width: 12px;
              height: 12px;
              padding: 0;
              border: none;
              border-radius: 100%;
              background-color: ${vars.colors.grey20};
              text-indent: -9999px;
            }
            &.slick-active {
              button {
                background-color: ${vars.colors.primary1};
              }
            }
          }
        }
        .case-slick-dots {
          position: absolute;
          top: 0px;
          left: 0px;
          width: 100%;
          display: flex !important;
          justify-content: center;
          margin: 0;
          padding: 1rem 0;
          list-style-type: none;
          li {
            margin: 0 0.25rem;
            button {
              display: block;
              width: 12px;
              height: 12px;
              padding: 0;
              border: none;
              border-radius: 100%;
              background-color: ${vars.colors.grey20};
              text-indent: -9999px;
            }
            &.slick-active {
              button {
                background-color: ${vars.colors.primary1};
              }
            }
          }
        }
      }
      `,
    },
    StateSelector: {
      default: `
      .mantine-Modal-body {
        background: ${vars.colors.primary3};
        border-top-left-radius: 12px;
        border-top-right-radius: 12px;
        width: 100vw !important;
        overflow-y: auto;
        max-height: 90vh;
        ${vars.breakpoints.lg}{
          border-radius: 12px;
          width: 785px !important;
        }
      }
      .mantine-Modal-inner{
        background: transparent;
        z-index: 999 !important;
        top: auto;
        bottom: 0px;
        padding: 0;
        ${vars.breakpoints.lg}{
          top: 0px;
          bottom: 0px;
        }
      }
      .mantine-Select-input{
        width: 80vw;
        ${vars.breakpoints.lg}{
          width: auto;
        }
      }
      .modal-body-inner {
        padding: 24px;
      }
      .selector-title {
        font-family: ${vars.fonts.familyTertiary};
        font-size: 26px;
        font-weight: 600;
        line-height: 31px;
        letter-spacing: -0.01em;
        text-align: center;
        margin: 0 0 25px;
      }
      .state-selection {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 24px;
        margin: 24px 0;
        flex-direction: column;
        ${vars.breakpoints.sm} {
          flex-direction: row;
        }
      }
      .state-selector-terms {
        font-family: ${vars.fonts.familySecondary};
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 0em;
        text-align: center;
        max-width: 219px;
        color: ${vars.colors.grey50};
        margin: auto;
      }
      .state-selector-compliance {
        margin-top: 25px;
        color: ${vars.colors.grey70};

        a {
          display: inline-block;
          color: ${vars.colors.grey50};
        }
      }
      button.verify-state-btn {
        width: 142px;
        text-transform: uppercase !important;
        font-weight: 600;
        border-radius: 4px !important;
        padding: 7px 24px;
        font-family: ${vars.fonts.familySecondary} !important;
        background-color: ${vars.colors.primary1};
        &:disabled {
          cursor: not-allowed;
        }
        &:disabled:hover {
          background-color: ${vars.colors.btnDisabled};
        }
        &:hover {
          background-color: ${vars.colors.primary1BtnHover};
        }
      }
      .mantine-Input-input {
        box-sizing: border-box;
        height: 44px;
        border: 1px solid ${vars.colors.grey40};
        border-radius: 4px;
        ${vars.breakpoints.xs} {
          min-width: 343px;
        }
      }
      .mantine-Modal-content {
        border-radius: 12px;
        overflow: unset;
        transition: flex .3s ease-in-out;
      }
      .selector-subtitle {
        font-family: ${vars.fonts.familySecondary};
        font-size: 16px;
        line-height: 22px;
        letter-spacing: 0em;
        text-align: center;
        .error-text {
          color: #c84646;
        }
      }
      .dropdown-item {
        font-family: ${vars.fonts.familySecondary} !important;
        font-size: 14px !important;
        line-height: 18px !important;
        letter-spacing: 0em !important;
        text-align: left !important;
        padding: 12px 10px !important;
        &[data-selected] {
          background-color: ${vars.colors.primary1} !important;
          color: ${vars.colors.white} !important;
          &:hover {
            background-color: ${vars.colors.primary1} !important;
          }
        }
      }     
    `,
      locationWrapper: `
      display: flex;
      align-items: center;
      gap: 6px;
      cursor: pointer;
    `,
    },
    Cart: {
      wrapper: `
      border: 1px solid #222222;
      display: flex;
      justify-content: center;
      align-items: center;
      height: max-content;
      padding: 8px;
      border-radius: 4px;
      `,
      cartLayout: {
        wrapper: `
          padding-right: 16px;
          display: flex;
          justify-content: space-between;
          margin-bottom: 12px;
          border-bottom: 1px dashed #bcbcbc;
          padding-bottom: 8px;
        `,
        layout: `
          display: flex;
          width: 100%;
          flex-wrap: no-wrap;
          align-items: flex-start;
          column-gap: 10px;
          ${EmotionBreakpoint('mobile')} {
            flex-direction: column;
          }
          ${EmotionBreakpoint('tablet')} {
            flex-wrap: no-wrap;
            flex-direction: row;
            justify-content: space-between;
          }
          ${EmotionBreakpoint('desktop')} {
            flex-wrap: no-wrap;
            flex-direction: row;
            justify-content: space-between;
          }
        `,
        cartCounterAndPrice: `
          display: flex;
          justify-content: space-between;
      
          h3 {
            text-align: right | middle;
          }
          ${EmotionBreakpoint('mobile')} {
            margin-top: 16px;
            width: 100%;
          }
          ${EmotionBreakpoint('tablet')} {
            width: 45%;
          }
          ${EmotionBreakpoint('desktop')} {
            width: 50%;
          }
          .price-savings {
            display: flex;
            align-items: flex-end;
            flex-direction: column;
      
            h3 {
              margin: 8px 0;
              font-size: 22px;
              font-weight: 500;
              line-height: 26.4px;
              font-family: ${vars.fonts.familyTertiary};
            }
          }
        `,
        reservationItem: `
          .reservation-item-text {
            color: #004D86;
            font-size: 14px;
            display: flex;
            align-items: center;
            gap: 12px;
          }
          ul {
            color: #004D86;
            margin: 4px 0;
            > li {
              margin: 2px 0;
            }
          }
        `,
        freeGift: `
          margin-bottom: 16px;
          border-bottom: 1px dashed #bcbcbc;
          padding-bottom: 16px;
          .wrapper {
            display: flex;
            border: 1px solid #BCBCBC;
            padding: 16px 0;
            border-radius: 4px;
            ${EmotionBreakpoint('mobile')} {
              align-items: center;
            }
          }
          .free-gift-image {
            width: 200px;
            height: 170px;
            ${EmotionBreakpoint('mobile')} {
              width: 70px;
              height: 70px;
              margin-left: 8px;
            }
          }
          .freeline-item-imagewrapper {
            margin:16px 32px;
            ${vars.breakpoints.sm} {
              margin:4px 0 12px 12px;
            }
            ${vars.breakpoints.lg} {
              margin:8px 0 16px 16px;
            }
          }
          .text-container {
            display: flex;
            flex-grow: 1;
            justify-content: space-between;
            align-items: top;
            margin: 0 24px;
            .freeline-item-product-link, h3, h4 {
              margin: 0;
              text-align: middle;
            }
            .freeline-item-product-link:hover, h3:hover {
              text-decoration: underline;
            }
            .freeline-item-product-link,h3 {
              font-family: ${vars.fonts.familyTertiary};
              font-size:16px;
              font-weight:500;
              line-height: 21px;
              height: max-content;
              ${vars.breakpoints.sm} {
                font-size:26px;
                line-height: 31px;
              }
            }

            ${EmotionBreakpoint('mobile')} {
              justify-content: center;
              flex-direction: column-reverse;
              padding: 18px 0;
              text-decoration: none;
              gap: 24px;
            }
          }
          .gift {
            display: flex;
            align-items: center;
            height: max-content;
            gap: 8px;

            h4 {
              font-family: ${vars.fonts.familyTertiary};
              font-size: 20px;
              font-weight: 500;
              line-height: 24px;
              ${vars.breakpoints.sm} {
                h3 {
                  font-size:22px;
                  ine-height: 26.4px;
                }
              }
            }
          }
        `,
      },
      enPrimeur: `
        display: flex;
        flex-direction: row;
        gap: 8px;
        ul {
          color: ${vars.colors.secondary12};
          margin: 4px 0;
          > li {
            margin: 2px 0;
          }
        }
        .info-text p {
          margin:0;
          font-weight: 400;
          font-size: 14px;
          line-height: 18.2px;
          letter-spacing: 0.02em;
          color: ${vars.colors.secondary12};
          text-transform: uppercase;
          ${vars.breakpoints.lg} {
            font-weight: 600;
            line-height: 16px;
          }
        }
        p.clickable-link {
          cursor: pointer;
          font-weight: 400;
          font-size: 12px;
          line-height: 16.8px;
          text-decoration: underline;
          ${vars.breakpoints.lg}{ 
            font-weight: 500;
            font-size: 16px;
            line-height: 19.2px;
          }
        }
      `,
      quickViewWrapper: `
        @media (max-width: 768px) {
          .button-wrapper {
            margin-top: 28px !important;
            button {
              width:100%;
            }
          }
        }
        @keyframes slideFromBottom {
          from {
            opacity: 0;
            transform: translateY(100%);
          }
          to {
            opacity: 1;
            transform: translateY(0);
          }
        }
        .mantine-Modal-overlay {
          z-index: 400;
        }

        .mantine-Modal-inner { 
          padding:0;
          z-index: 400;
          margin-top:107px;
          ${vars.breakpoints.md}{ 
            margin-top:0;
          }
          .mantine-Modal-content {
            animation: slideFromBottom 0.5s ease forwards;
            height: -webkit-fill-available;
            border-top-left-radius: 12px;
            border-top-right-radius: 12px;
          
            @media screen and (min-width: 768px) {
              max-height: calc(100vh - (5vh * 2)) !important;
              border-radius: 12px;
              height: auto !important;
            }
          }
      
          .mantine-Modal-header .mantine-Modal-body {
            padding:24px;
          }
        }

        .mantine-Modal-content {
          background: #F8F5EF;
          .mantine-Modal-header {
            background: #F8F5EF;
            .mantine-Modal-title {
              font-family: ${vars.fonts.familyPrimary};
              font-size: 28px;
              line-height: 30.8px;
              font-weight: 400;
              ${vars.breakpoints.md}{ 
                font-size: 32px;
                line-height: 35.2px;
              }
            }
            justify-content: space-between;
            padding: 24px 24px 15px 24px;
            ${vars.breakpoints.md}{ 
              padding: 32px 32px 16px 32px;
            }
            .mantine-Modal-close {
              outline: 0;
              color: #222222;
              width: auto;
              svg {
                width: 32px !important;
                height: 32px !important;
              }
            }
          }
          .mantine-Modal-body {
            [data-test="navigation"] {
              border-bottom:1px solid #DBDBDB;
              color: #616161;
              padding:12px 0;
              margin:0;
            }
            padding:0 24px 24px 24px;
            ${vars.breakpoints.md}{ 
              padding:0 32px 32px 32px;
            }
            .regular {
              font-weight: 400;
              font-family: ${vars.fonts.familySecondary};
              font-size: 16px;
              line-height: 22.4px;
              color: #616161;
              text-transform:none;
            }
            .bold {
              font-weight: 700;
              font-family: ${vars.fonts.familySecondary};
              font-size: 16px;
              line-height: 22.4px;
            }
          }
        }
      `,
      responsiveTable: `
        .mt-32 {
          margin-top: 32px;
        }
    
        table.dltrc {
          width: 100%;
          border-collapse: separate;
          border-spacing: 0px;
          border: solid #616161 1px;
          border-radius: 8px;
        }
    
        tr.dlheader {
          text-align: left;
          font-weight: bold;
          border-left: solid #616161 1px;
          padding: 16px 12px;
          width:50%;
        }
    
        td.dlheader {
          background: #d9d9d9;
          text-align: left;
          font-weight: bold;
          border-left: solid #616161 1px;
          border-radius: 0px;
          padding: 16px 12px;
          background-color: #EDEDED;
          color: #222222;
          font-family: ${vars.fonts.familySecondary};
          font-size: 16px;
          font-weight: 700;
          line-height: 22.4px;
        }
    
        tr.dlinfo,
        td.dlinfo {
          text-align: left;
          border-left: solid #616161 1px;
          border-top: solid #616161 1px;
          padding: 16px 12px;
          font-family: ${vars.fonts.familySecondary};
          font-size: 16px;
          font-weight: 400;
          line-height: 22.4px;
          color:#222222;
          background-color:#fff;
          width:50%;
        }
    
        td.dlinfo:first-child,
        td.dlheader:first-child {
          border-left: none;
        }
    
        td.dlheader:first-child {
          border-radius: 8px 0 0 0;
        }
    
        td.dlheader:last-child {
          border-radius: 0 8px 0 0;
        }
        .dlinfo:first-child {
          border-bottom-left-radius: 8px;
        }
        .dlinfo:last-child {
          border-bottom-right-radius: 8px;
        }
        .dlheader:first-child {
          border-left: none;
        }
      `,
      //EDWrapper - estimated Delivery Wrapper
      EDWrapper: `
      .mt-16 {
        margin-top: 16px;
      }

      .mt_mb-16 {
        margin: 16px 0;
      }
      .wrapper-container {
        border-radius: 8px;
        background: ${vars.colors.primary3};
        padding:16px;
        font-family: ${vars.fonts.familySecondary};
        color:${vars.colors.primary8};
        margin-bottom:16px;
        width: 100%;

        ${vars.breakpoints.sm}{ 
          width: 100%;
        }
        ${vars.breakpoints.md}{ 
          max-width: 284px;
        }
        ${vars.breakpoints.lg}{ 
          max-width: 272px;
        }
        ${vars.breakpoints.xl}{ 
          max-width: 293px;
        }
        ${vars.breakpoints.xxl}{ 
          max-width: 402px;
          padding:24px;
        }

        p,h4 {
          margin:0;
          padding:0
        }
        
        .delivery-text {
          margin-left:12px;
          margin-bottom: 8px;
          font-family: ${vars.fonts.familySecondary};
          h4 {
            font-family: ${vars.fonts.familySecondary};
          }
          h4, p, span {
            font-size: 12px;
            font-weight: 600;
            line-height: 15.6px;
          }
          p {
            font-weight: 400;
          }

            ${vars.breakpoints.sm}{ 
              h4 {
                font-size: 16px;
                font-weight: 700;
                line-height: 22.4px;
              }

              p {
                font-size: 14px;
                line-height: 18.2px;
              }
            }
            ${vars.breakpoints.md}{ 
              h4, p {
                font-size: 12px;
                font-weight: 700;
                line-height: 15.6px;
              }
            }
            ${vars.breakpoints.lg}{ 
              h4 {
                font-size: 14px;
                font-weight: 700;
                line-height: 18.2px;
              }
              p {
                font-weight: 400;
                font-size: 14px;
                line-height: 18.2px;
              }
            }

            ${vars.breakpoints.xl}{ 
              h4 {
                font-size: 16px;
                font-weight: 700;
                line-height: 22.4px;
              }

              p {
                font-size: 14px;
                line-height: 18.2px;
              }
            }

          p {
            font-weight: 400;
            margin-top: 8px;
            //  text-align: center;
          }

          .presell-item {
            text-align: left;
            line-height:18.2px;
          }
        }

        .delivery-info {
          display:flex;
          margin-bottom:12px;
        }

        .delivery-truck {
          margin-right: 12px;
          ${vars.icons['truck']}
          :before {
            ${vars.mixins.iconBase}
            font-size: ${vars.fonts.lineHeightDefault};
          }
        }

        .info-cicle {
          margin-right: 8px;
          ${vars.icons['information-circle']}
          :before {
            ${vars.mixins.iconBase}
            font-size: ${vars.fonts.lineHeightDefault};
          }
        }

        .other-info {
          display:flex;
          border-radius: 4px;
          padding: 12px 16px;
          background: #FFFFFF;
          //  margin-top: 12px;
        }

        .align-end {
          justify-self: end;
          white-space: nowrap;
        }

        .title {
          font-family: ${vars.fonts.familyTertiary};
          font-weight: 400;

          ${vars.breakpoints.xs}{
            font-size: 32px;
            line-height: 33.6px;
          }

          ${vars.breakpoints.sm}{
            font-size: 38px;
            line-height: 39.9px;
          }

          ${vars.breakpoints.md}{
            font-family: ${vars.fonts.familyTertiary};
            font-size: 20px;
            line-height: 24px;
            font-weight: 500;
          }

          ${vars.breakpoints.xl}{
            font-family: ${vars.fonts.familyPrimary};
            font-size: 32px;
            line-height: 33.6px;
            font-weight: 400;
          }

          ${vars.breakpoints.xxl}{
            font-size: 38px;
            line-height: 39.9px;
          }
        }

        .order-detail, .save-on-order, .order-total {
          font-weight: 400;
          margin:16px 0;
          display: grid;
          grid-template-columns: 1fr 1fr;
          font-size: 16px;
          line-height: 22px;
        }

        .order-total {
          font-weight: 500;
          font-size: 20px;
          line-height: 24px;
          span {
            font-family: ${vars.fonts.familyTertiary};
          }
          
          ${vars.breakpoints.sm}{
            font-size: 22px;
            line-height: 26.4px;
          }

          ${vars.breakpoints.md}{
            font-size: 18px;
            line-height: 23.4px;
          }

          ${vars.breakpoints.lg}{
            font-size: 18px;
            line-height: 23.4px;
          }

          ${vars.breakpoints.xl}{
            font-weight: 400;
            font-size: 20px;
            line-height: 24px;
          }

          ${vars.breakpoints.xxl}{
            font-family:${vars.fonts.familyTertiary};
            font-weight: 500;
            font-size: 22px;
            line-height: 26.6px;
          }
          
        }

          .deliveryWrapper {
            display: contents;
          }

          .deliveryWrapperEmptyBasket {
            display: block;
          }
          .payment-checkout { 
            ${vars.breakpoints.sm}{
              display: grid;
              grid-template-columns: 1fr 1fr;
              grid-template-areas: "payment-options button";
              align-items: center;
            }
            ${vars.breakpoints.md}{
              display: block ;
            }
  
            span {
              display:inline;
              margin-right : 8px;
              ${vars.breakpoints.sm}{
                display:none;
              }
              ${vars.breakpoints.xl}{
                display:inline;
              }
              ${vars.breakpoints.xxl}{
                display:none;
              }
            }
        }

        button {
          grid-area: button;
          width:100%;
          font-weight: 600;
          text-transform: uppercase;
          letter-spacing: 0.02em;

          
          ${vars.breakpoints.sm}{
            width:auto;
            font-size: 12px;
            line-height: 14px;
          }

          ${vars.breakpoints.md}{
            width:100%;
          }
        }

        .empty-basket .payment-options {
          display:flex;
          justify-content: space-around;
        }

        .payment-options {
          padding: 0 24px;
          display: grid;
          grid-template-columns: repeat(6, 1fr);
          text-align: center;
          gap: 8px;
          justify-items: center;
          
          ${vars.breakpoints.sm}{ 
            grid-area: payment-options;
            padding:0;
          }

          ${vars.breakpoints.md}{ 
            display:flex;
            justify-content: center;
            padding:0 24px;
          }

          ${vars.breakpoints.xl}{ 
            display:flex;
            justify-content: center;
            padding:0 14px;
          }

          ${vars.breakpoints.xxl}{ 
            gap: 22.8px;
          }
        }

        .information-text {
          font-size: 1rem;
          font-weight: 400;
          line-height: 1.37rem;
          margin-left:8px;
        }

        .save-on-order {
          font-size: 1rem;
          font-weight: 400;
          line-height: 1.37rem;
          color: #006E1A;
        }

        .coupon-text {
          font-size: 14px;
          line-height: 18.2px;
          font-weight: 700;
          margin-left:8px;
          ${vars.breakpoints.sm}{ 
            font-size: 16px;
            line-height: 22.4px;
          }

          ${vars.breakpoints.md}{ 
            font-size: 14px;
            line-height: 18.2px;
          }

          ${vars.breakpoints.xl}{ 
            font-size: 16px;
            line-height: 22.4px;
          }
        }

        .sub {
          font-weight: 400;
          margin-top:4px;
        }
      }

      .reffered {
        font-family: ${vars.fonts.familySecondary};
        font-size: 14px;
        font-weight: 600;
        line-height: 16px;
        text-align: center;
        margin: 24px 44.5px;
        text-decoration: underline;
        display: block;
      }
      `,
      cartDetailSection: `
          .info-message {
            display: flex;
            align-items: center;
            gap: 8px;
            color: #004d86;
            margin-bottom: 8px;
          }
          .out-of-stock-error{
            color: #aa182b;
          }
          .cart-item-error{
            color: #004D86; 
          }
          .mix-match {
            color: #006e1a;
            svg path {
              fill: #006e1a;
            }
          }
        .product-link {
            font-family: ${vars.fonts.familyTertiary};
            font-size: 20px;
            font-weight: ${vars.fonts.weightMedium};
            line-height: 24px;
            letter-spacing: -0.01em;
            text-align: left;
            margin-bottom: 8px;
            display: inline-block;
            ${vars.breakpoints.sm} {
              font-size:26px;
              line-height: 31px;
            }
        }
      
        .num-bottles {
          font-size: 16px;
          line-height: 22px;
          color: ${vars.colors.grey60};
          margin-bottom: 8px;
        }

        ${EmotionBreakpoint('mobile')} {
          width: 100%;
          margin-top: 16px;
        }

        ${EmotionBreakpoint('tablet')} {
          width: 50%;
        }

        ${EmotionBreakpoint('desktop')} {
          width: 50%;
        }

      `,
      orderSummaryContainer: `
        max-width: 1396px;
        margin: auto;

        .order-summary {
          &.is-sticky {
            position: sticky;
            top: 114px;
            height: fit-content;
          }
        }

        .continue-shopping {
          margin: 14px 0;
          a {
            text-decoration: underline;
            margin-left:8px;
            text-transform: uppercase;
            font-weight: 600;
          }
        }

        .cart-head {
          padding:0 16px;
          margin-bottom:24px;
          ${vars.breakpoints.sm}{  
            margin-bottom:32px;
          }
          ${vars.breakpoints.md}{  
            text-align: center;
          }
          .heading {
            margin: 0 0 8px 0;
          }
        }

        .heading {
          font-weight: 500;
          font-size:24px;
          line-height:28.8px;
          text-transform: uppercase;
          letter-spacing: -0.02em;
          ${vars.breakpoints.xs}{  
            font-family: ${vars.fonts.familyPrimary}; 
            font-weight: 400;
            font-size:28px;
            line-height:30.8px;
          }

          ${vars.breakpoints.sm}{
            font-family: ${vars.fonts.familyTertiary};
            font-weight: 500;
            font-size:24px;
            line-height:28.8px;
          }

          ${vars.breakpoints.lg}{
            font-family: ${vars.fonts.familyPrimary};
            font-weight: 400;
            font-size:32px;
            line-height: 35.2px;
          }

          ${vars.breakpoints.xxl}{
            font-family: ${vars.fonts.familyPrimary};
            font-weight: 400;
            font-size:38px;
            line-height: 39.9px;
          }
        }

        .sub-heading {
          margin-bottom: 24px;
          font-weight:400;
          font-size:16px;
          line-height:22.4px;
        }

        
      
        .cart-area {
          display: grid;
          width: 100%;
          max-width: 1396px;
          margin: auto;
          gap: 24px;
          // justify-content: space-between;
          grid-template-rows: auto auto;

          ${EmotionBreakpoint('mobile')} {
            flex-direction: column;
          }

          ${EmotionBreakpoint('tablet')} {
            flex-direction: column;
          }

          // ${EmotionBreakpoint('desktop')} {
          //  display: grid;
          //  grid-template-columns: 70% 30%;
          // }

          ${vars.breakpoints.sm}{ 
            grid-template-rows: 1fr auto;
          }
          ${vars.breakpoints.md}{ 
            grid-template-columns: 1fr 284px;
          }
          ${vars.breakpoints.lg}{ 
            grid-template-columns: 1fr 272px;
          }
          ${vars.breakpoints.xl}{ 
            grid-template-columns: 1fr 293px;
          }
          ${vars.breakpoints.xxl}{ 
            grid-template-columns: 1fr 402px;
            gap: 24px;
          }

          
        }
        
        .container {
          display: flex;
          flex-direction: column;
          flex-wrap: wrap;
          align-content: space-around;

          ${vars.breakpoints.xs}{   
            margin-left: 16px;
            margin-right: 16px;
          }

          ${vars.breakpoints.sm}{   
            margin-left: 32px;
            margin-right: 32px;
          }

          ${vars.breakpoints.md}{   
            // display: flex;
            // flex-direction: row;
            // justify-content: center;
            margin-left: 32px;
            margin-right: 32px;
          }

          ${vars.breakpoints.lg}{   
            margin-left: 72px;
            margin-right: 72px;
          }

          ${vars.breakpoints.xl}{   
            margin-left: 72px;
            margin-right: 72px;
          }

          .product-area {
            width: 100% !important; 
            padding:0 16px;
            ${vars.breakpoints.sm}{ 
              width: 100%;
            }
            ${vars.breakpoints.md}{ 
              width: 581px;
            }
            ${vars.breakpoints.lg}{ 
              width: 848px;
            }
            ${vars.breakpoints.xl}{ 
              width: 913px;
            }
            ${vars.breakpoints.xxl}{ 
              width: 970px;
            }
          }
        }

        .empty-basket {
          text-align:center;
          padding: 16px 16px 24px 16px;
          a {
            text-transform: uppercase;
            text-decoration:underline;
            letter-spacing: 0.02em;
            ${EmotionBreakpoint('mobile')} {
              width: 100%;
            }

            ${EmotionBreakpoint('tablet')} {
              width: 100% !important; 
            }

            ${EmotionBreakpoint('desktop')} {
              
            }
          }
        }
      `,
      trustContainer: `
      .trust-container {
        padding:16px;
        text-align:center;
        font-family:  ${vars.fonts.familySecondary};
        color:${vars.colors.primary8};

        .money-back-gaurantee {
          padding: 12.6px 4.57px;
        }

        .title {
          font-family:  ${vars.fonts.familySecondary};
          font-size: 16px;
          line-height: 22.4px;
          font-weight: 700;
          text-align: center;
          margin: 12px 0 0;
          ${vars.breakpoints.sm}{
            font-size: 18px;
            line-height: 23px;
          }
        }

        .innerWrapper {
          padding:16px;
          margin-top:16px;
        }

        .reviewer {
          font-size: 12px;
          font-weight: 600;
          line-height: 17px;
          text-align: left;
          color:${vars.colors.grey60};
          text-transform: uppercase;
        }

        .description {
          margin:16px 0 ;
          font-size: 16px;
          font-weight: 400;
          line-height: 22px;
          text-align: center;
        }

        .reviewDate {
          font-size: 12px;
          font-weight: 400;
          line-height: 16px;
          letter-spacing: 0em;
          text-align: left;
          margin-left:16px
        }

        .date-rating {
          display: inline-flex;
        }
      }

      .slick-list .slick-track {
        display:flex !important;
      }
      .slick-list .slick-track .slick-slide {
        height: inherit !important
      }
      
      .slick-slider .slick-list {
        max-width: calc(100vw - 32px);
        margin: 0;
  
          ${vars.breakpoints.sm}{ 
            max-width:704px;
          }
          ${vars.breakpoints.md}{ 
            max-width:284px;
          }
          ${vars.breakpoints.lg}{ 
            max-width:272px;
          }
          ${vars.breakpoints.xl}{ 
            max-width:293px;
          }
          ${vars.breakpoints.xxl}{ 
            max-width:402px;
          }
      }
      `,
      productItemsTrending: `
        text-align: center;
        text-align: -webkit-center; //for chrome browser

        .title-overline {
          width: 64px;
          height: 3px;
          gap: 0px;
          border-radius: 100px;
          opacity: 0px;
          display: inline-block;
          background: ${vars.colors.primary1};
        }

        .auc-Recommend .auc-Recommend-title {
          font-family: ${vars.fonts.familyTertiary};
          font-size: 20px;
          font-weight: 600 !important;;
          line-height: 24px;
          margin: 16px 0;
          ${vars.breakpoints.sm}{ 
            font-family: ${vars.fonts.familyTertiary};
            font-size: 28px;
            font-weight: 500 !important;
            line-height: 33.6px;
          }
        }

        span.price-cart {
          color:${vars.colors.primary1};
          font-family:${vars.fonts.familyTertiary};
          font-size: 16px;
          font-weight: 500;
          line-height: 20.8px;
          ${vars.breakpoints.lg}{ 
            font-size: 18px;
            line-height: 23px;
          }
      }

      .product-detail-cart {
        font-size: 16px;
        font-weight: 400;
        line-height: 22.4px;
        margin:10px 0 32px 0;
        display:block;
        text-decoration:underline;
          color:${vars.colors.primary8};
        text-underline-position: under
      }

        .product-detail-cart:hover {
          text-decoration: none; /* Remove underline on hover */
        }

      .review-count {
        margin-left:8px;
        font-size: 14px;
        font-weight: 400;
        line-height: 18px;
          color:${vars.colors.primary8};
      }

      .product-rating {
        display: inline-flex;
        margin-bottom:16px;
        align-items: center;
        .star-icons {
          display:flex;
          gap:4px;
        }
      }

      .product-title {
          font-family: ${vars.fonts.familyTertiary};
          font-weight: ${vars.fonts.weightMedium};
          font-size: 16px;
          line-height: 20.8px;
          display: block;
          overflow: hidden;
          height: auto;
          margin: 24px 16px 12px 16px;
          :hover {
            text-decoration:none;
          }

          ${vars.breakpoints.sm}{
            height: auto;
            min-width: 90%;
          }
          ${vars.breakpoints.lg}{ 
            font-size: 18px;
            line-height: 23.4px;
            height: 48px;
          }
        }

        .slick-slider {
          margin-bottom: 0px;
        }

        .slick-slider .slick-arrow.slick-prev {
          z-index:500;
        }

        .slick-slider .slick-arrow{
          margin: 20px;
          border: 1px solid ${vars.colors.secondary9};
          border-radius: 50%;
          width: 44px;
          height: 44px;
          i {
          font-size: 16px;
          }
        }

        .info-overlay {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background: white;
          color: #000000;
          display: flex;
          justify-content: center;
          align-items: center;
          opacity: 0;
          transition: opacity 0.3s ease;
          display: flex;
          flex-direction: column;
        }

        .product-image {
          position: relative;
          img {
            height: 100%;
          }
        }
        
        .overlay-info-container {
          display: inline-flex;
          align-items:center;
          gap: 8px;
          margin-bottom: 16px;
        }

        .overlay-text {
          margin:0;
          font-size:14px;
          line-height:18.2px;
          font-weight:400px;
        }

        .overlay-text strong {
          font-weight:700px !important;
        }

        .overlay-info-container p {
          margin-bottom:0;
        }

        .product-image:hover .info-overlay {
          opacity: 1;
        }

        .product-price {
          background: none;
        }
      `,
      Pill: `
        padding: 2px 12px;
        background: #CF004F;
        border-radius: 16px;
        color: #fff;
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
        letter-spacing: 0em;
        text-align: center;
        display: inline-flex;
        align-items: center;
        margin-top: 12px;
        span {
          font-size: 16px;
          font-weight: 500;
          line-height: 20.8px;
        }
        &.savings-pill {
          background: #C3E9CF;
          color: #006E1A;
          margin-top: 0;
          font-weight: 700;
          padding: 6px 8px;
          text-transform: uppercase;
        }
      `,
      MiniCart: `
          z-index: 16777216;
          position:relative;

        .mantine-Modal-inner {
          padding:0;
          justify-content: end;
          bottom:0;
        }

        .mantine-Modal-content {
          min-height: 100vh;
          min-width:100vw;
          ${vars.breakpoints.md}{ 
            max-width:482px;
            min-width:auto;
          }
          .mantine-Modal-header {
            padding:22px 0;
            padding: 16px 32px ;
            border-bottom:1px solid #BCBCBC;
            ${vars.breakpoints.md}{ 
              padding: 16px 32px;
            }
          }
          .mantine-Modal-close {
            outline: 0;
            color: ${vars.colors.primary8};
            width: auto;
            svg {
              width: 32px !important;
              height: 32px !important;
            }
          }

          .mini-cart-title {
            font-size:20px;
            line-height:24px;
            font-weight:500;
            color: ${vars.colors.primary8};
            font-family: ${vars.fonts.familyTertiary};
            margin:18px 0;
            ${vars.breakpoints.md} {
              font-size:18px;
              line-height:23.5px;
            }
          }
        }

        .mantine-Modal-title { 
          margin-bottom:0 !important;
        }
        .mantine-Modal-body {
          padding:0;
          padding: 0 16px 32px 16px;
          ${vars.breakpoints.md}{ 
            padding: 0 32px 32px 32px;
          }
        }

        .mini-cart-header {
          display: flex;
          -webkit-box-align: center;
          align-items: center;
          gap: 8px;
          font-family: ${vars.fonts.familyPrimary};
          font-size: 32px;
          font-weight: ${vars.fonts.weightNormal};
          line-height: 35.2px;
          color:#222;
          i {
            color:rgb(0, 110, 26);
          }
          .mini-cart-header-text{
            font-family: ${vars.fonts.familyPrimary};
            font-size: 28px;
            line-height: 30.8px;
            letter-spacing: -0.02em;
            margin-block: 0;
            ${vars.breakpoints.sm}{ 
              line-height: 35.2px;
              font-size: 32px;
            }
          }
        }

        .wrapper {
          display:flex;
          margin:24px 0;
          gap:16px;
          h2 {
            margin:0;
          }
        }

        .wrapper .product-image, .wrapper .product-image img {
          width:164px;
          height:165px;
          margin: auto 0;
          ${vars.breakpoints.sm}{ 
            width:254px;
            height:254px;
          }
          ${vars.breakpoints.md}{ 
            width:216px;
            height:216px;
          }
        }

        .product-details-container {
          margin: auto 0;
        }

        .product-great-pick{
          color: ${vars.colors.grey60};
          font-family: ${vars.fonts.familyTertiary};
          font-weight: ${vars.fonts.weightMedium};
          margin-top: 8px !important;
          font-size: 20px;
          line-height: 24px;
          text-align:left;
          ${vars.breakpoints.sm}{ 
            font-size: 22px;
            line-height: 26.4px;
          }
        }

        p.product-great-pick {
          margin-bottom: 8px;
        }

        .product-detail-text {
          font-family: ${vars.fonts.familyTertiary};
          font-weight: ${vars.fonts.weightMedium};
          font-size: 20px;
          font-weight: 500;
          line-height: 24px;
          text-align: left;
          margin-bottom: 8px;
          ${vars.breakpoints.sm}{
            font-size: 24px;
            line-height: 28.8px;
          }
          ${vars.breakpoints.md}{ 
            font-size: 22px;
            line-height: 26.4px;
          }
          .checkout-item-qty {
            color:#006E1A;
            font-family: ${vars.fonts.familyTertiary};
          }
        }

        .price-section {
          display:flex;
          gap:4px;
          align-items: center;
          font-weight: ${vars.fonts.weightMedium};
          font-family: ${vars.fonts.familyTertiary};
          padding: 0 0 8px 0;
          margin-bottom: 0px;
        }

        .price-section .price {
          font-size: 20px;
          line-height: 24px;
          font-weight: ${vars.fonts.weightMedium};
          ${vars.breakpoints.md}{
            font-size: 22px;
            line-height: 26.4px;
          }
        }

        .price-section p {
          margin: 0;
          font-family: ${vars.fonts.familyTertiary};
        } 

        .price-section .strike {
          color:${vars.colors.grey60};
          text-decoration-line: line-through;
          font-size: 16px;
          line-height: 22.4px;
          ${vars.breakpoints.sm}{
            font-size: 18px;
            line-height: 25.2px;
          }
        }
        
        .price-section .strike+.sale-price {
          color: ${vars.colors.primary1}
        }
        
        .price-section .strike+.member-price {
          color:${vars.colors.secondary4};
        }
        
        .member-savings {
          font-size: 14px;
          font-weight: ${vars.fonts.weightMedium};
          line-height: 16px;
          color: ${vars.colors.secondary4};
          letter-spacing: 0.02em;
          margin: 0px;
        }

        .case-details {
          font-size: 20px;
          font-weight: 500;
          line-height: 24px;
          color: #20513B;
        }

        .product-link {
          margin-bottom : 8px;
          font-family: ${vars.fonts.familyTertiary};
          font-size: 20px;
          font-weight: 500;
          line-height: 24px;
          color:${vars.colors.primary8};
          ${vars.breakpoints.sm}{
            font-size: 24px;
            line-height: 28.8px;
          }
          ${vars.breakpoints.md}{ 
            font-size: 22px;
            line-height: 26.4px;
          }
        }

        .num-bottles {
          font-size: 16px;
          font-weight: 400;
          line-height: 22.4px;
          text-align: left;
          margin-bottom: 8px;
          color:${vars.colors.grey60};
        }

        .light-grey {
          color: ${vars.colors.grey60};
        }

        .gift-tablet {
        display: none;
          ${vars.breakpoints.sm}{ 
            display: flex;
            align-items: center;
            gap: 8px;
            padding: 12px;
          }
          ${vars.breakpoints.md}{ 
            display: none;
          }
        }

        .gift-other-device {
          display: flex;
          gap: 8px;
          align-items: center;
          padding: 12px;
          ${vars.breakpoints.sm}{ 
            display:none;
          }
          ${vars.breakpoints.md}{ 
            display: flex;
            gap: 8px;
            align-items: center;
            padding: 12px;
          }
        }

        .gift-other-device h4, .gift-tablet h4 {
          margin-bottom:0px;
          text-transform: none;
        }

        h4 {
          font-size: 16px;
          font-weight: 400;
          margin:0;
          line-height: 22.4px;
          font-family: ${vars.fonts.familySecondary};
          color:${vars.colors.primary8};
          .cart-link {
            font-weight: 500;
            line-height: 19.2px;
            text-decoration:underline;
            margin-left: 8px;
            float:none;
            font-size: 16px;
          }

          .cart-link:hover, a:hover {
            text-decoration: none;
          }
        }

        .button-container {
          display:flex;
          gap:16px;
          flex-direction: column;
          padding-top: 24px;
          ${vars.breakpoints.sm}{ 
            flex-direction: row;
            margin-bottom:32px;
          }
          ${vars.breakpoints.md}{ 
            flex-direction: column;
          }
          a, button {
            width:100%;
          }

          button {
            font-weight:600;
            cursor:pointer;
            letter-spacing: 0.02em;
          }
        }

        .continue-shopping {
          display: grid;
          margin-bottom: 24px;
          justify-content: center;
        }
        
        .continue-shopping-text {
          text-decoration: underline;
          letter-spacing: 0.02em;
          line-height:16px;
          margin-left: 8px;
          text-transform: uppercase;
          font-weight: 600;
          margin: 12px;
          transition: text-decoration 0.3s ease; /* Smooth transition for text-decoration */
          cursor: pointer;
        }
        .continue-shopping .continue-shopping-text:hover {
          text-decoration: none; /* Remove underline on hover */
        }

        .tax{
          text-align: center;
          & .tax-info{
            display: inline-block;
            color: ${vars.colors.grey60};
            font-family: ${vars.fonts.familySecondary};  
            font-size: 12px;
            font-weight: ${vars.fonts.weightNormal};
            line-height: 15.6px;
            text-align: center;
            text-transform: none;
          }
        }

        .desktop {
          display:none;
          ${vars.breakpoints.sm}{ 
            display:grid;
          }
        }

        .mobile {
          display:grid;
          margin: 16px 0;
          ${vars.breakpoints.sm}{ 
            display:none;
          }
        }

        @keyframes slideInFromRight {
          from {
            transform: translateX(100%);
          }
          to {
            transform: translateX(0);
          }
        }
        @keyframes slideOutToRight {
          from {
            transform: translateX(0);
          }
          to {
            transform: translateX(100%);
          }
        }
      `,
      CartItemWrapper: `
        display: flex;
        margin-bottom: 16px;
        border-bottom: 1px dashed #bcbcbc;
        padding-bottom: 16px;
        min-height: 252px;
        gap: 24px;
        position: relative;

        .product-image {
          align-self: center;
          text-align: center;
          @media screen and (max-width: 575px) {
            img {
              max-width: 90px;
            }
          }
        }
        .vpp-price {
          font-size: 14px;
          font-weight: 400;
          line-height: 18.2px;
          text-align: center;
          color: #222222;
        }

        .product-details {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          width: 100%;
          margin: 0 0 0 8px;
          cursor: inherit;
          flex: 1;
        }

        .product-delete {
          display: flex;
          flex-direction: row;
          justify-content: left;
          align-items: center;
          width: 100%;
          margin: 24px 0;
          cursor: pointer;
          max-width: max-content;
          &-text {
            text-align: center;
            text-decoration: underline;
            text-transform: uppercase;
            margin-left: 4px;
          }
        }
      `,
      cartError: `
        display: flex;
        align-items: center;
        gap: 8px;
        color: #222222;
        margin-bottom: 24px;
        background: ${vars.colors.primary7};
        padding: 12px;
        border-radius: 4px;
      `,
      CartCounter: `
        .btn {
          background: none;
          border: none;
          padding: 0;
          min-width: 20px;
          .disabled {
            color: #cccccc;
          }
        }

        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }
        input[type='number'] {
          -moz-appearance: textfield;
        }

        input {
          font-size: 16px;
          max-width: 40px;
          min-width: 40px;
          text-align: center;
          border: 0;
          margin: 0;
          padding: 0;
          outline: 0;
        }
      `,
      abandonModalOverlayWrapper: `
      .mantine-Modal-overlay {
        z-index: 500 !important;
      }

      .mantine-Modal-inner {
        z-index: 500 !important;
      }
    `,
      abandonModalOverlay: `
        .mantine-Modal {
          &-content {
            display: flex;
            flex-direction: column;
            gap: 32px;
            color: ${vars.colors.primary8};
            padding-bottom: 0 !important;
            font-family: ${vars.fonts.familySecondary};
            font-size: 16px;
            padding: 22px 0;
          }
        }
        .modal-action {
          min-width: 324px;
          font-weight: 600;
          align-self: end;
          cursor: pointer;
          ${EmotionBreakpoint('mobile')} {
            width: 100%;
          }
        }
      `,
    },
    WinePlanCarousel: {
      controls: `
        width: 100%;
        margin-bottom: 10px;
        top: calc(50% - 3rem);
      `,
      control: `
        height: 36px;
        width: 36px;
        border-radius: 0;
        border: 1px solid ${vars.colors.grey50};
        svg {
          color: ${vars.colors.grey50};
          width: 34px !important;
          height: 34px !important;
        }
      `,
      viewport: `
        padding-bottom: 24px;
      `,
      indicators: `
        bottom: 5px;
      `,
      indicator: `
        border-radius: 50%;
        width: 12px;
        height: 12px;
        background: #BCBCBC;
        opacity: 1;
        &[data-active] {
          background: ${vars.colors.grey50};
        }
      `,
    },
    Membership: {
      wrapper: `
      & .accordion-navigation{
          width:200px;
          span {
            padding: 0;
          }
        & .accordion-panel {
          background-color: #FCFBF9;
          margin-top: 10px;
          padding: 10px 2px 2px 20px;
        }
        & .mantine-Accordion-item{
            width: 100%;
            border:none;
            margin-bottom: 10px;
            font-size:14px;
            color: #333333;
            margin-left: 4px;
            margin-top: 4px;
            margin-bottom: 10px;
            text-wrap: nowrap;
            gap: 10px;
          & button{
            padding: 0;
            display: flex;
            justify-content: space-between;
            width: 100%;
          }
        }
      }
      margin-left: 0;
      margin-right: 0; 
      background: ${vars.colors.white};
      display:flex;
        .membership-layout {
          width:100%;
          padding: 10px;
          margin-left:0px;
          ${vars.breakpoints.xs} {
            padding:20px;
          }
          
        .error-message{
          padding: 0 25px 15px 0;
        }
        .init-loader{
          display:flex;
          margin-left: 45%;
          margin-top: 20%;
        }
        .loader{
          position: fixed;
          left: 0;
          top: 0;
          height: 100%;
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          z-index: 9999;
          &.full-page-loader{
            background-color: rgb(0 0 0 / 36%);
          }
        }
        .card-container {
          display:flex;
          gap: 24px;
          flex-wrap: wrap;
          flex-direction: row;


          .cancel-membership {
            width: 100%;
            display:flex;
            font-size:14px;
            font-weight: ${vars.fonts.weightNormal};
            margin-bottom:0;
            align-items:center;
            .cancel-membership-checkbox{
              appearance: none;

              width: 19px;
              height: 19px;
              box-shadow: none;
              border-radius: 4px;
              border: 1px solid;
              color: ${vars.colors.black};
              
              margin-right:10px;
              cursor:pointer;
              &:checked{
                background-image: url("data:image/svg+xml,%3Csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg id='Checkbox'%3E%3Crect width='18' height='18' fill='%23117B53'/%3E%3Cpath id='Vector' d='M14 5L7.125 12L4 8.81818' stroke='%23F8F5EF' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/g%3E%3C/svg%3E%0A");
                color: transparent;
              }
              &.disable{
                pointer-events: none;
                cursor: not-allowed;
              }
            }
            .cancel-membership-text{
              width:100%;
            }
          }

          .update-visible {
            display: block;
          }

          .update-hidden {
            display: none;
          }


          .membership-card {
            width:420px;
            height:260px;
            background:white;
            padding:24px;
            display:flex;
            flex-direction:column;
            border: 1px solid ${vars.colors.secondary9};
            border-radius: 4px;
            h2{
                  margin-top: 0;
                }
            h3{
              font-family: ${vars.fonts.familySecondary};
              text-transform: none;
            }
            .card-heading{
              font-size: 22px;
              ${vars.breakpoints.lg} {
                font-size:28px;
              }
              font-family: ${vars.fonts.familyTertiary};
              font-weight: ${vars.fonts.weightNormal};
              
            }

            .cancel-save-offer-cta {
              text-decoration:underline;
              margin: auto;
            }
            
            .card-details{
              display:flex;
              align-items:center;
              .card-details-number{
                padding-left:10px;
              }

            }
            .membership-status{
            font-size:40px;
            font-family: ${vars.fonts.familyPrimary};
            color:${vars.colors.primary1};
            text-transform:uppercase;
            line-height: 52px;
            margin:auto;
            
            }
            .change-payment-card{
              display:flex;
              font-size:16px;
              font-weight: ${vars.fonts.weightNormal};
              margin:auto;
              margin-bottom:0;
              align-items:center;
              
            }
            .cancel-membership.disable{
              cursor: not-allowed;
            }

            .enroll-data{
              display:grid;
              grid-template-columns: 0.7fr 1fr;

              h3{
                font-family: ${vars.fonts.familySecondary};
                font-weight:${vars.fonts.weightNormal};
                font-size:16px;
                line-height: 24px;
              }
              .date{
                font-weight: ${vars.fonts.weightBold};
                padding-left: 10px;
              }
            }
            
            .last-payment-row{
              display:grid;
              grid-template-columns: 0.7fr 1fr;
            
              h3{
                font-family: ${vars.fonts.familySecondary};
                font-weight:${vars.fonts.weightNormal};
                font-size:16px;
                line-height: 24px;
              }
              .payment-date{
              font-size:16px;
              font-weight:${vars.fonts.weightBold};
              padding-left: 10px;
              .loaderContainer{
                justify-content: start;
                div{
                  width: 21px;
                  height: 21px;
                }
              }
              }
            }
            
          }
          .saving{
            font-family: ${vars.fonts.familyTertiary};
            align-items:center;
            text-align: center;
            .saving-price{
              padding:20px; 
              display: grid;
                
            
            .card-heading{
              margin:0;
              font-family: ${vars.fonts.familyPrimary};
              font-weight: ${vars.fonts.weightNormal};
              font-size: 50px;
              ${vars.breakpoints.md} {
                font-size: 44px;
                }
              ${vars.breakpoints.lg} {
              font-size: 60px;
              }
              color:${vars.colors.primary1};
              }
              .year-note{
                font-size: 18px;
                font-family: ${vars.fonts.familyTertiary};
              }
              color: ${vars.colors.grey60};
            }
            .static-data{
              font-family: ${vars.fonts.familyTertiary};
            
              font-weight: ${vars.fonts.weightMedium};
              font-size: 18px;
              color: ${vars.colors.grey60};
              padding: 20px 0px;
            }
            .static-note{
              display: grid;
              font-family: ${vars.fonts.familySecondary};
              font-weight: ${vars.fonts.weightNormal};
              font-size: ${vars.fonts.sizeLarge};
              color: ${vars.colors.black};
              margin: auto;
              margin-bottom: 20px;
              font-weight: ${vars.fonts.weightNormal};
              font-size: ${vars.fonts.sizeLarge};
              line-height: 22.4px;
            }
          }
          .faq-card{
            width:100%;
            ${vars.breakpoints.xl} {
              width:854px;
            }
            height:auto;
            display:flex;
            flex-direction: row;
            .left-faq{
              width: 40%;
              .view-all-faq{
                font-size:16px;
                margin-top: 10%;
                ${vars.breakpoints.xs} {
                  padding:2px 5px;
                  font-size:14px;
                }
                ${vars.breakpoints.sm} {
                  padding:10px;
                }
                ${vars.breakpoints.md} {
                  padding: 7.5px 22px;
                }
              
              }
            }
          .right-faq{
            width: 60%;
          }
            .faq-question{
              margin-bottom:24px;
              text-decoration:underline;
              cursor:pointer;
            &.active{
              font-weight: ${vars.fonts.weightBold};
            }
            }
            .faq-question-right.heading{
              margin-top:12%;
              margin-bottom:24px;
              font-weight: ${vars.fonts.weightBold};
            }
            .vertical-line {
              width: 1px;
              background-color: ${vars.colors.grey20}; /* Adjust the color as needed */
              margin: 5% 16px 10% 16px;
            }
          
          }
          // footer banner
        .footer-banner{
          width: 100%;
          & div{
            margin:0;
          }
          & .description{
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
          }
          & .banner-button{
            bottom: 0;
            margin-bottom: 18px;
            position:absolute;
          }
        }
        }
      }
      .bread-crumb-list{
        display:flex;
        & a{
          font-size:11px;
          ${vars.breakpoints.sm} {
            font-size:16px;
          }
        }
        .slash{
          padding:12px;
        }
      }
      .title-icon{
        display:flex;
        justify-content:center;
      }
      .hub-page-no-plan{
        width:80%;
        h1{
          margin-left:10px;
        }
        h1{
          font-size: 40px;
        }
        .no-plan-message{
          background:${vars.colors.specialLight};;
          padding: 10px;  
          margin:10px;
        }
    }
      `,
      Sidebar: `
      background:white;
      padding-left: 5%;
      display:none;
      ${vars.breakpoints.md} {
      display:flex;
      }
      .membership-sidebar{
        background: white;
        padding-left:40px;
        height:100%;
      
  
      
        & div{
          margin:0;
          padding:0;
        }

      & .navigation-link{
        ${vars.breakpoints.xs} {
              width:260px;
        }
        > h4{
          font-size:28px;
          font-family: ${vars.fonts.familyTertiary};
            // margin-bottom: 24px;
            color:${vars.colors.primary1};
            margin-bottom: ${vars.space.lg};
            margin-top: ${vars.space.lg};
        }
        & a{
          font-size:18px;
          margin-bottom:18px;
          line-height:24px;
        }
      }
      }`,
      pageWrapper: `
        .top-section .widget-container > div {
          margin: 0;
        }
        .bottom-section .widget-cms-column > div {
          margin: 0;
          & .banner-info {
            padding: 32px;
          }
          & .banner-imgcomp {
            width: 100%;
            height: 100%;
          }
          & .banner-img {
            position: absolute;
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
          & .banner-button a {
            border-color: ${vars.colors.btnsPrimaryColor};
            background: none;
            color: ${vars.colors.btnsPrimaryColor};
          }
        }
      `,
    },
    MembershipRebranding: {
      container: `
        & .no-plan-message{
          margin:40px 0;
          & p {
            font-family: ${vars.fonts.roboto};
            font-weight: ${vars.fonts.weightBold};
          }
        }
      `,
      layout: `
        margin-top: 40px;
        & .membership-details{
          display: flex;
          justify-content: center;
          gap: 40px;
          & > div{
            flex: 1;
          }
        }
        & .renew-layout {
            margin-bottom: 40px;
            .cancel-membership {
            width: 100%;
            display:flex;
            font-size:14px;
            font-weight: ${vars.fonts.weightNormal};
            margin-bottom:0;
            align-items:center;
            .cancel-membership-checkbox{
              appearance: none;
              width: 19px;
              height: 19px;
              box-shadow: none;
              border-radius: 4px;
              border: 1px solid;
              color: ${vars.colors.black};       
              margin-right:10px;
              cursor:pointer;
              &:checked{
                background-image: url("data:image/svg+xml,%3Csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg id='Checkbox'%3E%3Crect width='18' height='18' fill='%23117B53'/%3E%3Cpath id='Vector' d='M14 5L7.125 12L4 8.81818' stroke='%23F8F5EF' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/g%3E%3C/svg%3E%0A");
                color: transparent;
              }
              &.disable{
                pointer-events: none;
                cursor: not-allowed;
              }
            }
            .cancel-membership-text{
              width:100%;
              font-family: ${vars.fonts.roboto};
              font-weight: ${vars.fonts.weightNormal};
              font-size: ${vars.fonts.sizeH5};
              color: ${vars.colors.primary8};
            }
          }  
          .update-visible {
            display: block;
          }

          .update-hidden {
            display: none;
          }     
        }
      `,
      membershipStatus: `
        border: 1px solid #616161;
        padding: 24px;
        h1.membership-status {
          font-family: ${vars.fonts.oswald};
          font-weight: ${vars.fonts.weightMedium};
          color: ${vars.colors.textUnlimitedBg};
        }
        .year-note {
          font-family: ${vars.fonts.oswald};
          font-weight: ${vars.fonts.weightMedium};
          font-size: ${vars.fonts.sizeLarge};
          padding-left: 8px;
          color: ${vars.colors.textUnlimitedBg};
          position: relative;
          top: -3px;
        }
        .saving-instruction {
          font-family: ${vars.fonts.roboto};
          padding-top: 10px;
          font-weight: ${vars.fonts.weightNormal};
          font-size: ${vars.fonts.sizeLarge};
        }
        .saving-note {
          font-family: ${vars.fonts.roboto};
          font-weight: ${vars.fonts.weightNormal};
          font-size: ${vars.fonts.sizeH5};
          color: #616161;
          margin-top: 12px;
        }
        .price-section {
          display: flex;
          align-items: baseline;
        }
        .membership-status-title {
          font-family: ${vars.fonts.noto};
          font-weight: 600;
        }
        .membership-subscription-title {
          font-family: ${vars.fonts.roboto};
          font-weight: 700;
          font-size: ${vars.fonts.sizeLarge};
          padding-top: 10px;
        }
        .enroll-info {
          margin-top: 12px;
          font-family: ${vars.fonts.roboto};
          font-weight: ${vars.fonts.weightBold};
          font-size: ${vars.fonts.sizeLarge};
          & .date {
            font-weight: ${vars.fonts.weightNormal};
            font-size: ${vars.fonts.sizeLarge};
            padding-right: 8px;
          }
        }
      `,
      payment: `
        margin-top: 40px;
        .payment-title{
          font-family: ${vars.fonts.noto};
          font-weight: 600;
        }
        .last-payment-row{
          display: flex;
          font-family: ${vars.fonts.roboto};
          font-size: ${vars.fonts.sizeLarge};
          color:${vars.colors.primary8};
        }
        .last-payment-title{         
          font-weight: ${vars.fonts.weightNormal};
          padding-right: 4px;
        }
        .payment-date{
          font-weight: ${vars.fonts.weightBold};
          .loaderContainer div {
            width: 20px;
            height: 20px;
            border-width: 2px;
          }
        }
      `,
      paymentMethod: `
        margin: 24px 0;
        .single-frequency-text {
          font-family: ${vars.fonts.roboto};
          font-size: ${vars.fonts.sizeH4};
        }
        .accordion-content {
          display: flex;
          align-items: center;
          justify-content: space-between;
        }
        .card-details {
          display: flex;
          align-items: center;
          gap: 16px;
        }
        .card-number {
          font-weight: ${vars.fonts.weightNormal};
          font-size: ${vars.fonts.sizeLarge};
          color:${vars.colors.primary8};
        }
        .change-payment-card a {
          font-weight: 600;
          font-size: ${vars.fonts.sizeH5};
          color:${vars.colors.primary8};
        }
      `,
    },
    MyAccount: {
      wrapper: `
        display: grid;
        ${vars.breakpoints.md} {
          grid-template-columns:380px 1fr;
        }
      `,
      sideBar: `
      display:none;
      ${vars.breakpoints.md} {
        display:grid;
      }
      padding: 50px 10px 30px 45px;
        & div{
          margin:0;
          padding:0;
        }
        & .navigation-link{
          ${vars.breakpoints.xs} {
              width:260px;
          }
          a{
            font-size: ${vars.fonts.sizeLarge}; 
            margin-bottom: 18px;
            font-family: ${vars.fonts.familySecondary};
            line-height: 22.4px;
            color: ${vars.colors.grey50};

          }
          h4{
            font-size:30px;
            color:${vars.colors.primary1};
            font-family: ${vars.fonts.familyTertiary};
            margin: 20px 0px;
          }
        }
        & .myaccount-sidebar{
          padding:10px;
        
        }
      `,
      winePlan: `
        display: grid;
          .case-details{
            display: grid;
            margin-top:${vars.space.xl2};
            gap:${vars.space.xl};
            
            ${vars.breakpoints.xs} {
              grid-template-columns:0.4fr 1fr;
            }
            ${vars.breakpoints.sm} {
            grid-template-columns:0.8fr 1fr;
            }
            
              .case-image{
                display: grid;
                justify-content:end;
                & div{
                position:relative;
                width:90px;
                height:82px;
                  
                ${vars.breakpoints.sm} {
                  width:164px;
                  height:146px;
                }
                ${vars.breakpoints.md} {
                  width:146px;
                  height:130px;
                }
                ${vars.breakpoints.lg} {
                  width:174px;
                  height:156px;
                }
                ${vars.breakpoints.xl} {
                  width:190px;
                  height:170px;
                }
                ${vars.breakpoints.xxl} {
                  width:260px;
                  height:233px;
                }
                }
              }
              .case-info{
              // margin-top:${vars.space.xl2};
              display: flex;
              flex-direction: column;
              align-items: start;
              justify-content: center;
                .scheme-name{
                  font-size:24px;
                  font-weight: 600;
                  ${vars.breakpoints.sm} {
                    font-size:30px;
                  
                    }
                  ${vars.breakpoints.xxl} {
                    font-size:34px;
                    text-transform: uppercase;
                  }
                  
                  
                  font-family: ${vars.fonts.familyPrimary};
                  line-height: 35.7px;


                }
                .case-price-info{
                  font-size: 18px;
                  font-weight: ${vars.fonts.weightBold};
                  ${vars.breakpoints.sm} {
                    font-size: 22px;
                    font-weight: 600;
                  }
                  
                  display:flex;
                  flex-direction: column;
                  ${vars.breakpoints.sm} {
                  align-items:center;
                  flex-direction: row;
                  margin-top:${vars.space.xs};
                  }
                  padding:10px 0px;
                  .case-price{
                  line-height: 26.4px;
                  }
                  .case-saving-price{
                    margin-top:${vars.space.xs};
                    ${vars.breakpoints.sm} {
                      margin-top:0;
                      margin-left:${vars.space.xs};
                      }
                    
                    color: ${vars.colors.primary1};
                    background: ${vars.colors.primary5};
                    border-radius:16px;
                    padding:8px;
                    width: fit-content;
                    font-size: 12px;

                  }
                }
                .case-standard-price{
                  color: ${vars.colors.grey60};
                  padding-bottom:13px;
                  font-size: 16px;

                }
                .case-bottle-count{
                  font-size: 16px;
                  color: ${vars.colors.grey50};
                  font-weight: ${vars.fonts.weightNormal};

                }
                .case-not-select-container{
                  display: flex;
              flex-direction: column;
              align-items: start;
              .coming-soon-heading {
                color: ${vars.colors.grey50};
                margin: 0;
                padding:13px 0px;
              }
              .case-not-select-notes{
                font-size: ${vars.fonts.sizeH4};
                
              }
              .take-case{
                width:190px;
                margin:13px 0px;
                font-size: 14px;
                font-weight: 600;
                border-radius: 4px;
              }
                }
              }

          }
          .bottles-details-wrapper{
          display:grid;
          margin-top: ${vars.space.xl};
            .header{
              font-size: 18px;
              font-weight: 600;
              font-family: ${vars.fonts.familyTertiary};

            }
          .product-row{
            display:flex;
            align-items: center;
            .arrow-slide {
              background:white;
              border-radius:15px;
              cursor: pointer;
              ${vars.breakpoints.xs} {
              position: absolute;
              z-index: 1;
              &.right{
                right:25px;
                }
              }
              ${vars.breakpoints.sm}{
                position: relative;
                &.right{
                margin:auto;
                margin-right: 0px;
                }
              }
            }
            
            .bottles-details {
              
              display: grid;
            
              .bottle-container {
                display: grid;
                grid-auto-flow: column; /* Arrange items in a single row */
                grid-auto-columns: minmax(293.33px, 1fr); /* Define minimum and maximum column sizes */
                overflow-x: auto; /* Enable horizontal scrolling */
                white-space: nowrap; /* Prevent line breaks */
                gap: 10px; /*space between grid items */
                padding: 20px;
                scrollbar-width: none;
                

                  .product-card {
                    display: flex;
                    position: relative;
                    flex-direction: column;
                    align-items: center;
                    padding: 15px;
                    white-space: normal;
                      &.gift-card{
                        border: 1px solid ${vars.colors.secondary9};
                        border-radius: 4px;
                        background: none;
                          & .gift-tag{
                            justify-content: start;
                            position: absolute;
                            display:flex;
                            gap:7px;
                            background-color: ${vars.colors.grey00};
                            padding:10px;
                            border-radius: 4px;
                            font-weight:700;
                            font-size:16px;
                            z-index:1;
                          }
                        }
                    
                    
                  
                    .product-details{
                      margin: auto;
                      margin-bottom: 0;
                      .product-name{
                      font-size: 18px;
                      font-weight: 600;
                      margin: 18px 0 15px 0;
                      font-family: ${vars.fonts.familyTertiary};
                      margin-top: 10px;
                      &.single-line{
                        max-height: 3.5em;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-line-clamp: 2;
                        -webkit-box-orient: vertical;
                        //margin-top: -8.1px;
                      }
                      &:not(.single-line){
                        margin-top: -8.1px;
                      }
                      }        
                      .product-count{
                        color: ${vars.colors.grey60};
                        font-size: 16px;
                      font-weight: 400;
                      padding-bottom: 15px;
                      align-items: center;
                      display: flex;

                      }
                      .product-label{
                        display:none;
                        ${vars.breakpoints.sm} {
                          display:block;
                          }
                        color: ${vars.colors.grey50};
                        font-size: 16px;
                        font-weight: 400;
                        padding-top: 10px;
                        padding-bottom: 2px;
                        width: fit-content;
                        box-shadow: inset 0 -1px 0 0 black;
                        cursor:pointer;

                      }
                      &.non-wine-gift{
                        .product-label,.product-count,.wine-rating{
                          visibility: hidden;
                        }

                      }
                    }
                      .product-image-container{
                        margin-bottom: 13px;
                        .label-card {
                          display: none;
                          position: absolute;
                          top: 0;
                          left: 0;
                          width: 100%;
                          background: white;
                          color: black;
                          padding: 10px;
                          box-sizing: border-box;
                          z-index: 1;
                          height: 200px;
                          flex-direction: column;
                          justify-content:center;
                          align-items: start;
                          
                          ${vars.breakpoints.lg} {
                            height: 240px;
                          }
                          .label-row{
                            margin-left: 20%;
                            i{
                              padding:5px;
                            }
                            display: grid;
                            grid-template-columns: auto 1fr;
                            align-items: center;
                            padding:8px;
                          }
                        }
                        &:hover .label-card {
                          display: flex;
                        }
                      .image-container{
                        display: flex;
                        justify-content: center;
                          .image-size {
                          position: relative;
                          width:48px;
                          height: 180px;
                          ${vars.breakpoints.lg} {
                            width:120px;
                            height: 220px;
                          }
                          }
                          &.gift-image{
                            .image-size{
                              width:220px;
                            }
                          }
                        
                        }
                      }
                  
                }
              }
            }
          }     
          }
          .error-message{
            margin-top:16px;
            padding:18px;
            background-color:${vars.colors.errorLight};
            color:${vars.colors.error};
          }
          & .subscription-option{
            h6{
            font-size:26px;
            }
          .change-colour-case{
             .subscription-option-accordion{
            border-bottom: 1px solid ${vars.colors.secondary9};
            }
            margin-bottom: 50px;
            }
          }
      `,
      accordion: `
      .subscription-option-accordion{
        border-top: 1px solid ${vars.colors.secondary9};
        // border-bottom: 1px solid ${vars.colors.secondary9};
        // margin-bottom: 20px;
        transform: rotate(0deg);
        &.single-frequency{
          & .mantine-Accordion-chevron {
            visibility: hidden;
            }
          }
            & .accordion-heading{
              display:flex;
              
              align-items: center;
              gap: 10px;
            .accordion-heading-text{
              font-size:16px;
              font-weight: ${vars.fonts.weightBold};
              font-family: ${vars.fonts.familySecondary};
            }
            .single-frequency-text{
              margin-left: 10px;
              color:${vars.colors.lwDrGray};
            }
          }
          & .option-heading{
            margin-top:10px;
            margin-bottom:20px;
            font-size:16px;
            font-weight: ${vars.fonts.weightNormal};

          }
             & .choosing-weeks{
               // display:flex;
               display: grid;
               grid-auto-flow: row;
               grid-auto-columns: minmax(150px, 1fr); /* Define minimum and maximum column sizes */
               gap:20px;
               align-items: unset;
              ${vars.breakpoints.md} {
                grid-template-columns: repeat(3, 1fr);
                &.choosing-frequency{
                  width:80%;
                }
                // grid-auto-flow: column;
               }
              .weeks-chip {
                  //padding: 20px !important;
                  border-radius: 0;
                  width: auto;
                  height: auto;
                  padding: 10px;
                  border: 1px solid ${vars.colors.secondary9};
                  border-radius: 2px;
                  display:flex;
                  align-items: center; 
                  // justify-content: center; 
                  cursor:pointer;
                  font-size:16px;
                  font-weight: ${vars.fonts.weightNormal};
                  &.active{
                    border: 2px solid ${vars.colors.primary8};
                  
                    font-weight: ${vars.fonts.weightBold};
                    font-family: ${vars.fonts.familySecondary};

                  }
                  .icon{
                    position: absolute;
                    // margin-left:10px;
                    i:before{
                      color:${vars.colors.btnsPrimaryBg};;
                    }
                  }
                  .frequency-varient{
                     margin: auto;
                   padding:0 25px;
                    width: 100%;
                    display: flex;
                    justify-content: center;

                  }
                
              }

            }
            & .notes{
              display:flex;
              align-items:center;
              gap:10px;
              color:${vars.colors.secondary12};
              margin-top: 20px;
              .notes-text{

              }
            }
            & .update-section{
              display:flex;
              margin-top: 20px;
              justify-content:end;
              .clear {
                background: none;
                font-size: ${vars.fonts.sizeLarge};
                line-height: ${vars.fonts.lineHeightDefault};
                text-decoration-line: underline;
                
                cursor: pointer;
                border: none;
                box-shadow: none;
                &:hover {
                  background: none;
                  color: #000;
                  text-decoration-line: underline;
                }
              }
              .update{
                width: 10rem;
                ${vars.breakpoints.sm}{
                  width:250px;
                  }
              }
            }
          }
    `,
      winePlanModalWrapper: `
    & .mantine-Modal-inner{
      bottom: unset;
      top: 10rem;
      ${vars.breakpoints.lg} {
      bottom: 0;
      top: 0;
      }
    }
    & .mantine-Paper-root{
      padding:20px;
    & h2.mantine-Modal-title{
    font-size: 30px;
    font-weight: 500;
    }
    .frequency-weeks{
      font-size: 16px;
      margin:20px 0px;
      .bold{
        font-weight: 600;
      }
    }
    .notes{
      margin-bottom:20px
    }
    .close{
      display:flex;
      justify-content:end;
      button{
      width:190px;
      }
    }
  }
    `,
      unlimitedModalWrapper: `
        .mantine-Modal-title {
          font-size: 20px;
          font-weight: 600;
          margin-bottom: 0 !important;

          ${vars.breakpoints.sm} {
            font-size: 30px;
          }
        }

        .mantine-Modal-header {
          align-items: center;
          padding-top: 20px;
        }

        .mantine-Modal-body {
          padding-bottom: 0;
        }

        .mantine-Modal-content {
          position: absolute;
          padding: 12px;
          padding-top: 0;
          bottom: 0;
          width: 100%;
          height: auto;
          border-radius: 8px 8px 0 0;
          box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1); 

          ${vars.breakpoints.sm} {
            position: relative;
            max-width: 728px; 
            width: 100%; 
            padding: 24px;
            border-radius: 8px;
            box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
          }
        } 

        .unlimited-modal-content-wrapper {
          justify-content: space-between;
          gap: 20px;
          ${vars.breakpoints.sm} {
            justify-content: space-evenly;
          }
        }

        .unlimited-modal-cta-wrapper {
          justify-content: center;
          flex-direction: column-reverse;

          ${vars.breakpoints.sm} {
            flex-direction: row;
            justify-content: flex-end;
          }
        }

        .unlimited-modal-primary-cta {
          cursor: pointer;
          width: 100%;
          ${vars.breakpoints.sm} {
            width: auto;
          }
        }

        .unlimited-modal-tertiary-cta {
          cursor: pointer;
          text-decoration: underline;
          :hover {
            text-decoration: none;
          }
          width: 100%;
          ${vars.breakpoints.sm} {
            width: auto;
          }
        }

        
    `,
    },
    CmsTabs: {
      root: `
        .mantine-Tabs-tabsList {
          flex-wrap: nowrap;
          overflow-x: auto;
          border-bottom: 1px solid ${vars.colors.black};
        }
      `,
      tab: `
        border-bottom: 4px solid transparent;
        padding: 0 0 12px 0;
        margin-right: 16px !important;

        &[data-active] {
          border-color: ${vars.colors.black};
        }

        &[data-active]:active,
        &[data-active]:hover,
        &:hover {
          background: none;
          border-color: ${vars.colors.black};
        }

        &:last-child {
          margin-right: 0 !important;
        }

        &.highlighted span {
          color: ${vars.colors.primary9};
          font-style: italic;
        }
      `,
      tabLabel: `
        color: ${vars.colors.grey50};
        ${vars.mixins.h8}
      `,
      panel: `
        color: ${vars.colors.grey50};
        padding-top: 24px;
         div p {
          ${vars.mixins.body1}
        }
      `,
    },
    CmsTimer: {
      wrapper: `
        text-align: center;
        text-transform: uppercase;
        padding: ${vars.space.xl} 0;
        color: ${vars.colors.white};
        ${vars.breakpoints.lg} {
          padding: 40px 0;
        }
        .timer-headline {
          ${vars.mixins.eyebrowRegular};
          display: inline-block;
          margin-bottom: ${vars.space.sm};
        }
        h2.timer-content {
          margin-bottom: 0;
        }
      `,
    },
    CmsExpander: {
      wrapper: `
        position: relative;
        .expander-content {
          max-height: 0;
          overflow: hidden;
          transition: all 0.2s;
          position: relative;
          z-index: 1;
          padding: 0 ${vars.space.lg};
          * {
            &:last-child {
              margin-bottom: 0;
            }
          }
        }
        .expander-action {
          text-align: center;
          position: relative;
          z-index: 1;
          button {
            background: none;
            border-top: none;
            border-left: none;
            border-right: none;
            border-radius: 0;
            font-size: 16px;
            font-weight: 400;
            padding: 0;
            margin-top: 8px;
          }
        }
      `,
    },
    CmsTBSPage: {
      wrapper: `
        display: grid;
        max-width: 1920px;
        margin-left: auto;
        margin-right: auto;
        grid-gap: 24px;
        ${vars.breakpoints.md} {
          grid-template-columns:380px 1fr;
        }
      `,
      sideBar: `
      display:none;
      ${vars.breakpoints.md} {
        display:grid;
      }
      padding: 50px 10px 30px 45px;
        & div{
          margin:0;
          padding:0;
        }
        & div.navigation-link{
          ${vars.breakpoints.xs} {
              width:260px;
          }
          a{
            font-size: ${vars.fonts.sizeLarge}; 
            font-family: ${vars.fonts.familySecondary};
            line-height: 22.4px;
            color: ${vars.colors.grey50};
            padding: 12px 16px;
            margin: 0;
          }
          h4{
            font-size:30px;
            color:${vars.colors.primary1};
            font-family: ${vars.fonts.familyTertiary};
            padding: 0 16px;
            margin: 24px 0;
          }
        }
      `,
      content: `
        display: grid;
      `,
      breadcrumbCSS: `
        padding: 12px 24px 12px 0px;
        & a {
          display: flex;
          align-items: center;
          gap: 8px;
          &:hover, &:active, &:focus {
            text-decoration: none;
          }
          & .link-text {
            text-decoration: none;
            border-bottom: 1px solid ${vars.colors.primary8};
            font-size: ${vars.fonts.sizeH5};
            font-weight: bold;
          }
        }
      `,
    },
  };

  return widgets;
};

const createTheme: ThemeCreateFunction = () =>
  buildTheme(buildThemeVarsLW, buildThemeMixinsLW, buildThemeWidgetStylesLW);
export default createTheme;
