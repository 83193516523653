export const USStateData = [
  {
    stateId: '1052',
    stateCode: 'AL',
    stateName: 'Alabama',
    shippableBrands: ['wsj', 'law', 'mcy', 'vir', 'osw'],
    threeTier: false,
    wineryDirect: true,
    retailer: 'Lionstone International',
  },
  {
    stateId: '1053',
    stateCode: 'AK',
    stateName: 'Alaska',
    shippableBrands: ['all'],
    threeTier: false,
    wineryDirect: false,
    retailer: '',
  },
  {
    stateId: '1055',
    stateCode: 'AZ',
    stateName: 'Arizona',
    shippableBrands: ['all'],
    threeTier: false,
    wineryDirect: false,
    retailer: '',
  },
  {
    stateId: '1054',
    stateCode: 'AR',
    stateName: 'Arkansas',
    shippableBrands: [],
    threeTier: false,
    wineryDirect: false,
    retailer: '',
  },
  {
    stateId: '1046',
    stateCode: 'CA',
    stateName: 'California',
    shippableBrands: ['all'],
    threeTier: true,
    wineryDirect: false,
    retailer: 'Lionstone West',
  },
  {
    stateId: '1050',
    stateCode: 'CO',
    stateName: 'Colorado',
    shippableBrands: ['wsj', 'law', 'tcm', 'npr', 'osw'],
    threeTier: true,
    wineryDirect: true,
    retailer: 'Lionstone International',
  },
  {
    stateId: '1049',
    stateCode: 'CT',
    stateName: 'Connecticut',
    shippableBrands: ['all'],
    threeTier: true,
    wineryDirect: false,
    retailer: 'The Wine Cellar Outlet Cincinnati',
  },
  {
    stateId: '1047',
    stateCode: 'DE',
    stateName: 'Delaware',
    shippableBrands: [],
    threeTier: false,
    wineryDirect: false,
    retailer: '',
  },
  {
    stateId: '1051',
    stateCode: 'FL',
    stateName: 'Florida',
    shippableBrands: ['all'],
    threeTier: false,
    wineryDirect: false,
    retailer: '',
  },
  {
    stateId: '1028',
    stateCode: 'GA',
    stateName: 'Georgia',
    shippableBrands: ['law', 'wsj', 'mcy', 'vir', 'osw'],
    threeTier: false,
    wineryDirect: true,
    retailer: 'Lionstone International',
  },
  {
    stateId: '1027',
    stateCode: 'HI',
    stateName: 'Hawaii',
    shippableBrands: ['law', 'wsj', 'osw'],
    threeTier: false,
    wineryDirect: true,
    retailer: 'Lionstone International',
  },
  {
    stateId: '1024',
    stateCode: 'ID',
    stateName: 'Idaho',
    shippableBrands: ['law', 'wsj', 'osw'],
    threeTier: false,
    wineryDirect: true,
    retailer: 'Lionstone International',
  },
  {
    stateId: '1026',
    stateCode: 'IL',
    stateName: 'Illinois',
    shippableBrands: ['all'],
    threeTier: true,
    wineryDirect: false,
    retailer: 'The Wine Cellar',
  },
  {
    stateId: '1025',
    stateCode: 'IN',
    stateName: 'Indiana',
    shippableBrands: ['all'],
    threeTier: true,
    wineryDirect: false,
    retailer: 'Wine Direct',
  },
  {
    stateId: '1023',
    stateCode: 'IA',
    stateName: 'Iowa',
    shippableBrands: ['law', 'wsj', 'mcy', 'npr', 'osw'],
    threeTier: false,
    wineryDirect: true,
    retailer: '',
  },
  {
    stateId: '1022',
    stateCode: 'KS',
    stateName: 'Kansas',
    shippableBrands: ['law', 'wsj', 'mcy', 'vir', 'osw'],
    threeTier: false,
    wineryDirect: true,
    retailer: 'Lionstone International',
  },
  {
    stateId: '1020',
    stateCode: 'KY',
    stateName: 'Kentucky',
    shippableBrands: ['law', 'wsj', 'mcy', 'vir', 'osw'],
    threeTier: false,
    wineryDirect: true,
    retailer: 'Lionstone International',
  },
  {
    stateId: '1021',
    stateCode: 'LA',
    stateName: 'Louisiana',
    shippableBrands: ['all'],
    threeTier: false,
    wineryDirect: false,
    retailer: '',
  },
  {
    stateId: '1012',
    stateCode: 'ME',
    stateName: 'Maine',
    shippableBrands: ['law', 'wsj', 'mcy', 'vir', 'osw'],
    threeTier: false,
    wineryDirect: true,
    retailer: 'Lionstone International',
  },
  {
    stateId: '1014',
    stateCode: 'MD',
    stateName: 'Maryland',
    shippableBrands: ['law', 'wsj', 'mcy', 'vir', 'osw'],
    threeTier: false,
    wineryDirect: true,
    retailer: 'Lionstone International',
  },
  {
    stateId: '1013',
    stateCode: 'MA',
    stateName: 'Massachusetts',
    shippableBrands: ['all'],
    threeTier: true,
    wineryDirect: false,
    retailer: 'The Wine Cellar Outlet',
  },
  {
    stateId: '1017',
    stateCode: 'MI',
    stateName: 'Michigan',
    shippableBrands: ['all'],
    threeTier: true,
    wineryDirect: false,
    retailer: 'Wine Dock Co.',
  },
  {
    stateId: '1015',
    stateCode: 'MN',
    stateName: 'Minnesota',
    shippableBrands: ['all'],
    threeTier: false,
    wineryDirect: false,
    legacyWD: true,
    retailer: 'Lionstone International',
  },
  {
    stateId: '1018',
    stateCode: 'MS',
    stateName: 'Mississippi',
    shippableBrands: [],
    threeTier: false,
    wineryDirect: false,
    retailer: '',
  },
  {
    stateId: '1016',
    stateCode: 'MO',
    stateName: 'Missouri',
    shippableBrands: ['all'],
    threeTier: false,
    wineryDirect: false,
    retailer: '',
  },
  {
    stateId: '1019',
    stateCode: 'MT',
    stateName: 'Montana',
    shippableBrands: ['law', 'wsj', 'mcy', 'vir', 'osw'],
    threeTier: false,
    wineryDirect: true,
    retailer: 'Lionstone International',
  },
  {
    stateId: '1004',
    stateCode: 'NE',
    stateName: 'Nebraska',
    shippableBrands: ['all'],
    threeTier: false,
    wineryDirect: true,
    retailer: 'The Wine Cellar Outlet Nebraska',
  },
  {
    stateId: '1010',
    stateCode: 'NV',
    stateName: 'Nevada',
    shippableBrands: ['all'],
    threeTier: true,
    wineryDirect: false,
    retailer: 'Lionstone West',
  },
  {
    stateId: '1003',
    stateCode: 'NH',
    stateName: 'New Hampshire',
    shippableBrands: ['all'],
    threeTier: false,
    wineryDirect: false,
    retailer: '',
  },
  {
    stateId: '1008',
    stateCode: 'NJ',
    stateName: 'New Jersey',
    shippableBrands: ['all'],
    threeTier: true,
    wineryDirect: false,
    retailer: 'The Wine Cellar at Red Bank',
  },
  {
    stateId: '1007',
    stateCode: 'NM',
    stateName: 'New Mexico',
    shippableBrands: ['all'],
    threeTier: true,
    wineryDirect: false,
    retailer: 'Lionstone International',
  },
  {
    stateId: '1009',
    stateCode: 'NY',
    stateName: 'New York',
    shippableBrands: ['all'],
    threeTier: true,
    wineryDirect: false,
    retailer: 'The Wine Cellar at Rye Ridge',
  },
  {
    stateId: '1006',
    stateCode: 'NC',
    stateName: 'North Carolina',
    shippableBrands: ['all'],
    threeTier: false,
    wineryDirect: false,
    legacyWD: true,
    retailer: 'Lionstone International',
  },
  {
    stateId: '1005',
    stateCode: 'ND',
    stateName: 'North Dakota',
    shippableBrands: ['all'],
    threeTier: false,
    wineryDirect: false,
    retailer: '',
  },
  {
    stateId: '1042',
    stateCode: 'OH',
    stateName: 'Ohio',
    shippableBrands: ['all'],
    threeTier: false,
    wineryDirect: false,
    retailer: '',
  },
  {
    stateId: '1040',
    stateCode: 'OK',
    stateName: 'Oklahoma',
    shippableBrands: ['law', 'wsj', 'mcy', 'vir', 'osw'],
    threeTier: false,
    wineryDirect: true,
    retailer: 'Lionstone International',
  },
  {
    stateId: '1041',
    stateCode: 'OR',
    stateName: 'Oregon',
    shippableBrands: ['all'],
    threeTier: true,
    wineryDirect: false,
    retailer: 'Lionstone West',
  },
  {
    stateId: '1039',
    stateCode: 'PA',
    stateName: 'Pennsylvania',
    shippableBrands: ['law', 'wsj', 'mcy', 'vir', 'npr', 'osw'],
    threeTier: true,
    wineryDirect: true,
    retailer: 'Lionstone International',
  },
  {
    stateId: '1038',
    stateCode: 'RI',
    stateName: 'Rhode Island',
    shippableBrands: [],
    threeTier: false,
    wineryDirect: false,
    retailer: '',
  },
  {
    stateId: '1037',
    stateCode: 'SC',
    stateName: 'South Carolina',
    shippableBrands: ['all'],
    threeTier: false,
    wineryDirect: false,
    legacyWD: true,
    retailer: 'Lionstone International',
  },
  {
    stateId: '1036',
    stateCode: 'SD',
    stateName: 'South Dakota',
    shippableBrands: ['law', 'wsj', 'mcy', 'vir', 'osw'],
    threeTier: false,
    wineryDirect: true,
    retailer: 'Lionstone International',
  },
  {
    stateId: '1034',
    stateCode: 'TN',
    stateName: 'Tennessee',
    shippableBrands: ['law', 'wsj', 'mcy', 'vir', 'osw'],
    threeTier: false,
    wineryDirect: true,
    retailer: 'Lionstone International',
  },
  {
    stateId: '1033',
    stateCode: 'TX',
    stateName: 'Texas',
    shippableBrands: ['all'],
    threeTier: true,
    wineryDirect: false,
    retailer: 'Multiple-TX',
  },
  {
    stateId: '1031',
    stateCode: 'UT',
    stateName: 'Utah',
    shippableBrands: [],
    threeTier: false,
    wineryDirect: false,
    retailer: '',
  },
  {
    stateId: '1029',
    stateCode: 'VT',
    stateName: 'Vermont',
    shippableBrands: ['law', 'wsj', 'mcy', 'vir', 'osw'],
    threeTier: false,
    wineryDirect: true,
    retailer: 'Lionstone International',
  },
  {
    stateId: '1032',
    stateCode: 'VA',
    stateName: 'Virginia',
    shippableBrands: ['all'],
    threeTier: true,
    wineryDirect: false,
    retailer: 'The Wine Cellar Outlet Cincinnati',
  },
  {
    stateId: '1030',
    stateCode: 'WA',
    stateName: 'Washington',
    shippableBrands: ['all'],
    threeTier: true,
    wineryDirect: false,
    retailer: 'The Wine Cellar Outlet Issaquah',
  },
  {
    stateId: '1045',
    stateCode: 'WV',
    stateName: 'West Virginia',
    shippableBrands: ['all'],
    threeTier: false,
    wineryDirect: false,
    retailer: '',
  },
  {
    stateId: '1043',
    stateCode: 'WI',
    stateName: 'Wisconsin',
    shippableBrands: ['all'],
    threeTier: false,
    wineryDirect: false,
    retailer: '',
  },
  {
    stateId: '1044',
    stateCode: 'WY',
    stateName: 'Wyoming',
    shippableBrands: ['all'],
    threeTier: false,
    wineryDirect: false,
    retailer: 'The Wine Cellar Outlet Cincinnati',
  },
];

export default USStateData;
